import React, {useCallback, useMemo, useState} from "react";
import styled from "styled-components";
import {NftPoolItem, PoolType} from "./nft_pool_item";
import {Grid, TextField} from "@material-ui/core";
import {useCollection} from "../../../provider/collection_provicer";
import * as R from "ramda";
import {SudoMetaData} from "../../../model/sudo_meta_data";
import {mergeDeepWithKey} from "ramda";
import {TypeSelectionWidget} from "../widget/type_selection";
import {titleText} from "../widget/pool_item_title";
import {BondingList} from "../../my_pool/hook/usePool";
import {MyPoolData} from "../../../model/my_pool_data";
import {usePool} from "../hook/usePool";
import BigNumber from "bignumber.js";

export const NftPool: React.FC = () => {
    const {sudo, collections, nftMeta} = useCollection();

    const [filters, setFilters] = useState<any>({
        type: {"1": true, "2": true, "0": true},
        curve: {
            [BondingList.linear]: false,
            [BondingList.exponetial]: false,
        }
    });

    const pools: any = useMemo(() => {
        let list = Object.values(sudo)
        list = list.filter((ev: MyPoolData) => {
            let eType = parseInt(ev.type)
            if (eType === PoolType.trade) {
                return ev.nftIds.length > 0 || new BigNumber(ev.ethBalance).gt(0)
            }
            if (eType === PoolType.nft) {
                return ev.nftIds.length > 0
            }
            if (eType === PoolType.token) {
                return new BigNumber(ev.ethBalance).gt(0)
            }
        })
        return list
    }, [sudo])


    const groupPools = useMemo(() => {
        return R.groupBy((ev: MyPoolData) => ev.type)(pools);
    }, [pools])


    const groupCurvePools = useMemo(() => {
        return R.groupBy((ev: MyPoolData) => ev.bondingCurve)(pools);
    }, [pools])


    const {productionFeeding} = usePool(filters, pools);

    if (!collections || !nftMeta) return <div>Loading ...</div>

    return (
        <NftPoolStyle>
            <LeftItemStyle>
                <h4>Only Show Offers </h4>

                <div className="input">
                    <TextField
                        unselectable={'off'}
                        fullWidth={true}
                    />
                </div>

                {
                    Object.keys(groupPools).map((pools, i) => {
                        return <div key={`${pools}_${i}`} onClick={() => {
                            let _data = mergeDeepWithKey(
                                (k, l, r) => k === 'type' ? R.concat(l, r) : r,
                                filters,
                                {
                                    type: {
                                        [pools]: !filters['type'][pools]
                                    },
                                }
                            )
                            setFilters(_data);
                        }}>
                            <TypeSelectionWidget
                                name={titleText[parseInt(pools)]}
                                value={`${groupPools[pools].length}`}
                                isSelect={filters['type'][pools]}
                            />
                        </div>;
                    })
                }
                <div style={{height: "20px"}}/>
                <h4>Bonding Curve</h4>
                {
                    Object.keys(BondingList).map((key, i) => {
                        let _address = BondingList[key];
                        return <div key={`${key}_${i}`} onClick={() => {
                            let _data = mergeDeepWithKey(
                                (k, l, r) => k === 'type' ? R.concat(l, r) : r,
                                filters,
                                {
                                    curve: {
                                        [_address]: !filters['curve'][_address]
                                    },
                                }
                            )
                            setFilters(_data);
                        }}>
                            <TypeSelectionWidget
                                name={key.toUpperCase()}
                                value={`${groupCurvePools[_address.toLowerCase()]?.length}`}
                                isSelect={filters['curve'][_address]}
                            />
                        </div>;
                    })
                }
            </LeftItemStyle>
            <div className="nft_wap">
                <Grid container spacing={3}>

                    {productionFeeding.map((ev: SudoMetaData) => {
                            return <NftPoolItem key={`${ev.uqid}_${ev.nftId}`} props={
                                {
                                    type: parseInt(ev.type),
                                    sudoData: ev as any,
                                    collections: collections,
                                    isShowEmpty: true
                                }
                            }/>
                            // return <Grid item xs={12} sm={6} md={4} key={`${ev.uqid}_${ev.nftId}`}>
                            //     <NftPoolItem props={
                            //         {
                            //             type: parseInt(ev.type),
                            //             sudoData: ev as any,
                            //             collections: collections,
                            //             isShowEmpty: true
                            //         }
                            //     }/>
                            // </Grid>
                        }
                    )}
                </Grid>
            </div>
        </NftPoolStyle>
    );
}


const LeftItemStyle = styled.div`
  width: 20%;


  .input {
    margin-top: 20px;
    background: #080808;
    border: 1px solid #8D909A;
    border-radius: 10px;
    padding: 4px 10px;
    box-sizing: border-box;
  }

  .MuiInputBase-root {
    color: white;
  }

  .MuiInput-underline:before, .MuiInput-underline:after {
    display: none;
  }

  .MuiInputBase-input {
    font-family: 'ZX', sans-serif;

    &::-webkit-input-placeholder {
      text-align: start;
    }
  }

  h4 {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: -0.14em;
    color: #8D909A;
  }
`


const NftPoolStyle = styled.div`
  display: flex;
  justify-content: space-between;

  .nft_wap {
    width: 80%;
    padding-left: 10px;
  }

`

