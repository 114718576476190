import React, {useCallback, useEffect, useMemo, useState} from "react";
import {compose, flatten, forEach, map, path, sort} from "ramda";
import * as R from "ramda";
import {MyPoolData, RootMyPoolData} from "../../../model/my_pool_data";
import BigNumber from "bignumber.js";
import {useWallet} from "use-wallet";
import {NftMateMap, PairsMap} from "../../collection/type";
import {PoolType} from "../../collection/pool/nft_pool_item";

type getListProps = {
    sudo?: PairsMap,
    nftMeta?: NftMateMap,
}
export const useGetFoorPrice = ({sudo, nftMeta}: getListProps) => {
    const {account} = useWallet();
    const [floorPrice, setFloorPrice] = useState('0');
    const [ethVolume, setEthVolume] = useState('0');
    const [buyList, setBuyList] = useState<RootMyPoolData[]>([]);
    const [sellList, setSellList] = useState<RootMyPoolData[]>([]);

    const pools: any = useCallback(() => {
        if (!sudo) return [];
        let list = Object.values(sudo)
        list = list.filter((ev: MyPoolData) => {
            let eType = parseInt(ev.type)
            if (eType === PoolType.trade) {
                return ev.nftIds.length > 0 || new BigNumber(ev.ethBalance).gt(0)
            }
            if (eType === PoolType.nft) {
                return ev.nftIds.length > 0
            }
            if (eType === PoolType.token) {
                return new BigNumber(ev.ethBalance).gt(0)
            }
        })
        let vol = new BigNumber(0);
        list.forEach((ev) => {
            vol = vol.plus(ev.ethVolume)
        })
        setEthVolume(vol.toFixed())
        return list
    }, [sudo])


    const handelSetBuyList = useCallback(async () => {
        let list = pools().filter((item: MyPoolData) => {
            let eType = parseInt(item.type)
            if (eType === PoolType.trade || eType === PoolType.nft) {
                return item.nftIds.length > 0
            }
        });

        let _list = R.sort((a: any, b: any) => new BigNumber(a.averagePrice).minus(b.averagePrice).toNumber())(list);

        if (_list && _list[0]) {
            setFloorPrice(_list[0].averagePrice)
        }

        let buys = compose(
            R.partition((ev: MyPoolData) => ev.owner.id.toLowerCase() === (account || '').toLocaleString()),
            R.sort((a: any, b: any) => new BigNumber(a.averagePrice).minus(b.averagePrice).toNumber()),
            flatten,
            map((ev: MyPoolData) => {
                return ev.nftIds.map((id: string) => {
                    return {
                        ...ev,
                        nftId: id
                    }
                })
            }),
        )([...list] || [])
        let buy: any = buys[1]
        setBuyList(buy)
        setSellList(buys[0])


    }, [pools])

    useEffect(() => {
        const timer = setTimeout(()=>{
            if (sudo && nftMeta) {
                handelSetBuyList()
            }
        },300)
       return () => clearTimeout(timer)
    }, [sudo, pools, nftMeta, account])

    return {floorPrice, buyList, sellList, ethVolume}
}
