import React from "react";
import styled from "styled-components";
import {useCreatePairETH} from "../../server/factory";
import {useCreaterDataPool} from "../../provider/creater_lp_provider";
import {LoadingBtn} from "../../compones/loading_btn";
import {useSendModal} from "../../widget/modal/send_modal";
import {CURVEEnum} from "./type";
import {ChartsWIdget} from "./widget/charts";

import {
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import {PoolType} from "../collection/pool/nft_pool_item";
import {DoubleChartsWIdget} from "./widget/double.charts";


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

export const options = {
    plugins: {
        legend: {
            position: 'top' as const,
            display: false,

        },
        title: {
            display: false,
            text: 'Chart.js Line Chart',
        },
    },
};

const labels = ['1', '2', '3', '4', '5', '6', '7'];

export const data = {
    labels,
    datasets: [
        {
            fill: true,
            label: '',
            data: [1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9],
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
            tension: 0.5
        },
    ],
};


type KlinkProps = {
    getValues: Function,
    watch: any,
    option: any[],
    total: string,

}
export const KLink: React.FC<KlinkProps> = ({getValues, watch, option, total}) => {

    const handleCreatePairETH = useCreatePairETH()
    const {onSend, status,} = useSendModal(handleCreatePairETH)

    const {poolType, moralisData, curve, nftidsStateAction} = useCreaterDataPool()
    const [_curve, setCurve] = curve
    let [moralis] = moralisData
    let [pool] = poolType



    return (
        <WapStyle>
            <h3 className={'tis'}>
                <p>ETH Price Per {watch?.spotPrice} ETH.</p>
                <p>Displays how your buy price {pool == PoolType.token ? "increases" : "decreases"} with
                    each {moralis.symbol} bought.</p>
            </h3>
            <div className="container" id="container">
                {
                    pool === PoolType.trade ?
                        <DoubleChartsWIdget option={option}/> :
                        <ChartsWIdget option={option}/>

                }
            </div>

            <div className="bottom_type">
                <div className="curve">
                    <div onClick={() => setCurve(CURVEEnum.LINEAR)}
                         className={`btn ${_curve === CURVEEnum.LINEAR && 'ac'}`}>Linear Curve
                    </div>
                    <div onClick={() => setCurve(CURVEEnum.EXPONENTIAL)}
                         className={`btn ${_curve === CURVEEnum.EXPONENTIAL && 'ac'}`}>Exponential Curve
                    </div>
                </div>
            </div>

            <LoadingBtn isLoading={status} onClick={() => {
                let {spotPrice, delta, bonding} = getValues();
                let [moralis, _] = moralisData
                onSend({
                    delta,
                    bonding,
                    nftAddress: moralis.token_address,
                    poolType: pool,
                    spotPrice: spotPrice,
                    curve: _curve,
                    nftIds: nftidsStateAction[0],
                    total: pool === PoolType.trade ? watch?.amount : total,
                    feeRate: watch?.feeRate || 0,
                })
            }}>
                Confirm
            </LoadingBtn>

        </WapStyle>
    )
}

const WapStyle = styled.div`
  padding: 20px;

  .container {
    margin: 10px 0;
  }

  .bottom_type {
    .curve {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .btn {
        border-radius: 10px;
        background: #2C2F36;
        padding: 10px 0;
        width: 45%;
        text-align: center;
        color: #6E7075;
        cursor: pointer;

        &.ac {
          background: #52565F;
          color: #FFFFFF;
        }
      }
    }
  }

  .container {
  }

  .confirm {
    cursor: pointer;
    margin-top: 20px;
    width: 100%;
    padding: 10px 0;
    border-radius: 10px;
    background: #52565F;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
  }

  .tis {
    padding: 10px;
  }
`
