import React from "react";
import styled from "styled-components";
import {Grid} from "@material-ui/core";
import {LabelInput} from "../widget/label_input";
import ethImg from "../../../assets/images/pool_eth.png";
import cloneImg from "../../../assets/images/colse_img.png"

import {useForm} from "react-hook-form";
import {useSendModal} from "../../../widget/modal/send_modal";
import {NftPoolProps} from "../index";
import {LoadingBtn} from "../../../compones/loading_btn";
import {useMulticall} from "../../../server/pair";
import {ModalProps} from "../../../provider/model_provider";
import {CURVE} from "../../../web3/token.config";

type UpdateModelProps = {
    onDismiss: Function
} & NftPoolProps & ModalProps
export const UpdateModel: React.FC<UpdateModelProps> = ({sudo, onDismiss}) => {

    const {register, errors, getValues} = useForm();
    const handleMulticall = useMulticall(sudo?.id || '');
    const {onSend, status,} = useSendModal(handleMulticall)

    return (
        <WapStyle>
            <div className="model_title">
                Edit Price and Delta
                {
                    onDismiss && <img src={cloneImg} onClick={() => onDismiss()} alt=""/>
                }
            </div>
            <CellBottomStyle container>
                <div className="pt">
                    <LabelInput errors={errors}
                                inputRef={register({})}
                                lastTis={''}
                                label={'Current Price'}
                                name={'price'}
                                placeholder={""}
                    >
                        <img className={'ethImg'} src={ethImg} alt=""/>
                    </LabelInput>
                </div>
                <div className="pt">
                    <LabelInput errors={errors}
                                inputRef={register({
                                    required: 'Please enter a number > 0'
                                })}
                                label={'Delta'}
                                name={'delta'}
                                placeholder={""}
                                unit={`${sudo?.bondingCurve === CURVE.EXPONENTIAL_CURVE ? "%" : "ETH"}`}
                    />
                </div>

            </CellBottomStyle>

            <div className={'bottom'}>
                <LoadingBtn isLoading={status} onClick={() => {
                    let values = getValues();
                    onSend(values)
                }}>
                    <h4>Update</h4>
                </LoadingBtn>
            </div>
        </WapStyle>
    )
}


const WapStyle = styled.div`

  width: 60%;
  margin: 0 auto;
  background-color: #191b1f;
  border-radius: 20px;
  border: 1px solid #2C2F36;
  padding: 10px 20px;
  box-sizing: border-box;

  .bottom {
    display: flex;
    justify-content: center;

    > div {
      width: 50%;
    }
  }

  .model_title {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    display: flex;
    align-items: center;
    color: #D9D9D9;
    border: 1px solid #191B1F;
    position: relative;
    justify-content: center;

    img {
      display: block;
      width: 20px;
      position: absolute;
      cursor: pointer;

      right: 0;
    }
  }
`


export const CellBottomStyle = styled(Grid)`
  padding: 10px 0;
  box-sizing: border-box;
  margin-top: 8px;

  div {
    .icon .img {
      background-color: #2C2F36;
    }

    .address {
      > div {
        background-color: #2C2F36;
      }
    }
  }
  
  .disabled{
    .pt{
      .FlexStyle {
        > div {
          .MuiInputBase-input.Mui-disabled{
            cursor: no-drop;
          }
        }
      }
    }
  }


  .pt {
    box-sizing: border-box;
    cursor: pointer;
    width: 100%;

    .btn_max {
      background-color: #2d2f35;
      padding: 4px 10px;
      border-radius: 100px;
      font-size: 18px;
      font-weight: 400;
      color: #FFFFFF;
      cursor: pointer;
    }

    .bottom_msg {
      font-size: 14px;
      font-weight: 400;
      color: #FFFFFF;
      margin-bottom: 20px;
    }

    .ethImg {
      display: block;
      width: 20px;
    }
    .FlexStyle {
      > div {
        background-color: #52565F;
        cursor: no-drop;
      }
    }
    > div .LabelInput {
      
    }
  }

  .tis {
    font-size: 16px;
    font-weight: 500;
    color: #BABAC8;
    padding: 10px 20px;
    box-sizing: border-box;
    line-height: 20px;

    p {
      margin-bottom: 10px;
    }

    .sub_title {
      font-size: 12px;
    }
  }

  .bottom_text {
    padding: 0 30px 20px;
    box-sizing: border-box;
    color: #FE9000;
    font-size: 12px;
  }

  @media (max-width: ${({theme}) => theme.sm}) {
    .tis {
      padding: 20px;
      font-size: 14px;
    }
  }
`
