import {useWait} from "../../../utils/wait_fn";
import {HttpServer} from "../../../net/http_server";
import {allPass, anyPass, filter, path, pathSatisfies, propSatisfies} from "ramda";
import {useMemo} from "react";
import * as R from "ramda";
import {useWallet} from "use-wallet";
import {CURVE} from "../../../web3/token.config";
import {RootMyPoolData} from "../../../model/my_pool_data";


export const BondingList: { [key: string]: string } = {
    linear: CURVE.LINEAR_CURVE,
    exponetial: CURVE.EXPONENTIAL_CURVE
}

export const BondingText: { [key: string]: string } = {
    [CURVE.LINEAR_CURVE]: "linear",
    [CURVE.EXPONENTIAL_CURVE]: "exponetial"
}


export const usePool = (
    filters: any,
    isShow: boolean,
) => {

    const {account} = useWallet();

    const {
        request,
        data,
        status,
    } = useWait(() => HttpServer.thegraph((account || '').toLowerCase()))

    const pools: any = useMemo(() => {
        if (!data) {
            return []
        }
        if (!R.path(['data', 'pairOwner', 'pairs'], data)) {
            return []
        }
        return R.compose(
            R.map((ev: any) => {
                return R.mergeDeepRight(ev, {
                    assetRecipient: account,
                    ...ev['pair']
                })
            }),
            R.path(['data', 'pairOwner', 'pairs'])
        )(data);
    }, [data, account])


    const productionFeeding = useMemo(() => {
        let list = filter(
            anyPass([
                propSatisfies(
                    (batch: any,) => {
                        let {id} = batch;
                        let status = path([id], filters);
                        if (!status) {
                            status = false
                        }
                        return status === isShow;
                    },
                    'collection'
                ),
            ]),
            pools
        );
       return list
    }, [pools, filters, isShow])


    return {request, pools, status, productionFeeding};
}
