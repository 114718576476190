import {CollectionsDataModel} from "../../model/collections_data";

export const defaultCollections: CollectionsDataModel = {
    "_id": "63031329304128f4ec069989",
    "address": "0x6d5E087B64b21C1671469b9963349b154F381D98",
    "name": "Sudo Grindset Value Menu",
    "symbol": null,
    "isVerified": false,
    "images": {
        "image_url": "https://openseauserdata.com/files/42d08ae60dc3cb8c60621e7363273ed4.png",
        "banner_image_url": "https://openseauserdata.com/files/28f3c02b6a9341d9b199230f41c3c769.png"
    },
    "buy_quote": 14186847545341764,
    "sell_quote": 12707650170945940,
    "pool_count": 5,
    "item_count": 806,
    "offer_tvl": 1237368596693029000,
    "analytics": {"volume_all_time": 6.205319614467098, "volume_24_hour": 0}
}
