import {useCallback, useEffect, useMemo, useState} from "react";
import {compose, flatten, map, sort} from "ramda";
import * as R from "ramda";
import {MyPoolData, RootMyPoolData} from "../../../model/my_pool_data";
import BigNumber from "bignumber.js";
import {useWallet} from "use-wallet";
import {PoolType} from "../pool/nft_pool_item";
import {NftMateMap, PairsMap, SellQty} from "../type";
import PairABI from "../../../web3/abi/pair_eth";
import {useWeb3} from "../../../provider/web3_provider";
import {sequenceFn} from "../../../utils/sequence";

type getListProps = {
    sudo?: PairsMap,
    nftMeta?: NftMateMap,
}
export const useGetList = ({sudo, nftMeta}: getListProps) => {
    const {account} = useWallet();
    const [floorPrice, setFloorPrice] = useState('0');
    const [bestOffer, setBestOffer] = useState('0');
    const [ethVolume, setEthVolume] = useState('0');
    const [tvl, setTvl] = useState('0');
    const [buyList, setBuyList] = useState<RootMyPoolData[]>([]);

    const pools: any = useMemo(() => {
        if (!sudo) return [];
        let list = Object.values(sudo)
        let _tvl = new BigNumber(0);
        list.forEach((ev) => {
            _tvl = _tvl.plus(ev.ethBalance)
        })
        let vol = new BigNumber(0);
        list.forEach((ev) => {
            vol = vol.plus(ev.ethVolume)
        })

        setEthVolume(vol.toFixed())
        setTvl(_tvl.toFixed())
        list = list.filter((ev: MyPoolData) => {
            let eType = parseInt(ev.type)
            if (eType === PoolType.trade) {
                return ev.nftIds.length > 0 || new BigNumber(ev.ethBalance).gt(0)
            }
            if (eType === PoolType.nft) {
                return ev.nftIds.length > 0
            }
            if (eType === PoolType.token) {
                return new BigNumber(ev.ethBalance).gt(0)
            }
        })


        return list
    }, [sudo])


    const currentPaire = useCallback(() => {
        let list = pools.filter((item: MyPoolData) => {
            let eType = parseInt(item.type)
            if (eType === PoolType.trade || eType === PoolType.token) {
                return new BigNumber(item.ethBalance).gt(0)
            }
        });


        let _pools = sort((a: SellQty, b: SellQty) => {
            let aSpotPrice = a.spotPrice;
            let aFee = new BigNumber(a.fee).div(1e20).toFixed()
            aFee = aFee === '0' ? '0.05' : aFee

            let bSpotPrice = b.spotPrice;
            let bFee = new BigNumber(b.fee).div(1e20).toFixed()
            bFee = bFee === '0' ? '0.05' : bFee

            let aSequenceFn = sequenceFn(new BigNumber(aSpotPrice), a.delta, a.bondingCurve, 0);


            let bSequenceFn = sequenceFn(new BigNumber(bSpotPrice), b.delta, b.bondingCurve, 0);
            let aV = aSequenceFn
                .minus(aSequenceFn.times(aFee));
            let bV = bSequenceFn
                .minus(bSequenceFn.times(bFee));
            return bV.minus(aV).toNumber()
        }, list)

        let item: SellQty = _pools[0]

        if (!item) {
            setBestOffer('0')
            return item
        }
        let sequence = sequenceFn(new BigNumber(item.spotPrice), item.delta, item.bondingCurve, 0)
        let fee = new BigNumber(item.fee).div(1e20).toFixed();
        fee = fee === '0' ? '0.05' : fee
        let price = sequence
            .minus(sequence.times(fee))
            .toFixed(0)
        setBestOffer(price)
    }, [pools])


    const handelSetBuyList = useCallback(async () => {
        let list = pools.filter((item: MyPoolData) => {
            let eType = parseInt(item.type)
            if (eType === PoolType.trade || eType === PoolType.nft) {
                return item.nftIds.length > 0
            }
        });

        let _list = R.sort((a: any, b: any) => new BigNumber(a.averagePrice).minus(b.averagePrice).toNumber())(list);

        if (_list && _list[0]) {
            if (_list[0].averagePrice) {
                setFloorPrice(_list[0].averagePrice)
            }

        }

        let buys = compose(
            R.partition((ev: MyPoolData) => ev.owner.id.toLowerCase() === (account || '').toLocaleString()),
            R.sort((a: any, b: any) => new BigNumber(a.averagePrice).minus(b.averagePrice).toNumber()),
            flatten,
            map((ev: MyPoolData) => {
                return ev.nftIds.map((id: string) => {
                    return {
                        ...ev,
                        nftId: id,
                    }
                })
            }),
        )(list || [])
        let buy: any = buys[1]
        setBuyList(buy)

    }, [pools, nftMeta, sudo])

    useEffect(() => {

        const timer = setTimeout(() => {
            setFloorPrice("0")
            if (sudo && nftMeta) {
                currentPaire()
                handelSetBuyList()
            }
        }, 300)
        return () => clearTimeout(timer)

    }, [sudo, pools, account, nftMeta])

    return {floorPrice, buyList, pools, bestOffer, ethVolume, tvl}
}
