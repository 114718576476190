import styled from "styled-components";
import React, {useEffect, useMemo} from "react";
import {BarBuyWidget, BarWidget} from "./widget/type_bar";
import {NftItemCart} from "./widget/nft_shopping_item";
import {ShoppingType} from "./type";
import {useShoppingCart} from "../../provider/shopping_cart_provider";
import * as R from "ramda";
import {compose, reduce} from "ramda";
import BigNumber from "bignumber.js";
import ethImg from "../../assets/images/index_eth.png"
import {useSendModal} from "../../widget/modal/send_modal";
import {useBuyNFTs} from "../../server/router_buy";
import {NftMetadata} from "../../model/nft_metadata";
import {LoadingBtn} from "../../compones/loading_btn";
import {useBuyPrice} from "./hook/buy_price";
import {getBalanceFormat} from "../../utils/formatBalance";
import {HttpStatus} from "../../utils/wait_fn";

export const ShoppingCart: React.FC = () => {
    const {nftMateMap, deleteShopping} = useShoppingCart()
    const {allPrice} = useBuyPrice()

    const [sellList, buyList] = useMemo(() => {
        return compose(
            R.partition((ev: NftMetadata) => {
                return ev.isSell === true;
            }),
            R.sort((a: any, b: any) => new BigNumber(a.averagePrice).minus(b.averagePrice).toNumber()),
        )(Object.values(nftMateMap) || [])
    }, [nftMateMap])


    const handleCreatePairETH = useBuyNFTs()
    const {onSend, status,} = useSendModal(handleCreatePairETH)

    const netSum = useMemo(() => {
        let buy = reduce((acc, cur: NftMetadata) =>
            acc.plus(cur.averagePrice), new BigNumber(0))(buyList)

        let sell = reduce((acc, cur: NftMetadata) =>
            acc.plus(cur.averagePrice), new BigNumber(0))(sellList)

        return sell.minus(buy).toString()
    }, [])

    useEffect(() => {
        if (status === HttpStatus.success) {
            deleteShopping("", true)
        }
    }, [status])

    return (<ShoppingCartStyle>
        <div className="box">
            <BarBuyWidget prices={Object.values(allPrice)} list={buyList} type={ShoppingType.buy}/>
            {
                buyList.length === 0 && <p>N/A</p>
            }

            {
                buyList.map((ev: NftMetadata) => {
                    return <NftItemCart uqid={ev.uqid}/>
                })
            }
            {/*{*/}
            {/*    Object.values(collectionsMap).map((ev: NftMetadata[]) => {*/}
            {/*        let ids = ev.filter((ev) => !ev.isSell)*/}
            {/*        if(!ids.length) return <span />*/}
            {/*        return <NftBuyItemCart price={allPrice[ids[0].ownedId]} nfts={ids}/>*/}
            {/*    })*/}
            {/*}*/}
            <div style={{height: "20px"}}/>
            <BarWidget list={sellList} type={ShoppingType.sell}/>
            {
                sellList.length === 0 && <p>N/A</p>
            }
            {
                sellList.map((ev: NftMetadata) => {
                    return <NftItemCart uqid={ev.uqid}/>
                })
            }
        </div>

        <div className={'bottom'}>
            <div className="tis">
                <h6>Net Sum:</h6>
                <div>
                    <img src={ethImg} alt=""/> <span>{getBalanceFormat(netSum)}</span>
                </div>
            </div>

            <LoadingBtn className={'btn'} isLoading={status} onClick={() => {
                let listNft = R.compose(
                    R.groupBy((student: NftMetadata) => {
                        return student.ownedId;
                    }),
                )(Object.values(nftMateMap));
                let listBuys: any[] = [];
                let listSells: any[] = [];
                let ethAmount = '0';
                let ethMinOutput = '0';

                for (const key in listNft) {
                    let item: NftMetadata[] = listNft[key];
                    let id = key;
                    let nftids = item.map((ev) => ev.id.tokenId);
                    if (item[0].isSell) {
                        let minOutput = new BigNumber(0)
                        item.map((ev) => {
                            minOutput = minOutput.plus(ev.averagePrice);
                        })
                        ethMinOutput = new BigNumber(ethMinOutput).plus(minOutput).toFixed(0)
                        listSells.push([
                            [id, nftids], minOutput.toFixed(0)
                        ]);
                    } else {
                        let maxCost = allPrice[key]
                        ethAmount = new BigNumber(ethAmount).plus(maxCost).toFixed(0)
                        listBuys.push([
                            [id, nftids], maxCost
                        ]);
                    }
                }
                onSend({buys: listBuys, sell: listSells, value: ethAmount, ethMinOutput})
            }}>
                CONFIREM
            </LoadingBtn>
        </div>


    </ShoppingCartStyle>);
}


const ShoppingCartStyle = styled.div`


  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .bottom {
    padding: 10px;

    .tis {
      display: flex;
      justify-content: space-between;
      align-items: center;

      width: 80%;
      margin: auto;
      margin-bottom: 20px;


      h6, div {
        color: #FFFFFF;
      }

      h6 {
        font-weight: 400;
        font-size: 18px;
        line-height: 18px;
        display: flex;
        align-items: center;
        letter-spacing: -0.15em;
      }

      div {
        display: flex;
        align-items: center;


        img {
          display: block;
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }

        span {
          font-style: normal;
          font-weight: 400;
          font-size: 30px;
          line-height: 30px;
          text-align: center;
        }
      }
    }

    .btn {
      cursor: pointer;
      font-weight: 400;
      font-size: 20px;
      line-height: 20px;
      text-align: center;
      letter-spacing: -0.14em;
      color: #000000;
      width: 60%;
      margin: 0 auto;
      background: #FFFFFF;
      border: 2px solid #000000;
      border-radius: 12px;
    }
  }

  width: 430px;
  padding: 5px;
  height: 100%;
  background: #1C1C1C;


  .box {
    flex: 1;
    padding: 5px;
    border-radius: 10px;
    border: 1px solid #8D909A;
    overflow-y: scroll;

  }
`
