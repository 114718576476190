import {useCallback, useState} from "react";
import {CollectionsDataModel} from "../../../model/collections_data";
import {HttpServer} from "../../../net/http_server";
import Web3 from "web3";
import * as R from "ramda";
import {CollectionsData} from "../../../mock_data/collections";
import {forEach} from "ramda";
import {useStore} from "effector-react";
import {$getCollections} from "../../main/store";

export const useCollections = () => {

    const [collectionMap, setCollections] = useState<{ [key: string]: CollectionsDataModel }>({});
    const allCollections = useStore($getCollections)

    const handeGetCollection = useCallback(async (ids: string[]) => {
        let _data: any = {}
        ids.forEach((id)=>{
            let address = Web3.utils.toChecksumAddress(id)
            let collectionData: any = getCollectionById(address, allCollections.collections);

            _data[id] = collectionData

        })

        setCollections(R.mergeDeepRight(collectionMap, _data))


    }, [collectionMap])

    return {collectionMap, handeGetCollection};

}


export function getCollectionById(id: string, collections: any) {
    return  R.find(R.propEq('address', id))(collections)
}
