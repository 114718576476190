import React, {useCallback, useMemo, useState} from "react";
import styled from "styled-components";
import {Grid, Modal} from "@material-ui/core";
import {useForm} from "react-hook-form";
import {LabelInput} from "./widget/label_input";
import {KLink} from "./k_link";
import {CollectionsDataModel} from "../../model/collections_data";
import {PoolItemTitle} from "../collection/widget/pool_item_title";
import {PoolType} from "../collection/pool/nft_pool_item";
import {NftPoolItemHeader} from "../collection/widget/nft_pool_item_header";
import {NFTBalanceInput} from "./widget/nft_balance_input";
import {TokenBalanceInput} from "./widget/token_balance_input";
import ethImg from "../../assets/images/pool_eth.png";
import {UpDataPoolContext, useUpDataPool} from "../../provider/updata_pool_provicer";
import {RootMyPoolData} from "../../model/my_pool_data";
import {BodyStyle, ModalBodyStyle, ModalProps} from "../../provider/model_provider";
import {getBalanceFormat} from "../../utils/formatBalance";
import {CellBottomStyle, UpdateModel} from "./model/update_model";
import {NftSelectModel} from "./model/nft_deposit_model";
import {UpdataLoadingBtn} from "./widget/loading_btn";
import {HttpStatus} from "../../utils/wait_fn";
import {NftWithdrawModel} from "./model/nft_withdraw_model";
import {OwnedNft} from "../../model/get_nft_model";
import cloneImg from "../../assets/images/colse_img.png"
import {range, scan} from "ramda";
import {CURVEEnum} from "../creater_lp/type";
import BigNumber from "bignumber.js";
import {CURVE} from "../../web3/token.config";
import {useWallet} from "use-wallet";
import {TokenWithdrawModel} from "./model/token_withdraw_model";
import {ModelType} from "./type";
import {TokenDepositModel} from "./model/token_deposit_model";

export type NftPoolProps = {
    collections: CollectionsDataModel,
    sudo: RootMyPoolData,
    type: PoolType,
    nfts?: OwnedNft[],
    balance?: string,
} & ModalProps
export const NftPoolModel: React.FC<NftPoolProps> = (props) => {

    let {type, nfts, onDismiss} = props


    return (
        <UpDataPoolContext.Provider value={{
            collections: props.collections,
            sudo: props.sudo,
            nfts: nfts || []
        }}>
            <NftPollStyle>
                <div className="model_title">
                    <PoolItemTitle type={type}/>
                    <img onClick={() => {
                        onDismiss && onDismiss()
                    }} src={cloneImg} className={'cloneImg'} alt=""/>
                </div>
                <CellWidget {...props}/>
            </NftPollStyle>
        </UpDataPoolContext.Provider>

    )
}

const NftPollStyle = styled.div`
  width: 90%;
  margin: 0 auto;
  background-color: #191b1f;
  border-radius: 20px;
  border: 1px solid #2C2F36;
  padding: 0 20px;
  box-sizing: border-box;

  .model_title {
    text-align: center;
    padding: 17px 0;
    border-bottom: 2px solid rgba(255, 255, 255, .1);
    display: flex;
    justify-content: center;
    position: relative;

    .cloneImg {
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      right: 0;
      cursor: pointer;
    }
  }

`

const CellWidget: React.FC<NftPoolProps> = ({collections, balance, sudo, type}) => {
    const {register, errors} = useForm();
    let {name, images: {image_url},} = collections
    const {id, owner, spotPrice, delta} = sudo;
    const [isOpen, setIsOpen] = useState(false);
    const [_type, setType] = useState(0);
    const {nfts} = useUpDataPool();
    const {account} = useWallet();
    const handleDismiss = useCallback(() => {
        setIsOpen(false);
        setType(0)
    }, [setIsOpen]);

    const handleOpen = useCallback((type: number) => {
        setIsOpen(true);
        setType(type)
    }, [setIsOpen]);

    const option = useMemo(() => {

        let _curve = sudo?.bondingCurve === CURVE.EXPONENTIAL_CURVE ? CURVEEnum.EXPONENTIAL : CURVEEnum.LINEAR;
        let xL: number[] = []

        if (type !== PoolType.token) {
            xL = range(1, nfts.length)
        }

        if (xL.length < 2) {
            xL = range(1, 3)
        }

        let _spotPrice = getBalanceFormat(spotPrice, 4)
        let _delta = getBalanceFormat(delta, 4)

        if (type === PoolType.token) {
            return scan((acc, elem) => {
                if (_curve === CURVEEnum.EXPONENTIAL) {
                    return new BigNumber(_spotPrice).times(Math.pow(1 + (_delta / 100), elem)).toFixed(6)
                }
                return acc += parseFloat(_delta)
            }, _spotPrice, xL);
        }
        return scan((acc, elem) => {
            if (_curve === CURVEEnum.EXPONENTIAL) {
                return new BigNumber(_spotPrice).times(Math.pow(1 - (_delta / 100), elem)).toFixed(6)
            }
            return acc -= parseFloat(_delta)
        }, _spotPrice, xL);
    }, [sudo?.bondingCurve, spotPrice, delta,]);

    const disabledToken = useMemo(() => {
        if (!sudo) {
            return true;
        }
        return parseInt(sudo.type) === PoolType.token;
    }, [sudo])

    const isOwnerId = useMemo(() => {
        if (owner.id.toLowerCase() == (account || '').toLowerCase()) {
            return true;
        }
        return false
    }, [owner])
    return (
        <WapStyle>
            <CellBottomStyle container>
                <Grid item md={6} xs={12}>

                    <NftPoolItemHeader id={id} name={name} image_url={image_url} ownerId={owner.id}/>
                    <div className={!isOwnerId ? "disabled" : ""} onClick={() => {
                        if (!isOwnerId) {
                            return
                        }
                        setIsOpen(true)
                    }}>
                        <div className="pt">
                            <LabelInput errors={errors}
                                        inputRef={register({})}
                                        lastTis={''}
                                        disabled={!isOwnerId}
                                        label={'Current Price'}
                                        name={'Price'}
                                        placeholder={getBalanceFormat(spotPrice, 4)}
                            >
                                <img className={'ethImg'} src={ethImg} alt=""/>
                            </LabelInput>
                        </div>
                        <div className="pt">
                            <LabelInput errors={errors}
                                        inputRef={register({
                                            required: 'Please enter a number > 0'
                                        })}
                                        label={'Delta'}
                                        disabled={!isOwnerId}

                                        name={'Delta'}
                                        placeholder={getBalanceFormat(delta, 4)}
                                        unit={`${sudo?.bondingCurve === CURVE.EXPONENTIAL_CURVE ? "%" : "ETH"}`}
                            />
                        </div>
                    </div>
                    <div className="pt">
                        <NFTBalanceInput>
                            <>
                                <UpdataLoadingBtn
                                    disabled={disabledToken || !isOwnerId}
                                    isLoading={HttpStatus.success}
                                    onClick={() => {
                                        handleOpen(1)
                                        // setIsOpen(true);
                                        // setType(1)
                                    }}>
                                    <p className={'deposit'}>Deposit</p>
                                </UpdataLoadingBtn>
                                <div style={{height: "4px"}}/>
                                <UpdataLoadingBtn disabled={disabledToken || !isOwnerId}
                                                  isLoading={HttpStatus.success}
                                                  onClick={() => {
                                                      handleOpen(2)
                                                  }}>
                                    <p className={'deposit'}>Withdraw</p>
                                </UpdataLoadingBtn>
                            </>
                        </NFTBalanceInput>
                    </div>
                    <div className="pt">
                        <TokenBalanceInput handleOpen={handleOpen} balance={balance}/>
                    </div>
                </Grid>
                <Grid item md={6}>
                    <KLink option={option}/>
                </Grid>
            </CellBottomStyle>
            <Modal
                open={isOpen}
                onClose={handleDismiss}
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
            >
                <ModalBodyStyle>
                    <div className={'bg'} onClick={handleDismiss}/>
                    <BodyStyle>
                        {
                            _type === ModelType.Update && <UpdateModel
                                sudo={sudo}
                                collections={collections}
                                type={type}
                                onDismiss={handleDismiss}/>
                        }

                        {
                            _type === ModelType.NftSelect && <NftSelectModel
                                sudo={sudo}
                                collections={collections}
                                type={type}
                                onDismiss={handleDismiss}/>
                        }

                        {
                            _type === ModelType.NftWithdraw && <NftWithdrawModel
                                nfts={nfts}
                                sudo={sudo}
                                collections={collections}
                                type={type}
                                onDismiss={handleDismiss}/>
                        }
                        {
                            _type === ModelType.TokenWithdraw && <TokenWithdrawModel
                                nfts={nfts}
                                sudo={sudo}
                                collections={collections}
                                // type={type}
                                balance={balance || '0'}
                                onDismiss={handleDismiss}
                                type={type}
                            />
                        }
                        {
                            _type === ModelType.TokenDeposit && <TokenDepositModel
                                nfts={nfts}
                                sudo={sudo}
                                collections={collections}
                                // type={type}
                                balance={balance || '0'}
                                onDismiss={handleDismiss}
                                type={type}
                            />
                        }
                    </BodyStyle>
                </ModalBodyStyle>
            </Modal>
        </WapStyle>

    );
}


const WapStyle = styled.div`



  .MuiPaper-root {
    background: transparent;
  }

  .MuiAccordionSummary-root {
    padding: 0 20px;
  }

  .MuiButtonBase-root {
    background: #161515;
    border-radius: 10px;
    height: 78px;
    border: 1px solid transparent;

    .MuiButtonBase-root {
      background: transparent;
    }
  }

  .MuiAccordionSummary-root.Mui-expanded {
    background: #262525;
    border: 1px solid #A19F9F;
  }

  .MuiAccordionDetails-root {
    padding-left: 0;
    padding-right: 0;
  }

  @media (max-width: ${({theme}) => theme.sm}) {
    .MuiButtonBase-root, .MuiAccordionSummary-root.Mui-expanded {
      height: 240px;
    }
  }
`
