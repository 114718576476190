
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Grid} from "@material-ui/core";
import {NaData} from "../index";
import {MyPoolData, RootMyPoolData} from "../../../model/my_pool_data";

import {BuyProps} from "../type";
import {path, sort} from "ramda";
import {useShoppingCart} from "../../../provider/shopping_cart_provider";
import {NftBuyItem} from "../widget/nft_buy_item";
import {useBuyList} from "../hook/useBuyList";


export const BuyWidget: React.FC<BuyProps> = ({ nftMeta}) => {

    const {nftMateMap} = useShoppingCart()

    const {pools} = useBuyList()



    return (
        <>
            {
                pools.length === 0 && <NaData>empty</NaData>
            }
            {
                pools.map((ev: RootMyPoolData) => {
                    let uqid = `${ev.id}_${ev.nftId}`
                    let img = path<string>([uqid, 'metadata', 'image'], nftMeta)
                    if (!img) {
                        img = path<string>([uqid, 'tokenUri', 'gateway'], nftMeta)
                    }
                    let name = path<string>([uqid, 'metadata', 'name'], nftMeta)
                    let averagePrice = path<string>([uqid, 'averagePrice'], nftMateMap)

                    return (
                        <Grid item key={`${uqid}_buy_`}>
                            <NftBuyItem
                                poolData={ev}
                                data={{
                                imgUrl: img,
                                symbol: name,
                                id: ev.id,
                                nftId: ev.nftId,
                                averagePrice: averagePrice || ev.averagePrice || '0',
                                uqid,
                                delta: ev.delta,
                                spotPrice: ev.spotPrice,
                                nftIds: ev.nftIds,
                            }}/>
                        </Grid>
                    );
                })
            }
        </>
    );
}
