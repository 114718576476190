import React, {useState} from "react";
import {MoralisData} from "../../../model/moralis_data";
import {Grid, Snackbar} from "@material-ui/core";
import Roy from "../../../assets/images/question-mark.svg";
import {NaData} from "../index";
import {useWallet} from "use-wallet";
import {MyPoolData} from "../../../model/my_pool_data";
import {useCollection} from "../../../provider/collection_provicer";
import {NftSellItem} from "../widget/nft_sell_item";
import {useShoppingCart} from "../../../provider/shopping_cart_provider";
import {NftMetadata} from "../../../model/nft_metadata";
import {useSellList} from "../hook/useSellList";

type SellProps = {}


export const SellWidget: React.FC<SellProps> = () => {
    const {sudo} = useCollection();

    const {account} = useWallet();
    const {nftMateMap} = useShoppingCart()
    const {myNfts, currentPaire, poolsList} = useSellList()


    return (
        <>
            {
                myNfts.length === 0 && <NaData>empty</NaData>
            }

            <Grid container spacing={4}>
                {
                    myNfts.map((ev: MoralisData) => {
                        let metadata = JSON.parse(ev.metadata)
                        if (!metadata) {
                            metadata = {
                                name: ev.name,
                                image: Roy
                            }
                        }
                        let uqid = `${account}_${ev.token_id}`
                        let current: MyPoolData = currentPaire;
                        let nftMate: NftMetadata = nftMateMap[uqid];
                        return (
                            <Grid item key={`sell_${uqid}`}>
                                <NftSellItem
                                    poolsList={poolsList}
                                    data={{
                                        id: current?.id,
                                        imgUrl: metadata.image || metadata.image_url,
                                        nftId: ev.token_id,
                                        symbol: metadata.name || ev.name,
                                        price: nftMate?.averagePrice || current?.averagePrice,
                                        uqid,
                                    }}
                                    onClick={(isAdd: boolean) => {
                                    }}
                                    // tis={() => setOpen(true)}
                                />
                            </Grid>
                        );
                    })
                }
            </Grid>

            {/*<Dialog onClose={handleClose} open={open}>*/}
            {/*    <Alert severity="warning">Please deselect the sold NFT first.</Alert>*/}
            {/*</Dialog>*/}
        </>
    );
}
