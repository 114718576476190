import React, {useMemo} from "react";
import styled from "styled-components";
import Roy from "../../../assets/images/question-mark.svg"
import Jazzicon from '@raugfer/jazzicon';
import indexEth from "../../../assets/images/index_eth.png"


type NftBuyItemProps = {
    data: {
        imgUrl?: string,
        symbol?: string,
        addr?: string
    },
}
export const ActivityItem: React.FC<NftBuyItemProps> = ({data}) => {
    const imgBase64 = useMemo(() => {
        if (!data.addr) return;
        return 'data:image/svg+xml;base64,' + btoa(Jazzicon(data.addr));
    }, [data])
    return (
        <NftBuyItemStyle className={'max'}>
            <div className="top">
                {imgBase64 && <img src={imgBase64} className={'addr'} alt=""/>}

                <img src={data.imgUrl || Roy} alt="" className="nft_img"/>
            </div>
            <div className="bottom">
                <p className={'nftId'}>#{data.symbol}</p>
                <div className="lastSale">
                    <h4><img src={indexEth} alt=""/>7.4</h4>
                </div>
            </div>


        </NftBuyItemStyle>
    );
}

const NftBuyItemStyle = styled.div`
  background: #353840;
  border-radius: 7px;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    background-color: #5A5A64;
  }

  .bottom {
    padding: 5px;
    box-sizing: border-box;

    .lastSale {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 11px;
      letter-spacing: -0.14em;
      color: #A0A0AA;
      justify-content: end;

      h4 {
        display: flex;
        align-items: center;
        color: #FFFFFF;
        font-size: 11px;

        img {
          display: block;
          width: 15px;
          margin-right: 5px;
        }
      }
    }


    .nftId {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      display: flex;
      align-items: center;
      letter-spacing: -0.1em;

      color: #FFFFFF;
    }
  }

  .top {
    position: relative;

    .addr {
      display: block;
      width: 20px;
      border-radius: 40px;
      position: absolute;
      top: 10px;
      left: 10px;
    }

    .nft_img {
      display: block;
      width: 130px;
      height: 130px;
      margin: 0 auto;
    }
  }

`
