import React, {useMemo} from "react";
import styled from "styled-components";
import ethImg from "../../../assets/images/index_eth.png"
import {ShoppingType} from "../type";
import {reduce} from "ramda";
import BigNumber from "bignumber.js";
import {getBalanceFormat} from "../../../utils/formatBalance";
import {NftMetadata} from "../../../model/nft_metadata";

type BarWidgetProps = {
    type: ShoppingType,
    list: NftMetadata[]
}
export const BarWidget: React.FC<BarWidgetProps> = ({type, list,}) => {
    let color = type === ShoppingType.buy ? "#4FCFA9" : "#E0446A"
    let typeStr = type === ShoppingType.buy ? "Buy" : "Sell"

    const total = useMemo(() => {
        return reduce((acc, cur: NftMetadata) =>
            acc.plus(cur.averagePrice), new BigNumber(0))(list)
    }, [list])
    return (
        //<span className="clear">Clear</span>
        <BarStyle>
            <h4 className="title buy" style={{color: color}}>&gt;{typeStr} {list.length} Nfts
            </h4>
            <div className="price">
                <p>{typeStr} Total:</p>
                <h4><img src={ethImg} alt=""/>{getBalanceFormat(total)}</h4>
            </div>
        </BarStyle>
    );
}

export const BarBuyWidget: React.FC<{ prices: string[] } & BarWidgetProps> = ({type, list, prices}) => {
    let color = type === ShoppingType.buy ? "#4FCFA9" : "#E0446A"
    let typeStr = type === ShoppingType.buy ? "Buy" : "Sell"

    const total = useMemo(() => {
        return reduce((acc, cur: string) =>
            acc.plus(cur), new BigNumber(0))(prices)
    }, [prices])
    return (
        //<span className="clear">Clear</span>
        <BarStyle>
            <h4 className="title buy" style={{color: color}}>&gt;{typeStr} {list.length} Nfts
            </h4>
            <div className="price">
                <p>{typeStr} Total:</p>
                <h4><img src={ethImg} alt=""/>{getBalanceFormat(total)}</h4>
            </div>
        </BarStyle>
    );
}

const BarStyle = styled.div`

  .title {
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 28px;

    display: flex;
    align-items: center;
    letter-spacing: -0.18em;
    display: flex;
    justify-content: space-between;

    .clear {
      padding: 3px 20px;
      background: #242424;
      border: 1px solid #D9D9D9;
      border-radius: 12px;
      cursor: pointer;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.15em;
      color: #FFFFFF;
    }

    &.buy {
      color: #4FCFA9;

    }

    &.sell {
      color: #E0446A;

    }
  }

  .price {
    margin-top: 10px;
    margin-bottom: 15px;
    padding: 10px;
    background: #8D909A;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;

    p {
      font-weight: 400;
      font-size: 18px;
      letter-spacing: -0.15em;
      color: #FFFFFF;
      flex: 1;
    }

    h4 {
      display: flex;
      align-items: center;

      img {
        display: block;
        width: 15px;
      }

      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      text-align: center;

      color: #FFFFFF;

    }
  }

`
