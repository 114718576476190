import React, {createContext, useCallback, useContext, useMemo, useState} from "react";
import {Modal, SwipeableDrawer} from "@material-ui/core";
import {ShoppingCart} from "../view/shopping_cart";
import {MyPoolDataMap, NftMateMap, PairsMap, SellPoolMap, SellQty} from "../view/collection/type";
import {NftMetadata} from "../model/nft_metadata";
import * as R from "ramda";
import {MyPoolData, RootMyPoolData} from "../model/my_pool_data";
import BigNumber from "bignumber.js";
import {sequenceBuyFn, sequenceFn} from "../utils/sequence";
import {path, sort} from "ramda";
import {SellItem} from "../view/shopping_cart/type";


const getSellPrices = (nftMetadatas: NftMetadata[], myPoolDatas: MyPoolData[]) => {
    const fn1 = (aQty: number): SellItem => {
        let pools: MyPoolData[] = [];

        for (let i = 0; i < myPoolDatas.length; i++) {
            let item: MyPoolData = myPoolDatas[i];
            let aSpotPrice = item.spotPrice;
            let aFee = new BigNumber(item.fee).div(1e20).toFixed()
            aFee = aFee === '0' ? '0.01' : aFee
            let aSequenceFn = sequenceFn(new BigNumber(aSpotPrice), item.delta, item.bondingCurve, aQty || 0);
            let aV = aSequenceFn.times(1 - (parseFloat(aFee) * (aQty || 0))).toString();
            item.sellPrice = aV;
            pools.push(item)
        }

        let poolsList = sort((a: MyPoolData, b: MyPoolData) => {
            return new BigNumber(b.sellPrice).minus(a.sellPrice).toNumber()
        }, pools)


        return {
            sellPrice: poolsList[0].sellPrice,
            id: poolsList[0].id
        }
    }

    let list: SellItem[] = [];
    for (let i = 0; i < nftMetadatas.length; i++) {
        list.push(fn1(i))
    }

    return list;

}
const setPrice = (nftMateMap: NftMateMap, pairsMap: PairsMap, myPoolDataMap: MyPoolDataMap) => {
    let _group = R.compose(
        R.groupBy((student: any) => {
            return student.ownedId;
        }),
    )(Object.values(nftMateMap) || [])

    Object.values(_group).forEach((pools: any) => {
        if (pools[0].isSell) {
            return;
        }
        for (let i = 0; i < pools.length; i++) {
            let item: NftMetadata = pools[i];
            let pairs: RootMyPoolData = pairsMap[item.ownedId]
            let aSpotPrice = pairs.spotPrice
            let aQty = i
            let aFee = new BigNumber(pairs.fee).div(1e20).toFixed()
            aFee = aFee === '0' ? '0.01' : aFee
            let aSequenceFn = sequenceBuyFn(new BigNumber(aSpotPrice), pairs.delta, pairs.bondingCurve, aQty);
            let aV = aSequenceFn.times(1 + (parseFloat(aFee) * aQty || 0)).toString();
            item.averagePrice = aV;
            nftMateMap[item.uqid] = item
        }
    })
    let sellList = Object.values(nftMateMap).filter((item: NftMetadata) => item.isSell)

    let sellGroup = R.compose(
        R.groupBy((student: NftMetadata) => {
            return student.contract.address;
        }),
    )(sellList)

    for (const sellGroupKey in sellGroup) {
        let oldNftMetadatas = sellGroup[sellGroupKey];
        let newNftMetadatas = getSellPrices(oldNftMetadatas, myPoolDataMap[sellGroupKey])
        for (let i = 0; i < oldNftMetadatas.length; i++) {
            let oldItem: NftMetadata = oldNftMetadatas[i];
            let newItem: SellItem = newNftMetadatas[i];

            nftMateMap[oldItem.uqid].averagePrice = newItem.sellPrice;
            nftMateMap[oldItem.uqid].ownedId = newItem.id;
        }
    }

    return nftMateMap;
}

export const ShoppingCartProvider: React.FC = ({children}) => {

    const [isOpen, setIsOpen] = useState(false);
    const [nftMateMap, setNftMeta] = useState<NftMateMap>({});
    const [pairsMap, setPairsMap] = useState<PairsMap>({});
    const [myPoolDataMap, setMyPoolDataMap] = useState<MyPoolDataMap>({});

    const addShopping = useCallback((mate: NftMetadata, {
        poolData,
        myPoolDataList,
    }: {
        poolData?: RootMyPoolData,
        myPoolDataList?: MyPoolData[],
    }) => {
        let {uqid} = mate;
        let _d: any = {}
        _d[uqid] = mate;
        let newNftMeta = {...nftMateMap, ..._d};

        let _a: any = {}
        if (poolData) {
            _a[mate.ownedId] = poolData;
        }
        let newPairsMap = {...pairsMap, ..._a};
        setPairsMap(newPairsMap)

        let _c: any = {}
        let contractId = path<string>(["contract", "address"], mate);
        if (myPoolDataList && contractId) {
            _c[contractId] = myPoolDataList
        }
        let newMyPoolData = {...myPoolDataMap, ..._c};
        setMyPoolDataMap(newMyPoolData)

        newNftMeta = setPrice(newNftMeta, newPairsMap, newMyPoolData)
        setNftMeta(newNftMeta)


    }, [nftMateMap, pairsMap])

    const deleteShopping = useCallback((uqId: string, isAll?: boolean) => {
        if (isAll) {
            Object.keys(nftMateMap).map((key) => {
                delete nftMateMap[key]
            })
        } else {
            delete nftMateMap[uqId]

        }
        setNftMeta({...nftMateMap})
        setPrice(nftMateMap, pairsMap, myPoolDataMap)
    }, [nftMateMap, pairsMap, myPoolDataMap])

    const collectionsMap: Record<string, NftMetadata[]> = useMemo(() => {
        return R.compose(
            R.groupBy((student: NftMetadata) => {
                return student.ownedId.toLowerCase();
            }),
        )(Object.values(nftMateMap));
    }, [nftMateMap]);

    return (
        <ShoppingCartContext.Provider value={{
            addShopping,
            show: setIsOpen,
            nftMateMap,
            myPoolDataMap,
            pairsMap,
            deleteShopping,
            collectionsMap

        }}>
            {children}
            <SwipeableDrawer
                anchor={"right"}
                open={isOpen}
                onClose={() => {
                    setIsOpen(false)
                }}
                onOpen={() => {
                }}
            >
                <ShoppingCart/>
            </SwipeableDrawer>

        </ShoppingCartContext.Provider>
    );
}

export const ShoppingCartContext = createContext<ShoppingCart>({
    show: () => {
    },
    nftMateMap: {},
    pairsMap: {},
    myPoolDataMap: {},
    collectionsMap: {},
    addShopping: (mate: NftMetadata, data: {
        poolData?: RootMyPoolData,
        myPoolDataList?: MyPoolData[],
    }) => {
    },
    deleteShopping: (uqId: string, isAll) => {
    }
});

interface ShoppingCart {
    show: (isOpen: boolean) => void,
    nftMateMap: NftMateMap,
    pairsMap: PairsMap,
    myPoolDataMap: MyPoolDataMap,
    collectionsMap: Record<string, NftMetadata[]>,
    addShopping: (mate: NftMetadata, data: {
        poolData?: RootMyPoolData,
        myPoolDataList?: MyPoolData[],
    }) => void
    deleteShopping: (uqId: string, isAll?: boolean) => void
}


export const useShoppingCart = () => {
    return useContext(ShoppingCartContext);
}

