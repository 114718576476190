import {createRequestEffect} from "../../../utils/request";
import {CollectionsData} from "../../../mock_data/collections";
import {zgf} from "../../../net/http_server";
import {indexBy, prop} from "ramda";

const initial: any = CollectionsData
export const getCollections = createRequestEffect<any, any>({
    // url: zgf,
    url: "https://api.rootswap.xyz/api/root/get_collection",
    method: 'GET',
    processing: (ev: any) => {
       try{
           let {data: {collections}} = ev;
           let {collections: oldCollecyions} = initial;
           oldCollecyions = oldCollecyions.map((ev: any) => {
               return {
                   ...ev,
                   address: ev.address.toLowerCase()
               }
           })
           oldCollecyions = indexBy<any>(prop('address'), oldCollecyions);
           let _newCollecttions = collections.map((ev: any) => {
               return {
                   // ...oldCollecyions[ev.id],
                   ...ev,
               }
           })

           return {collections: _newCollecttions}
       }catch (e) {
           console.error(e)
       }
    }
})
