import {useCallback, useEffect, useState} from "react";
import {indexBy, mapObjIndexed, path, prop} from "ramda";
import {NftEventMap, NftMateMap} from "../type";
import {HttpServer} from "../../../net/http_server";
import {useParams} from "react-router-dom";
import * as R from "ramda";


export const useActivity = () => {
    const [nftMeta, setNftMeta] = useState<NftMateMap>({});
    const [nftEvents, setNftEvents] = useState<NftEventMap>({})
    let {id} = useParams<{ id: string, type: string }>();


    const handeGetMateData = useCallback(async (map: NftEventMap, nftMeta: NftMateMap) => {
        mapObjIndexed(async (value, key) => {
            let _meta: any = await HttpServer.alchemyapi(id, value.tokenId || '0')
            nftMeta[key] = _meta;
            let _data: any = {}
            _data[key] = _meta;
            setNftMeta(R.mergeDeepRight(nftMeta, _data))
        }, map)
        setNftEvents(map)
    }, [nftEvents, nftMeta, id])

    const handleGetPairs = useCallback(async () => {
        let result: any = await HttpServer.getActivity(id);


        let list = path<any[]>(['data', 'getNftEvents', 'items'], result) || [];
        let permissionMap = indexBy(prop('tokenId'), list);
        handeGetMateData(permissionMap, nftMeta)
    }, [id])

    useEffect(() => {
        handleGetPairs()
    }, [])


    return {nftEvents, nftMeta}

}
