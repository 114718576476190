import React, {useMemo} from "react";
import styled from "styled-components";
import {useStore} from "effector-react";
import {$getMoralisFileData} from "../inventory/store";
import {Grid} from "@material-ui/core";
import {MoralisData} from "../../model/moralis_data";
import Roy from "../../assets/images/question-mark.svg";
import {NftCardItem} from "../inventory/widget/nft_card_item";
import {Contract} from "web3-eth-contract";
import {useCreaterDataPool} from "../../provider/creater_lp_provider";
import {useWeb3} from "../../provider/web3_provider";
import erc721 from "../../web3/abi/erc721";
import {ApproveButton} from "../../widget/base/approve_button";
import {StepBtnWidget, StepStyle} from "./widget/step_btn";
import {StepEnum} from "./type";

export const SelectNft: React.FC = () => {
    const data = useStore($getMoralisFileData)
    const {web3, contrcts,} = useWeb3();
    const {moralisData, nftidsStateAction, setActiveStep} = useCreaterDataPool()
    let [moralis] = moralisData
    const contract: Contract = useMemo(() => {
        return new web3.eth.Contract(erc721, moralis.token_address)
    }, [moralis])

    let [ids, setIds] = nftidsStateAction;

    const listNft = useMemo(() => {
        return data?.result.filter((ev: MoralisData) => ev.token_address == moralis.token_address)
    }, [data, moralis])

    return (
        <WapStyle>
            {/*<h3 className={'your_details'}>Your Pool Details</h3>*/}

            <h5>Deposit {ids.length} NFTs</h5>

            <Grid className={'nft_wap'} container spacing={2}>

                {
                    listNft.map((ev: MoralisData, index: number) => {
                        let metadata = JSON.parse(ev.metadata)
                        if (!metadata) {
                            metadata = {
                                name: ev.name,
                                image: Roy
                            }
                        }
                        return <Grid item key={`${ev.name}_${ev.token_id}`}>
                            <NftCardItem
                                data={{
                                    id: ev.token_address,
                                    imgUrl: metadata.image || metadata.image_url,
                                    nftId: ev.token_id,
                                    nftIds: [],
                                    symbol: metadata.name || ev.name,
                                    hidePrice: true
                                }}
                                onCb={(select: boolean) => {
                                    listNft[index]['isChecked'] = select;

                                    let ids = listNft
                                        .filter((ev: any) => ev.isChecked)
                                        .map((ev: MoralisData) => ev.token_id)
                                    setIds(ids);
                                }}
                            />
                        </Grid>
                    })
                }
            </Grid>

            <div className="bottom">
                <ApproveButton
                    contractAddress={moralis.token_address}
                    contract={contract}
                    spender={contrcts.FACTORY_A}
                >
                    <div className="footer">
                        <StepBtnWidget text={"Previos Step"} page={StepEnum.SelectToken}/>
                        <StepStyle onClick={() => {
                            setActiveStep(StepEnum.CreaterPool)
                        }}>
                            Next Step
                        </StepStyle>
                    </div>


                </ApproveButton>
            </div>

        </WapStyle>
    )
}


const WapStyle = styled.div`

  .bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    > div {
      width: 100%;
    }

    .footer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 50px;
    }

  }

  .nft_wap {
    padding: 20px;
    box-sizing: border-box;
  }

  padding-top: 20px;

  .your_details {
    text-align: center;
    font-size: 24px;
    color: white;
    margin-bottom: 20px;
  }

  h4 {
    font-size: 18px;
    padding-left: 100px;
    color: white;
    margin-bottom: 10px;
  }

  h5 {
    text-align: center;
    padding-top: 20px;
    color: #fff;
  }
`
