import React from "react";
import styled from "styled-components";
import miniImg from "../../../assets/images/mini_ge.png"
import indexEth from "../../../assets/images/index_eth.png"
import {CellCollectionsData} from "../type";
import {getBalanceFormat} from "../../../utils/formatBalance";
import {useHistory} from "react-router-dom";
import {CollectionInterface} from "../../../provider/collections_provicer";
import {useGetList} from "../../collection/hook/useGetList";
import nftImg from "../../../assets/images/null_nft_icon.png"

const CellWidget: React.FC<{ label: string, value: string }> = ({label, value}) => {
    return (
        <CellStyle>
            <h4>{label}</h4>
            <h5><img src={indexEth} alt=""/>{value}</h5>
        </CellStyle>
    );
}

const CellStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;

  h4 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: white;
  }

  h5 {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: white;
    letter-spacing: 0;

    img {
      display: block;
      width: 20px;
      margin-right: 5px;
    }
  }
`

type CryptoSimejiProps = {
    collection: CollectionInterface
    data?: CellCollectionsData,
}

export const CryptoWidget: React.FC<CryptoSimejiProps> = ({data, collection}) => {
    const history = useHistory();
    const {floorPrice, bestOffer, pools} = useGetList({sudo: collection?.sudo, nftMeta: collection?.nftMeta})
    if (!data) {
        return <div/>
    }
    return (
        <CryptoStyle>
            <img src={data.img || nftImg} alt="" className="icon"/>
            <h3 className="btn" onClick={() => {
                history.push(`/collection/buy/${data.ref.address}`)
            }}>{data.name}</h3>
            <CellWidget value={getBalanceFormat(floorPrice)} label={'Floor price'}/>
            <CellWidget value={getBalanceFormat(bestOffer)} label={'Best  Offer'}/>
            <CellWidget value={`${getBalanceFormat(data.ethVolume)}`} label={'Volume'}/>
            {/*<CellStyle>*/}
            {/*    /!*<h4>Change</h4>*!/*/}
            {/*    /!*<h5 style={{color: "#4FCFA9"}}>+73 %</h5>*!/*/}
            {/*</CellStyle>*/}
            <CellStyle>
                <h4>Listings</h4>
                <h5>{
                    collection?.sudo ? pools.length : "--"
                }</h5>
            </CellStyle>

            <CellWidget value={getBalanceFormat(data.ethOfferTVL)} label={'TVL'}/>
        </CryptoStyle>
    );
}


const CryptoStyle = styled.div`
  background: linear-gradient(0deg, rgba(58, 58, 58, 0.35), rgba(58, 58, 58, 0.35)), url(${miniImg});
  background-size: contain;
  border: 1px solid #FFFFFF;
  border-radius: 20px;
  padding: 12px 40px;
  box-sizing: border-box;

  .icon {
    display: block;
    width: 80px;
    margin: 0 auto;
    border-radius: 100px;
  }

  .btn {
    width: 55%;
    margin: 15px auto;
    background: #FFFFFF;
    border: 1px solid #000000;
    border-radius: 12px;
    text-align: center;
    font-size: 16px;
    color: #080808;
    padding: 13px 0;
    font-weight: 400;
    cursor: pointer;

  }
`
