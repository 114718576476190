import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import React, {useEffect, useMemo} from "react";
import styled from "styled-components";
import {Tooltip} from "@material-ui/core";
import TisIcon from "../../../assets/images/tis_img.png";
import indexEth from "../../../assets/images/index_eth.png"
import {useHistory} from "react-router-dom";
import {getBalanceFormat} from "../../../utils/formatBalance";
import {HttpStatus, useWait} from "../../../utils/wait_fn";
import {HttpServer} from "../../../net/http_server";
import {LoadingWidget} from "../../../widget/base/loading_widget";
import {useStore} from "effector-react";
import {$getCollections} from "../store";
import {CollectionsData} from "../../../mock_data/collections";
import {CollectionInterface, useCollections} from "../../../provider/collections_provicer";
import {useGetList} from "../../collection/hook/useGetList";

interface Data {
    name: string;
    img: string;
    floorPrice: number;
    bestOffer: number;
    d: number;
    volD: number;
    listing: number;
    ref?: any

}

function createData(
    name: string,
    img: string,
    floorPrice: number,
    bestOffer: number,
    d: number,
    volD: number,
    listing: number,
    ref?: any
): Data {
    return {name, img, floorPrice, bestOffer, d, volD, listing, ref};
}


function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
) => number {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: HeadCell[] = [
    {
        id: "name",
        numeric: false,
        disablePadding: true,
        label: "Name"
    },
    {id: "floorPrice", numeric: true, disablePadding: false, label: "Floor price"},
    {id: "bestOffer", numeric: true, disablePadding: false, label: "Best  Offer"},
    // {id: "d", numeric: true, disablePadding: false, label: "7D %"},
    {id: "volD", numeric: true, disablePadding: false, label: "TVL"},
    {id: "listing", numeric: true, disablePadding: false, label: "Listing"}
];

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (
        event: React.MouseEvent<unknown>,
        property: keyof Data
    ) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const {
        order,
        orderBy,
        onRequestSort
    } = props;
    const createSortHandler = (property: keyof Data) => (
        event: React.MouseEvent<unknown>
    ) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "center" : "left"}
                        padding={headCell.disablePadding ? "none" : "default"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}

                        >
                            {headCell.label}
                            <Tooltip title="----------" aria-label="add">
                                <img className={'tis_icon'} src={TisIcon} alt=""/>
                            </Tooltip>
                            {orderBy === headCell.id ? (
                                <span className={'visuallyHidden'}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}


export function AllCollectionTable() {
    const [order, setOrder] = React.useState<Order>("asc");
    const [orderBy, setOrderBy] = React.useState<keyof Data>("name");
    const [selected, setSelected] = React.useState<string[]>([]);
    const history = useHistory();

    const {collections: data} = useStore($getCollections)



    const rows: any[] = useMemo(() => {
        if (!data) return [];
        return data.filter((ev: any) => ev.name).map((ev: any) => {
            let {name, images, sell_quote, pool_count, item_count, analytics} = ev;
            return createData(name, images.image_url, sell_quote, pool_count, analytics.volume_24_hour, analytics.volume_all_time, item_count, ev);
        })
    }, [data])

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data
    ) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n: Data) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const isSelected = (name: string) => selected.indexOf(name) !== -1;
    const {collections} = useCollections()

    return (
        <AllCollectionStyle>
            <Paper>
                <TableContainer>
                    <Table
                        aria-labelledby="tableTitle"
                        size={"small"}
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {stableSort(rows, getComparator(order, orderBy))
                                .map((row: any, index) => {
                                    const isItemSelected = isSelected(row.name);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.name}
                                            selected={isItemSelected}
                                            onClick={() => {
                                                history.push(`/collection/buy/${row.ref.address}`)
                                            }}
                                        >
                                            <ItemWidget collection={collections[row.ref.address]} labelId={labelId}
                                                        row={row}/>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </AllCollectionStyle>
    );
}

const ItemWidget: React.FC<{
    row: any, labelId: string, collection: CollectionInterface
}> = ({row, labelId, collection}) => {

    const {floorPrice, bestOffer, ethVolume, tvl,} = useGetList({sudo: collection?.sudo, nftMeta: collection?.nftMeta})


    return (<>
        <TableCell
            component="th"
            id={labelId}
            scope="row"
            padding="none"
            className="label"
        >
            <TableCellStyle>
                <img className={'label'} src={row?.img || ""} alt=""/>{row.name}
            </TableCellStyle>
        </TableCell>
        <TableCell align="center">
            <TableCellStyle className={'center'}>
                {getBalanceFormat(floorPrice)} <img className={'eth'}
                                                    src={indexEth} alt=""/>
            </TableCellStyle>
        </TableCell>
        <TableCell align="center">
            <TableCellStyle className={'center'}>
                {getBalanceFormat(bestOffer)} <img className={'eth'} src={indexEth} alt=""/>
            </TableCellStyle>
        </TableCell>
        {/*<TableCell align="center" style={{color: "#4FCFA9"}}>+{row.d}%</TableCell>*/}
        <TableCell align="center">
            <TableCellStyle className={'center'}>
                {getBalanceFormat(tvl)} <img className={'eth'} src={indexEth}
                                             alt=""/>
            </TableCellStyle>
        </TableCell>
        <TableCell align="center">{collection?.sudo ? Object.values(collection.sudo).length : '--'}</TableCell>
    </>)
}

const TableCellStyle = styled.div`
  display: flex;
  align-items: center;

  &.center {
    justify-content: center;
  }


  .label {
    display: block;
    margin: 0 10px 0 20px;
    width: 40px;
  }

  .eth {
    display: block;
    width: 15px;
    margin-left: 20px;
  }
`


const AllCollectionStyle = styled.div`

  .tis_icon {
    display: block;
    width: 15px;
    margin: 0 5px;
  }

  .MuiPaper-root {
    background-color: transparent;

    .MuiTableBody-root {
      .MuiTableRow-hover {
      }

      .MuiTableRow-hover:hover {
        background-color: #242424;
      }
    }

    .MuiTableCell-root {
      border-bottom: 1px solid #8D909A;
      padding: 0;
      box-sizing: border-box;
    }

    .MuiTableCell-sizeSmall {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .MuiTableCell-body {
      padding-top: 20px;
      padding-bottom: 20px;
      color: white;
      font-family: 'ZX';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
    }

    .MuiTableSortLabel-active {
      color: white !important;
    }

    .MuiTableCell-head {
      .MuiTableSortLabel-root {
        color: #8D909A;
        font-size: 16px;
        line-height: 16px;
      }
    }

    .visuallyHidden {
      border: 0;
      clip: rect(0 0 0 0);
      color: white;
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      top: 20px;
      width: 1px;
    }

    .MuiTableSortLabel-root {
      color: white;
    }
  }

  .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon {
    color: white;
  }

`
