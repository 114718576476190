import React, {} from "react";
import {TextField, Tooltip} from "@material-ui/core";
import styled from "styled-components";
import {useModel} from "../../../hook/use_model";
import {BottomTisWidget} from "../../../widget/base/bottom_tis_widget";
import tisImg from "../../../assets/images/i@2x.png";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TisIcon from "../../../assets/images/tis_img.png";


interface InputInterFace {
    label: string,
    unit?: string,
    tis?: string[],
    onChange?: (e: any) => void,
    placeholder: string,
    setValue?: (ev: string) => void,
    inputRef: React.Ref<any>,
    errors: any;
    name?: string,
    buttonText?: string,
    type?: string,
    sunTitle?: any,
    lastTis?: string,
    disabled?:boolean
}


export const LabelInput: React.FC<InputInterFace> = ({
                                                         label,
                                                         placeholder,
                                                         unit,
                                                         inputRef,
                                                         errors,
                                                         name,
                                                         type,
                                                         onChange,
                                                         sunTitle = '',
                                                         lastTis = '',
                                                         tis,
                                                         children,
                                                         disabled
                                                     }) => {


    return (
        <div className={'LabelInput'}>
            <LabelStyle>
                <p className={'left'}>
                    {label}


                    {tis && <Tooltip title={tis[0]} placement="top" arrow aria-label="add">
                        <img className={'tis_icon'} src={TisIcon} alt=""/>
                    </Tooltip>}
                </p>
                <p className='right'>
                    {lastTis}
                </p>


            </LabelStyle>

            <FlexStyle className={'FlexStyle'}>
                <InputStyle>
                    <TextField name={name || label}
                               error={Boolean(errors[name || label])}
                               placeholder={placeholder}
                               unselectable={'off'}
                               fullWidth={true}
                               inputRef={inputRef}
                               type={type}
                               disabled={disabled}
                               onChange={(ev) => onChange && onChange(ev)}
                    />
                    {
                        unit ?
                            <span className={'unit'}>{unit}</span> : children
                    }
                </InputStyle>


            </FlexStyle>
            <HelperTextStyle>
                <p className={'err'}>{errors[name || label] ? errors[name || label].message : ''}</p>
                {sunTitle}
            </HelperTextStyle>
        </div>
    );
}

export const ButtonStyle = styled.div`
  height: 30px;
  width: 45px;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  margin-right: 15px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
const HelperTextStyle = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;

  .err {
    color: red;
    font-size: 14px;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    font-size: 14px;
    align-items: center;
    line-height: 1;
    color: #2A1D69FF;

    p {
      display: block;
      margin-right: 5px;
    }

    a {
      color: ${({theme}) => theme.color.greens[100]};
      text-decoration: none;
      font-size: 12px;

    }
  }
`;

const LabelStyle = styled.h2`
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #FFFFFF;

  .left {
    color: #FFFFFF;
    line-height: 1;
    font-size: 14px;
    font-weight: 400;
    margin-right: 5px;
    display: flex;
    align-items: center;

    img {
      display: block;
      width: 15px;
      margin-left: 5px;
      height: 15px;
    }
  }

  .right {
    font-size: 14px;
    font-weight: 400;
    color: #7094C7;
  }

  img {
    display: block;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  @media (max-width: ${({theme}) => theme.sm}) {
    .left {
      font-size: 14px;
    }
  }

`


export const FlexStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  margin: 5px 0;
  box-sizing: border-box;

  .MuiInputBase-root {
    color: white;
  }

  .MuiInput-underline:before, .MuiInput-underline:after {
    display: none;
  }

  .balance {
    color: #6F6F7D;
    font-size: 14px;
    width: 120px;

    span {
      color: #7B7A89;

    }
  }
`

export const InputStyle = styled.div`
  display: flex;
  background: #2C2F36;

  flex: 1;
  align-items: center;
  border: 1px solid #323841;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 600;
  padding: 6px 20px;

  span {
    font-size: 14px;
    font-weight: 400;
    color: #7688B7;
    display: inline-block;
    //padding-left: 20px;
  }

  .unit {
    color: #fff;
  }

  .MuiInputBase-input {
    font-family: 'ZX', sans-serif;

    &::-webkit-input-placeholder {
      text-align: start;
    }
  }


  .balance {
    color: #7B7A89;
    width: 200px;

    span {
      color: #7B7A89;

    }
  }

`


