import {useWallet} from "use-wallet";
import {useCallback, useEffect, useMemo, useState} from "react";
import {ethers} from 'ethers'
import {useSendFn} from "../utils/web3_send";
import {useWeb3} from "../provider/web3_provider";
import {Contract} from "web3-eth-contract";
import BigNumber from "bignumber.js";


export const useApprove = (contract: Contract | undefined, contractAddress: string, spender: string) => {

    const handleSendFn = useSendFn(contractAddress);

    const handleApprove = useCallback(async () => {
        if (!contract) return;
        try {
            let method = contract.methods.setApprovalForAll(spender, true);

            let data = await handleSendFn(method);

            return data;
        } catch (e) {
            throw e
        }

    }, [contract, spender, contractAddress]);
    return [handleApprove];
}


export const useAllowance = (contract: Contract | undefined, spender: string) => {
    const [allowance, setAllowance] = useState(false);
    const {account,} = useWallet();
    const fetchAllowance = useCallback(async () => {
        if (!contract) return;
        const allowance = await contract.methods
            .isApprovedForAll(account, spender)
            .call();
        setAllowance(allowance)
    }, [account, contract, spender]);

    useEffect(() => {
        fetchAllowance()
    }, [contract, spender]);
    return allowance;
}


export const useBalance = (ERC20: Contract | undefined, dafAccount?: string) => {
    const [allowance, setAllowance] = useState(new BigNumber(0));
    const {account} = useWallet();
    const fetchAllowance = useCallback(async () => {
        if (!ERC20) return;
        const allowance = await ERC20.methods.balanceOf(dafAccount || account).call();
        setAllowance(new BigNumber(allowance));
    }, [account, dafAccount, ERC20, allowance]);

    useEffect(() => {
    }, [ERC20]);
    return allowance;
}

// 使用erc20 地址查看余额
export const useAddrBalance = (ercAddr: string | undefined, dafAccount?: string) => {
    const [allowance, setAllowance] = useState(new BigNumber(0));
    const {account} = useWallet();
    const {web3} = useWeb3();
    const fetchAllowance = useCallback(async () => {
        if (!ercAddr) return;
        const ERC20 = new web3.eth.Contract([] as Array<any>, ercAddr);

        const allowance = await ERC20.methods.balanceOf(dafAccount || account).call();
        setAllowance(new BigNumber(allowance));
    }, [account, dafAccount, allowance]);

    useEffect(() => {
    }, [ercAddr]);
    return allowance;
}


//get erc20 合约
export const useGetERC20 = (ercAddr: string | undefined) => {
    const [ERC20, setERC20] = useState();
    const {account} = useWallet();
    const {web3} = useWeb3();
    const fetchAllowance = useCallback(async () => {
        if (!ercAddr) return;
        const _erc20 = new web3.eth.Contract([] as Array<any>, ercAddr);

        setERC20(_erc20);
    }, [account]);

    useEffect(() => {
    }, []);
    return ERC20;
}




