import React from "react";
import {PoolType} from "../pool/nft_pool_item";
import styled from "styled-components";

type PoolItemTitleProps = {
    type: PoolType
}
export const titleText = [
    "Token",
    "NFT",
    "Trade"
]
const titleColor = [
    "#E0446A",
    "#4FCFA9",
    "#2081E2"
]

export const PoolItemTitle: React.FC<PoolItemTitleProps> = ({type}) => {
    return (
        <PoolItemStyle>
            <h4 style={{color: titleColor[type]}}>{titleText[type]} Pool</h4>
        </PoolItemStyle>
    )
}

const PoolItemStyle = styled.div`
  h4 {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: -0.14em;
  }
`
