import React, {useCallback, useMemo} from "react";
import styled from "styled-components";
import {Grid} from "@material-ui/core";
import {PoolType} from "../collection/pool/nft_pool_item";
import {PoolItemTitle} from "../collection/widget/pool_item_title";
import nftGrop from "../../assets/images/nft_grop.png";
import tokenGrop from "../../assets/images/token.png";
import curvedArrow from "../../assets/images/curved_arrow.png"
import {useCreaterDataPool} from "../../provider/creater_lp_provider";

export const SelectType: React.FC = () => {


    return (
        <SelectTypeStyle>
            <Grid container spacing={4}>
                <Grid item xs={6} sm={6}  md={4} >
                    <ItemWidget type={PoolType.token}/>
                </Grid>
                <Grid item xs={6} sm={6}  md={4} >
                    <ItemWidget type={PoolType.nft}/>
                </Grid>
                <Grid item xs={6} sm={6}  md={4} >
                    <ItemWidget type={PoolType.trade}/>

                </Grid>
            </Grid>
        </SelectTypeStyle>
    );
}


const SelectTypeStyle = styled.div`

  padding: 20px;
  box-sizing: border-box;
`


const ItemWidget: React.FC<{ type: PoolType }> = ({type}) => {
    let img = curvedArrow;

    let text = useMemo(() => {
        let _text = 'You will deposit tokens and receive NFTs as people swap their NFTs for your deposited tokens.'
        switch (type) {
            case PoolType.nft:
                _text = 'You will deposit NFTs and receive tokens as people swap their tokens for your deposited NFTs.'
                break;
            case PoolType.trade:
                _text = 'You will deposit both NFTs and tokens and earn trading fees as people buy or sell NFTs using your pool.'
                break;
        }
        return _text;
    }, [type])

    const {setActiveStep, poolType} = useCreaterDataPool()
    let [_, setPoolType] = poolType
    const next = useCallback((type: PoolType) => {
        setPoolType(type)
        setActiveStep(1)
    }, [])

    return (
        <ItemStyle onClick={() => {
            next(type);
        }}>
            <PoolItemTitle type={type}/>
            <div className="img_grop">
                <div className={`top ${type === PoolType.token ? "token" : ""}`}>
                    <img className={'arrow'} src={img} alt=""/>
                </div>
                <img className={'group'} src={tokenGrop} alt=""/>
                <img className={'group'} src={nftGrop} alt=""/>
                {
                    type === PoolType.trade && <div className="bottom">
                        <img className={'arrow'} src={img} alt=""/>
                    </div>
                }
            </div>

            <div className="text">{text}</div>
        </ItemStyle>
    );
}

const ItemStyle = styled.div`
  padding: 20px;
  background: #2C2F36;
  border: 2px solid #52565F;
  border-radius: 20px;
  cursor: pointer;

  &:hover {
    background: #52565F;
    border: 2px solid #FFFFFF;
    border-radius: 20px;
  }


  .text {
    text-align: center;
    font-size: 18px;
    letter-spacing: -0.14em;
    margin-top: 30px;
    padding-bottom: 40px;
    color: #A7A7A7;
  }

  .img_grop {
    display: flex;
    padding: 40px 0;
    margin-top: 20px;
    justify-content: space-between;
    position: relative;

    .top {
      position: absolute;
      top: 0;
      width: 100%;

      &.token {
        transform: perspective(800px) translateZ(0) rotateY(180deg);
      }
    }


    .bottom {
      position: absolute;
      bottom: 0;
      transform: rotate(180deg);
      width: 100%;
    }

    .arrow {
      display: block;
      width: 100px;
      margin: 0 auto;
    }

    img {
      display: block;
    }

    .group {
      width: 130px;
    }
  }
`

