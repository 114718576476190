import React from "react";
import styled from "styled-components";
import {FormatAddr} from "../../../utils/format_addr";

import nftImg from "../../../assets/images/null_nft_icon.png"
import indexEth from "../../../assets/images/index_eth.png"
import {CollectionsDataModel} from "../../../model/collections_data";
import banner_bg from "../../../assets/images/banner_bg.png"
import {useCollection} from "../../../provider/collection_provicer";
import {getBalanceFormat} from "../../../utils/formatBalance";

export const HeaderWidget: React.FC<{
    collections: CollectionsDataModel,
    floorPrice: string, bestOffer: string, ethVolume: string
}> = ({floorPrice, bestOffer, ethVolume

      }) => {


    const {collections,} = useCollection();


    if (!collections) return <div/>
    let {
        name,
        address,
        images: {image_url, banner_image_url},
    } = collections
    return (
        <HeaderStyle bgImg={banner_image_url}>
            <img src={banner_bg} alt="" className="banner_bg"/>

            <div className="inner">
                <img src={image_url || nftImg} onError={() => nftImg} alt="" className="img"/>
                <div className="right">
                    <div className="top">
                        <p className={'name'}>{name}</p>
                        <p className={'address'}>{FormatAddr(address)}</p>
                    </div>
                    <div className="liquidity">
                        <div className="item">
                            <h3><img src={indexEth} alt=""/>{getBalanceFormat(ethVolume)}</h3>
                            <h4>Total Volume</h4>
                        </div>

                        <div className="item">
                            <h3><img src={indexEth} alt=""/>{
                                getBalanceFormat(floorPrice)
                            }</h3>
                            <h4>Floor Price</h4>
                        </div>

                        <div className="item">
                            <h3><img src={indexEth} alt=""/>{getBalanceFormat(bestOffer)}</h3>
                            <h4>Best Offer</h4>
                        </div>

                        {/*<div className="btn" onClick={()=>{*/}
                        {/*    onPresent(<ListEntireModel />);*/}
                        {/*}}>*/}
                        {/*    List Entire Collection*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>


        </HeaderStyle>
    );
}

const HeaderStyle = styled.div<{ bgImg: string }>`
  display: flex;
  align-items: center;
  color: white;
  padding: 30px 5%;
  box-sizing: border-box;

  background: url(${({bgImg}) => bgImg});
  background-size: 100% 100%;
  position: relative;

  .banner_bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    display: block;
    height: 100%;
  }

  .inner {
    display: flex;
    align-items: center;
    color: white;
    box-sizing: border-box;
    position: relative;
    z-index: 10;
  }

  .btn {
    background: #FFFFFF;
    border: 2px solid #000000;
    border-radius: 12px;
    width: 160px;
    padding: 5px 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    align-items: center;
    text-align: center;
    letter-spacing: -0.14em;
    cursor: pointer;

    color: #000000;

  }

  .right {
    .liquidity {
      display: flex;
      margin-top: 15px;
      align-items: center;

      .item {
        margin-right: 50px;
        text-align: center;

        h3 {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 16px;
          /* identical to box height */
          align-items: center;
          text-align: center;
          letter-spacing: -0.14em;
          justify-content: center;
          color: #FFFFFF;
          display: flex;

          img {
            display: block;
            width: 15px;
            margin-right: 5px;
          }
        }

        h4 {
          margin-top: 10px;
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 15px;
          /* identical to box height */

          align-items: center;
          text-align: center;
          letter-spacing: -0.14em;

          color: #8D909A;
        }
      }
    }

    .top {
      .name {
        font-weight: 400;
        font-size: 38px;
        font-family: 'ZX';
        letter-spacing: -0.05em;
        font-style: normal;
        line-height: 38px;
      }

      .address {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        margin-top: 5px;
        line-height: 18px;
        /* identical to box height */
        letter-spacing: -0.05em;
        text-decoration-line: underline;
        color: #D9D9D9;

      }
    }
  }

  .img {
    border: 4px solid #101010;
    display: block;
    width: 108px;
    margin-right: 20px;
  }

`
