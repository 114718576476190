export enum CURVEEnum {
    LINEAR,
    EXPONENTIAL,
}


export type NftHeaderMsg = {
    name: string,
    symbol: string,
    token_address: string,
}

export enum StepEnum {
    SelectType,
    SelectToken,
    SelectNft,
    CreaterPool
}
