import {useCallback, useEffect, useState} from "react";
import {indexBy, mapObjIndexed, path, prop} from "ramda";

import {NftMateMap, PairsMap} from "../view/collection/type";
import {HttpServer} from "../net/http_server";
import {NftMetadata} from "../model/nft_metadata";
import PairABI from "../web3/abi/pair_eth";
import {pairsProps} from "../view/collection/hook/usePairs";
import {CollectionInterface} from "../provider/collections_provicer";
import {getCollectionById} from "../view/my_pool/hook/useCollections";
import {useStore} from "effector-react";
import {$getCollections} from "../view/main/store";


type mapCollectionInterface = { [key: string]: CollectionInterface }
let _collections: any = {}
export const useAllPairs = ({account, web3}: pairsProps) => {
    const [collections, setCollections] = useState<mapCollectionInterface>({});
    const {collections: collectionsData} = useStore($getCollections)

    const handeGetMateData = useCallback(async (pools: PairsMap, nftMeta: NftMateMap, id) => {
        mapObjIndexed(async (value, key) => {
            if (value.type === '0') return;
            if (!value.nftIds.length) return;
            let _meta: any = await HttpServer.alchemyapiGetNft(id, value.id)

            let listMate: NftMetadata[] = path(['ownedNfts'], _meta) || []
            const pairContract = new web3.eth.Contract(PairABI as any, value.id)
            let ruselt = await pairContract.methods.getBuyNFTQuote(1).call()

            pools[key].spotPrice = ruselt.newSpotPrice
            pools[key].averagePrice = ruselt.inputAmount

            let averagePrice = ruselt.inputAmount
            let ids = listMate.map((ev) => {
                let tokenId = parseInt(ev.id.tokenId);
                let uqid = `${key}_${tokenId}`
                nftMeta[uqid] = {
                    ...ev,
                    uqid,
                    ownedId: value.id,
                    averagePrice: averagePrice,
                };
                return tokenId
            })
            _collections = {
                ..._collections,
                [id]: {
                    sudo: pools,
                    nftMeta: nftMeta,
                    collections: getCollectionById(id, collectionsData)
                }
            }
            setCollections(_collections)
        }, pools)

    }, [_collections, web3])

    const handleGetPairs = useCallback(async (id) => {

        let result: any;
        result = await HttpServer.thegraphGetNft(id);
        if (result.errors) return;

        if (!result.data.collection) return;

        let list: any = path(['data', 'collection', 'pairs'], result);

        if (!list) {
            return
        }
        if (_collections[id]) {
            return;
        }
        let permissionMap = indexBy<any>(prop("id"), list);
        // _collections = {
        //     ..._collections, [id]: {
        //         sudo: permissionMap,
        //         nftMeta: {},
        //         collections: getCollectionById(id)
        //     },
        // }
        // setCollections(_collections)
        handeGetMateData(permissionMap, {}, id);
    }, [web3])

    useEffect(() => {
        const ids = collectionsData.map((ev: any) => ev.address)
        if (web3 && account) {
            ids.forEach((id: string) => {
                handleGetPairs(id)
            })
        }
    }, [collectionsData, web3, account])


    return {handleGetPairs, collections}

}
