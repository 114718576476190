import React, {useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import Roy from "../../../assets/images/question-mark.svg"
import Deleteimg from "../../../assets/images/delete_nft.png"
import {getBalanceFormat} from "../../../utils/formatBalance";
import indexEth from "../../../assets/images/index_eth.png";


type NftBuyItemProps = {
    data: {
        id: string,
        nftIds: any[],
        nftId?: string,
        spotPrice?: string,
        imgUrl?: string,
        symbol?: string,
        isSelect?: boolean,
        hidePrice?: boolean,
        isSell?: boolean
    },
    onCb: Function,
}
export const NftCardItem: React.FC<NftBuyItemProps> = ({data, onCb}) => {
    const [select, setSelect] = useState(data.isSelect)

    useEffect(()=>{
        setSelect(data.isSelect)
    },[data.isSelect])

    return (
        <NftBuyItemStyle style={{borderColor: select ? "#fff" : "#404040"}} onClick={() => {
            onCb(!select)
            setSelect(!select)
        }}>
            <div className="top">
                <div className="nft_img" style={{backgroundImage: `url(${data.imgUrl || Roy})`}}/>
            </div>
            <div className="bottom">

                <>
                    <p className={'nftId'}>{data.symbol}</p>
                    <p className={'sud_nftId'}>#{data.nftId}</p>
                </>

                {!data.hidePrice && <div className="bar">
                    No offer
                </div>}
                {data.hidePrice && data.spotPrice && <div className="bar sellPrice">
                   <span></span><div>{getBalanceFormat(data.spotPrice)} <img src={indexEth} alt=""/></div>
                </div>}
            </div>

            {
                data.isSell && <div className="top_bg">
                    {
                        select && <img onClick={() => {
                        }} src={Deleteimg} alt=""/>
                    }

                </div>
            }


        </NftBuyItemStyle>
    );
}

export const NftSellCardItem: React.FC<NftBuyItemProps> = ({data, onCb}) => {

    const [select, setSelect] = useState(data.isSelect)

    useEffect(()=>{
        setSelect(data.isSelect)
    },[data.isSelect])

    return (
        <NftBuyItemStyle style={{borderColor: select ? "#fff" : "#404040"}} onClick={() => {
            onCb(!select)
            setSelect(!select)
        }}>
            <div className="top">
                <div className="nft_img" style={{backgroundImage: `url(${data.imgUrl || Roy})`}}/>
            </div>
            <div className="bottom">

                <>
                    <p className={'nftId'}>{data.symbol}</p>
                    <p className={'sud_nftId'}>#{data.nftId}</p>
                </>
            </div>

            {
                data.isSell && <div className="top_bg">
                    {
                        select && <img onClick={() => {
                        }} src={Deleteimg} alt=""/>
                    }

                </div>
            }


        </NftBuyItemStyle>
    );
}

const NftBuyItemStyle = styled.div`
  border-radius: 15px;
  overflow: hidden;
  cursor: pointer;
  padding: 10px;
  width: 140px;

  box-sizing: border-box;
  border: 1px solid;
  position: relative;

  .top_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      display: block;
      width: 15px;
      height: 15px;
      position: absolute;
      right: 8px;
      top: 8px;
    }
  }


  .bottom {
    box-sizing: border-box;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }

    .nftId {
      font-style: normal;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: -0.1em;
      margin: 4px 0;
      font-weight: bold;
      text-transform: Capitalize;
      color: #FFFFFF;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

    }

    .sud_nftId {
      font-style: normal;
      font-weight: 400;
      font-size: 8px;
      align-items: center;
      letter-spacing: -0.1em;
      color: #FFFFFF;
    }

    .bar {
      margin-top: 5px;
      padding: 5px 0;
      background-color: #404040;
      border-radius: 100px;
      text-align: center;
      color: white;

    }
    .sellPrice{
      display: flex;
      justify-content: space-between;
      
      div{
        display: flex;
      }
      img{
        margin: 0 5px;
        display: block;
        width: 15px;
        height: 15px;
      }
    }
  }

  .top {
    border-radius: 6px;
    overflow: hidden;

    .nft_img {
      display: block;
      width: 120px;
      height: 120px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

`
