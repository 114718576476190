import { Effect } from 'effector'
import { useStore } from 'effector-react'
import { useEffect } from 'react'

export default function useRequestEffect<P, D, F>(
    effect: Effect<P, D, F>,
    params?: P
) {
    const loading = useStore(effect.pending)

    useEffect(() => {
        effect(params!)
    }, [effect, params])

    return { loading }
}
