import {FlexStyle} from "../../creater_lp/widget/label_input";
import {TextField} from "@material-ui/core";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import DeleteImg from "../../../assets/images/search_delete.png"
import {useStore} from "effector-react";
import {$getCollections} from "../store";
import {CellCollectionsData, createData} from "../type";
import {anyPass, filter, path, propSatisfies} from "ramda";
import {useHistory} from "react-router-dom";

export const SearchBarWidget: React.FC = () => {
    const history = useHistory();

    const [searchText, setSearch] = useState("");
    const [collections, setCollections] = useState<CellCollectionsData[]>([])
    const data = useStore($getCollections)
    const rows: CellCollectionsData[] = useMemo(() => {
        if (!data || !data.collections) return [];
        return data.collections.filter((ev: any) => ev.name).map((ev: any) => {
            let {name, images, sell_quote, pool_count, item_count, analytics, address, ethOfferTVL, ethVolume} = ev;
            return createData(name, images.image_url, sell_quote, pool_count, analytics.volume_24_hour, analytics.volume_all_time, item_count, address, ethOfferTVL, ethVolume, ev);
        })
    }, [data])

    const handelSetColl = useCallback(() => {
        if (!searchText) {
            setCollections([]);
            return
        }
        let list: CellCollectionsData[] = filter(
            anyPass([
                propSatisfies(
                    (name: string) => {
                        return name.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
                    },
                    'name'
                ),
                propSatisfies(
                    (address: string) => {
                        return address.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
                    },
                    'address'
                ),
            ]),
            rows || []
        );
        setCollections(list);
    }, [searchText, rows])

    useEffect(() => {
        let itemOt = setTimeout(handelSetColl, 300);
        return () => clearTimeout(itemOt)
    }, [searchText])

    return (
        <SearchBarStyle>
            <div className="search">
                <FlexStyle>
                    <InputStyle>
                        <TextField fullWidth={true} value={searchText}
                                   onChange={(ev: any) => setSearch(ev.target.value)}
                                   placeholder={'Search collection'}/>
                        <img onClick={()=>{setSearch('')}} className={'DeleteImg'} src={DeleteImg} alt=""/>
                    </InputStyle>
                </FlexStyle>

                {
                    collections.length > 0 && <div className="search_list">
                        {
                            collections.map((ev) => {
                                return <SearchItemStyle  onClick={() => {
                                    history.push(`/collection/buy/${ev.address}`)
                                }}>
                                    <img className={'label'} src={ev?.img || ""} alt=""/>
                                    {ev.name}
                                </SearchItemStyle>
                            })
                        }
                    </div>
                }
            </div>

        </SearchBarStyle>
    )
}

const SearchItemStyle = styled.div`
  color: white;
  padding: 10px 0;
  font-size: 18px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #fff;
  cursor: pointer;

  .label {
    display: block;
    width: 40px;
    height: 40px;
    margin-right: 20px;
  }
`

export const InputStyle = styled.div`

  display: flex;
  flex: 1;
  height: 45px;
  align-items: center;
  border: 1px solid #323841;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 600;
  //clip-path: inset(0 round 4px);
  padding: 6px 20px;
  background: #080808;

  .MuiInputBase-input {
    font-family: 'ZX', sans-serif;

    &::-webkit-input-placeholder {
      text-align: start;
    }
  }


  .balance {
    color: #7B7A89;
    width: 200px;

    span {
      color: #7B7A89;

    }
  }

  .DeleteImg {
    display: block;
    width: 30px;
    height: 30px;
    cursor: pointer;
    transition: all .2s;
  }

  .DeleteImg:hover {
    transform: rotate(90deg);
  }


`

const SearchBarStyle = styled.div`



  .search {
    //padding: 20px 150px;
    margin: 20px auto 20px auto;
    box-sizing: border-box;
    position: relative;
    width: 800px;

  }

  .search_list {
    position: absolute;

    top: 80px;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    background: #080808;
    border: 1px solid #D9D9D9;
    border-radius: 10px;

    div:last-child {
      border-bottom: none;
    }
  }
`
