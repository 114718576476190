import BigNumber from "bignumber.js";
import {CURVE} from "../web3/token.config";



export function sequenceBuyFn(cardinality: BigNumber, delta: string, curveA: string, n: number) {
    let curve: BigNumber;
    if (curveA.toLowerCase() === CURVE.EXPONENTIAL_CURVE.toLowerCase()) {
        curve = new BigNumber(delta).div(1e20)
    } else {
        curve = new BigNumber(delta)
    }

    function i() {
        if (n === 0) {
            return
        }
        if (curveA.toLowerCase() === CURVE.EXPONENTIAL_CURVE.toLowerCase()) {
            cardinality = new BigNumber(cardinality).plus(cardinality.times(curve))
        } else {
            cardinality = new BigNumber(cardinality).plus(curve)
        }
        n -= 1
        i()
    }

    i()


    return cardinality
}



export function sequenceFn(cardinality: BigNumber, delta: string, curveA: string, n: number) {
    let curve: BigNumber;
    if (curveA.toLowerCase() === CURVE.EXPONENTIAL_CURVE.toLowerCase()) {
        curve = new BigNumber(delta).div(1e20)
    } else {
        curve = new BigNumber(delta)
    }

    function i() {
        if (n === 0) {
            return
        }
        if (curveA.toLowerCase() === CURVE.EXPONENTIAL_CURVE.toLowerCase()) {
            cardinality = new BigNumber(cardinality).minus(cardinality.times(curve))
        } else {
            cardinality = new BigNumber(cardinality).minus(curve)
        }
        n -= 1
        i()
    }

    i()


    return cardinality
}
