import React, {createContext, useContext} from "react";
import {CollectionsDataModel} from "../model/collections_data";
import {RootMyPoolData} from "../model/my_pool_data";
import {OwnedNft} from "../model/get_nft_model";


export const UpDataPoolContext = createContext<UpDataPoolInterface>({
    collections: undefined,
    sudo: undefined,
    nfts: []
})


interface UpDataPoolInterface {
    collections: CollectionsDataModel | undefined,
    sudo: RootMyPoolData | undefined,
    nfts: OwnedNft[]
}


export const useUpDataPool = (): UpDataPoolInterface => {
    const {sudo, collections, nfts} = useContext(UpDataPoolContext);
    return {
        sudo, collections, nfts
    }
}





