import React from "react";
import styled from "styled-components";
import {CircularProgress} from "@material-ui/core";
import {HttpStatus} from "../utils/wait_fn";

type LoadingBtnProps = {
    isLoading: HttpStatus,
    onClick: Function,
    className?: string,
    disabled?: boolean

}
export const LoadingBtn: React.FC<LoadingBtnProps> =
    ({isLoading, className, onClick, children, disabled}) => {
        return (
            <LoadingStyle className={`LoadingBtn ${className} ${disabled ? "disabled" : ""}`} onClick={() => {
                if (disabled) {
                    return;
                }
                onClick()
            }}>

                {isLoading === HttpStatus.wait ? <CircularProgress disableShrink/> : children}
            </LoadingStyle>
        );
    }


const LoadingStyle = styled.div`
  cursor: pointer;
  margin-top: 20px;
  width: 100%;
  height: 40px;
  //padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: #52565F;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
  
  &.disabled{
    cursor: no-drop;
    opacity: .8;
  }
`
