export default [
    {
        "inputs": [
            {
                "internalType": "enum CurveErrorCodes.Error",
                "name": "error",
                "type": "uint8"
            }
        ],
        "name": "BondingCurveError",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "Ownable_NewOwnerZeroAddress",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "Ownable_NotOwner",
        "type": "error"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "a",
                "type": "address"
            }
        ],
        "name": "AssetRecipientChange",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "uint128",
                "name": "newDelta",
                "type": "uint128"
            }
        ],
        "name": "DeltaUpdate",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "uint96",
                "name": "newFee",
                "type": "uint96"
            }
        ],
        "name": "FeeUpdate",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [],
        "name": "NFTWithdrawal",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "uint128",
                "name": "newSpotPrice",
                "type": "uint128"
            }
        ],
        "name": "SpotPriceUpdate",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [],
        "name": "SwapNFTInPair",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [],
        "name": "SwapNFTOutPair",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "TokenDeposit",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "TokenWithdrawal",
        "type": "event"
    },
    {
        "stateMutability": "payable",
        "type": "fallback"
    },
    {
        "inputs": [],
        "name": "assetRecipient",
        "outputs": [
            {
                "internalType": "address payable",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "bondingCurve",
        "outputs": [
            {
                "internalType": "contract ICurve",
                "name": "_bondingCurve",
                "type": "address"
            }
        ],
        "stateMutability": "pure",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address payable",
                "name": "target",
                "type": "address"
            },
            {
                "internalType": "bytes",
                "name": "data",
                "type": "bytes"
            }
        ],
        "name": "call",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address payable",
                "name": "newRecipient",
                "type": "address"
            }
        ],
        "name": "changeAssetRecipient",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint128",
                "name": "newDelta",
                "type": "uint128"
            }
        ],
        "name": "changeDelta",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint96",
                "name": "newFee",
                "type": "uint96"
            }
        ],
        "name": "changeFee",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint128",
                "name": "newSpotPrice",
                "type": "uint128"
            }
        ],
        "name": "changeSpotPrice",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "delta",
        "outputs": [
            {
                "internalType": "uint128",
                "name": "",
                "type": "uint128"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "factory",
        "outputs": [
            {
                "internalType": "contract ILSSVMPairFactoryLike",
                "name": "_factory",
                "type": "address"
            }
        ],
        "stateMutability": "pure",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "fee",
        "outputs": [
            {
                "internalType": "uint96",
                "name": "",
                "type": "uint96"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getAllHeldIds",
        "outputs": [
            {
                "internalType": "uint256[]",
                "name": "",
                "type": "uint256[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getAssetRecipient",
        "outputs": [
            {
                "internalType": "address payable",
                "name": "_assetRecipient",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "numNFTs",
                "type": "uint256"
            }
        ],
        "name": "getBuyNFTQuote",
        "outputs": [
            {
                "internalType": "enum CurveErrorCodes.Error",
                "name": "error",
                "type": "uint8"
            },
            {
                "internalType": "uint256",
                "name": "newSpotPrice",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "newDelta",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "inputAmount",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "protocolFee",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "numNFTs",
                "type": "uint256"
            }
        ],
        "name": "getSellNFTQuote",
        "outputs": [
            {
                "internalType": "enum CurveErrorCodes.Error",
                "name": "error",
                "type": "uint8"
            },
            {
                "internalType": "uint256",
                "name": "newSpotPrice",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "newDelta",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "outputAmount",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "protocolFee",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_owner",
                "type": "address"
            },
            {
                "internalType": "address payable",
                "name": "_assetRecipient",
                "type": "address"
            },
            {
                "internalType": "uint128",
                "name": "_delta",
                "type": "uint128"
            },
            {
                "internalType": "uint96",
                "name": "_fee",
                "type": "uint96"
            },
            {
                "internalType": "uint128",
                "name": "_spotPrice",
                "type": "uint128"
            }
        ],
        "name": "initialize",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes[]",
                "name": "calls",
                "type": "bytes[]"
            },
            {
                "internalType": "bool",
                "name": "revertOnFail",
                "type": "bool"
            }
        ],
        "name": "multicall",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "nft",
        "outputs": [
            {
                "internalType": "contract IERC721",
                "name": "_nft",
                "type": "address"
            }
        ],
        "stateMutability": "pure",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            },
            {
                "internalType": "uint256[]",
                "name": "",
                "type": "uint256[]"
            },
            {
                "internalType": "uint256[]",
                "name": "",
                "type": "uint256[]"
            },
            {
                "internalType": "bytes",
                "name": "",
                "type": "bytes"
            }
        ],
        "name": "onERC1155BatchReceived",
        "outputs": [
            {
                "internalType": "bytes4",
                "name": "",
                "type": "bytes4"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            },
            {
                "internalType": "bytes",
                "name": "",
                "type": "bytes"
            }
        ],
        "name": "onERC1155Received",
        "outputs": [
            {
                "internalType": "bytes4",
                "name": "",
                "type": "bytes4"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "pairVariant",
        "outputs": [
            {
                "internalType": "enum ILSSVMPairFactoryLike.PairVariant",
                "name": "",
                "type": "uint8"
            }
        ],
        "stateMutability": "pure",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "poolType",
        "outputs": [
            {
                "internalType": "enum LSSVMPair.PoolType",
                "name": "_poolType",
                "type": "uint8"
            }
        ],
        "stateMutability": "pure",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "spotPrice",
        "outputs": [
            {
                "internalType": "uint128",
                "name": "",
                "type": "uint128"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes4",
                "name": "interfaceId",
                "type": "bytes4"
            }
        ],
        "name": "supportsInterface",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256[]",
                "name": "nftIds",
                "type": "uint256[]"
            },
            {
                "internalType": "uint256",
                "name": "minExpectedTokenOutput",
                "type": "uint256"
            },
            {
                "internalType": "address payable",
                "name": "tokenRecipient",
                "type": "address"
            },
            {
                "internalType": "bool",
                "name": "isRouter",
                "type": "bool"
            },
            {
                "internalType": "address",
                "name": "routerCaller",
                "type": "address"
            }
        ],
        "name": "swapNFTsForToken",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "outputAmount",
                "type": "uint256"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "numNFTs",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "maxExpectedTokenInput",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "nftRecipient",
                "type": "address"
            },
            {
                "internalType": "bool",
                "name": "isRouter",
                "type": "bool"
            },
            {
                "internalType": "address",
                "name": "routerCaller",
                "type": "address"
            }
        ],
        "name": "swapTokenForAnyNFTs",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "inputAmount",
                "type": "uint256"
            }
        ],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256[]",
                "name": "nftIds",
                "type": "uint256[]"
            },
            {
                "internalType": "uint256",
                "name": "maxExpectedTokenInput",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "nftRecipient",
                "type": "address"
            },
            {
                "internalType": "bool",
                "name": "isRouter",
                "type": "bool"
            },
            {
                "internalType": "address",
                "name": "routerCaller",
                "type": "address"
            }
        ],
        "name": "swapTokenForSpecificNFTs",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "inputAmount",
                "type": "uint256"
            }
        ],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "withdrawAllETH",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "contract IERC1155",
                "name": "a",
                "type": "address"
            },
            {
                "internalType": "uint256[]",
                "name": "ids",
                "type": "uint256[]"
            },
            {
                "internalType": "uint256[]",
                "name": "amounts",
                "type": "uint256[]"
            }
        ],
        "name": "withdrawERC1155",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "contract ERC20",
                "name": "a",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "withdrawERC20",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "contract IERC721",
                "name": "a",
                "type": "address"
            },
            {
                "internalType": "uint256[]",
                "name": "nftIds",
                "type": "uint256[]"
            }
        ],
        "name": "withdrawERC721",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "withdrawETH",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "stateMutability": "payable",
        "type": "receive"
    }
]
