import React, {useEffect, useMemo, useState} from "react";
import {Grid} from "@material-ui/core";
import styled from "styled-components";

import {CellCollectionsWidget} from "./cell_collections";
import {CryptoWidget} from "./crypto_simeji";
import {HttpStatus, useWait} from "../../../utils/wait_fn";
import {HttpServer} from "../../../net/http_server";
import {LoadingWidget} from "../../../widget/base/loading_widget";
import {CellCollectionsData, createData} from "../type";
import {slice} from "ramda";
import {useStore} from "effector-react";
import {$getCollections} from "../store";
import useRequestEffect from "../../../hook/useRequestEffect";
import {getCollections} from "../store/effects";
import {useCollections} from "../../../provider/collections_provicer";


export const RightWidget: React.FC = () => {

    const data = useStore($getCollections)
    const rows: CellCollectionsData[] = useMemo(() => {
        if (!data || !data.collections) return [];
        return data.collections.filter((ev: any) => ev.name).map((ev: any) => {
            let {name, images, sell_quote, pool_count, item_count, analytics, address, ethOfferTVL, ethVolume} = ev;
            return createData(name, images.image_url, sell_quote, pool_count, analytics.volume_24_hour, analytics.volume_all_time, item_count, address, ethOfferTVL, ethVolume, ev);
        })
    }, [data])


    const [cryptoSimeji, setCryptoSimeji] = useState<CellCollectionsData | undefined>();
    const [acIndex, setAcIndex] = useState(0);

    const {collections} = useCollections()
    return (
        <RightStyle>
            <Grid
                container
                justify={"space-between"}
                spacing={4}
            >
                <Grid className={'cell_wap'} item>
                    <div>
                        {
                            rows.slice(0, 4).map(
                                (
                                    (ev, index) => {
                                        return (<div key={`${ev.address}_${index}`} onClick={() => {
                                            setCryptoSimeji(ev)
                                            setAcIndex(index + 1)
                                        }}>
                                            <CellCollectionsWidget
                                                collection={collections[ev.address]}
                                                {...ev}
                                                isAc={acIndex === index + 1}
                                                index={index + 1}
                                            />
                                        </div>);
                                    }
                                )
                            )
                        }
                    </div>

                </Grid>

                <Grid item className={'cell_wap'}>
                    <div>
                        {
                            rows.slice(4, 9).map(((ev, index) => {
                                return <div onClick={() => {
                                    setCryptoSimeji(ev)
                                    setAcIndex(index + 6)
                                }}>
                                    <CellCollectionsWidget
                                        collection={collections[ev.address]}
                                        {...ev}
                                        isAc={acIndex === index + 6}
                                        index={index + 6}/>
                                </div>;
                            }))
                        }
                    </div>

                </Grid>

                <Grid item className={'cell_wap'}>
                    <CryptoWidget collection={collections[(cryptoSimeji || rows[0]).address]}
                                  data={cryptoSimeji || rows[0]}
                    />
                </Grid>
            </Grid>

        </RightStyle>
    );
}


const RightStyle = styled.div`

  max-width: 1100px;
  margin: 0 auto;

  .wap {
    background-size: 100% 100%;
  }

  .cell_wap {
    width: 32%;
  }

  .inner {
    padding: 24px;
    box-sizing: border-box;
  }


  .input {
    margin-top: 20px;
    box-sizing: border-box;


  }

  .balance {
    color: #7B7A89;
    width: 200px;

    span {
      color: #7B7A89;
    }
  }

  @media (max-width: ${({theme}) => theme.sm}) {
    .input {
      margin-top: 20px;
    }


    .charged {
      margin-bottom: 20px;
    }

    .balance {
      width: 220px;
    }
  }
`
