import env from "@beam-australia/react-env";

console.log(env("REACT_APP_CHAIN_Id"))
export const AppConfig: any = {
    chainId: parseInt(env("CHAIN_Id")),
    urlPath: 'https://admin.nsure.network/public/avatars/',
    voteURL: env("VOTE_URL"),
    rpcUrl: {
        1: 'http://106.8.149.90:8545',
        42: 'https://kovan.infura.io/v3/a17d484065334e38bd8b6475ca266c88',
    },
    etherscan: {
        1: 'https://etherscan.io/',
        5: 'https://goerli.etherscan.io/',
        42: 'https://kovan.etherscan.io/'
    }
}


