import styled from "styled-components";
import React from "react";
import nftImg from "../assets/images/null_nft_icon.png";

type CollectionImgProps = {
    imgUrl: string,
}
export const CollectionImgWidget: React.FC<CollectionImgProps> = ({imgUrl}) => {
    return (
        <CollectionImgStyle className={'collection_img'}>
            <img src={imgUrl || nftImg} onError={() => nftImg} alt=""/>
        </CollectionImgStyle>
    );
}


const CollectionImgStyle = styled.div`
  img {
    display: block;
    width: 100%;
    border-radius: 4px;
    border: 2px solid #101010;
  }
`
