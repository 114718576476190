import styled from "styled-components";
import React, {useEffect, useState} from "react";
import LoadingImg from "../../assets/images/loading_img.png"

export const LoadingWidget: React.FC = () => {
    return (
        <LoadingStyle>
            Loading...
        </LoadingStyle>
    );
}

const LoadingStyle = styled.div`
  color: white;
  text-align: center;
  margin-top: 40px;
`

let a = 0;
export const LoadingImgWidget: React.FC = () => {

    const [rotate, setRotate] = useState(0)
    useEffect(() => {
        let interval = setInterval(() => {
            a++;
            let _rotate = 45 * a;
            setRotate(_rotate)
            if (a === 8) {
                a = 0;
            }
        }, 100)
        return () => clearInterval(interval)
    }, [])
    return (
        <LoadingImgStyle>
            <img style={{transform: `rotate(${rotate}deg)`}} src={LoadingImg} alt=""/>
        </LoadingImgStyle>
    );
}

const LoadingImgStyle = styled.div`
  color: white;
  text-align: center;

  img {
    display: block;
    width: 100px;
    margin: 0 auto;
    //transition-duration: 0s;
    //animation: spin 1s linear infinite;
  }

  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`
