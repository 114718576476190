import React, {useEffect} from "react";
import {Grid} from "@material-ui/core";
import styled from "styled-components";
import {NavLink, useHistory} from "react-router-dom";
import {useWeb3} from "../provider/web3_provider";
import {useShoppingCart} from "../provider/shopping_cart_provider";
import {useWallet} from "use-wallet";
import {useENSName} from "use-ens-name";
import {formatAddress} from "../utils/formatBalance";
import cart from "../assets/images/shop_car.png"

export const HeaderWidget: React.FC = () => {
    const {} = useWeb3();
    useEffect(() => {
    }, [])
    return (
        <HeaderStyle>
            <Grid container alignItems={"center"} justify={"space-between"}>
                <Grid item md={2} xs={12}>
                    <LogoWidget/>
                </Grid>
                <Grid item md={6} xs={12}>
                    <NavWidget/>
                </Grid>
            </Grid>
        </HeaderStyle>

    );
}

const LogoWidget: React.FC = () => {
    const {push} = useHistory()
    return (
        <LogoStyle onClick={() => push('/')}>
            ROOTSWAP
        </LogoStyle>
    );
}


const NavWidget: React.FC = () => {
    const {show, nftMateMap} = useShoppingCart()
    const {account} = useWallet()

    const name = useENSName(account)

    return (
        <NavStyle>
            <StyledLink to="/my_pool">Your Pools</StyledLink>
            <StyledLink to="/inventory">Your NFTs</StyledLink>
            <ConnectWalletStyle>{name || formatAddress(account || '', 4)}</ConnectWalletStyle>
            <div style={{width: "20px"}}/>
            <ShoppingCart onClick={() => show(true)}>
                <img src={cart} alt=""/>
                <span>{Object.keys(nftMateMap).length}</span>
            </ShoppingCart>

        </NavStyle>
    );
}
const ShoppingCart = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.15em;
  cursor: pointer;
  position: relative;
  img{
    display: block;
    width: 25px;
  }
  span{
    font-family: 'ZX';
    padding: 0 3px;
    position: absolute;
    border-radius: 100px;

    background: #242424;
    top: -5px;
    right: -10px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
  }
`
const ConnectWalletStyle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.15em;
  background-color: #fff;
  padding: 10px 20px;
  color: #080808;
  border-radius: 12px;
  cursor: pointer;
`
const HeaderStyle = styled.div`
  padding: 10px 2%;
  box-sizing: border-box;
  width: 100%;
`


const NavStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`

const StyledLink = styled(NavLink)`
  font-size: 16px;
  margin-right: 20px;
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  padding: 10px 20px;
  box-sizing: border-box;

  border: 1px solid #D9D9D9;
  border-radius: 12px;

  &.active {
    color: #FFFFFF;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 0;

    &.active {
    }
  }
`;
const SomiStyle = styled.a`
  font-size: 16px;
  color: #6F6F7D;
  text-decoration: none;
  font-weight: 400;
  padding: 3px 6px;
  box-sizing: border-box;
  position: relative;

  &.active {
    color: #2A1D69;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 0;
    &.active {
    }
  }
`;


const LogoStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  background-color: #FFFFFF;
  cursor: pointer;

  width: 160px;
  height: 40px;

  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  color: #080808;

`
