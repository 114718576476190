import React, {createContext, Dispatch, SetStateAction, useContext, useState} from "react";
import {PoolType} from "../view/collection/pool/nft_pool_item";
import {MoralisData} from "../model/moralis_data";
import {CURVEEnum, NftHeaderMsg} from "../view/creater_lp/type";


export const CreaterPoolProvider: React.FC<{ setActiveStep: Function }> = ({
                                                                               children,
                                                                               setActiveStep
                                                                           }) => {
    const poolType = useState<PoolType>(PoolType.nft);
    const curve = useState<CURVEEnum>(CURVEEnum.LINEAR);
    const nftidsStateAction = useState<string[]>([]);
    const moralisData = useState<NftHeaderMsg>({} as any);
    return <CreaterPoolContext.Provider value={{
        poolType,
        setActiveStep,
        moralisData,
        curve,
        nftidsStateAction
    }}>
        {children}
    </CreaterPoolContext.Provider>
}

export const CreaterPoolContext = createContext<UpDataPoolInterface>({
    poolType: [PoolType.nft, () => {
    }],
    // @ts-ignore
    moralisData: [{}, () => {
    }],
    setActiveStep: Function,
    curve: [CURVEEnum.LINEAR, () => {
    }],
    nftidsStateAction: [[], () => {
    }]

})

interface UpDataPoolInterface {
    poolType: [PoolType, Dispatch<SetStateAction<PoolType>>],
    moralisData: [NftHeaderMsg, Dispatch<SetStateAction<NftHeaderMsg>>],
    curve: [CURVEEnum, Dispatch<SetStateAction<CURVEEnum>>],
    nftidsStateAction: [string[], Dispatch<SetStateAction<string[]>>],
    setActiveStep: Function,
}

export const useCreaterDataPool = () => {
    const {poolType, setActiveStep, moralisData, curve, nftidsStateAction} = useContext(CreaterPoolContext);
    return {
        poolType, setActiveStep, moralisData, curve, nftidsStateAction
    }
}





