
export interface CellCollectionsData {
    name: string;
    img: string;
    floorPrice: number;
    bestOffer: number;
    d: number;
    volD: number;
    listing: number;
    ref?: any,
    address:string,
    ethOfferTVL: string,
    ethVolume: string,
}


export function createData(
    name: string,
    img: string,
    floorPrice: number,
    bestOffer: number,
    d: number,
    volD: number,
    listing: number,
    address: string,
    ethOfferTVL:string, ethVolume: string,
    ref?: any,

): CellCollectionsData {
    return {name, img, floorPrice, bestOffer, d, volD, listing, ref, address, ethOfferTVL, ethVolume};
}
