import {useCallback, useState} from "react";
import {indexBy, mapObjIndexed, path, prop} from "ramda";
import {NftMateMap, PairsMap} from "../type";
import {HttpServer} from "../../../net/http_server";

import {NftMetadata} from "../../../model/nft_metadata";
import PairABI from "../../../web3/abi/pair_eth";
import Web3 from "web3";
import {PoolType} from "../pool/nft_pool_item";


export type pairsProps = {
    account: string | null,
    web3: Web3,
    // id:string
}
export const usePairs = ({account, web3}: pairsProps) => {
    const [nftMeta, setNftMeta] = useState<NftMateMap>({});
    const [pairsItems, setPairsItems] = useState<PairsMap>({})
    const handeGetMateData = useCallback(async (pools: PairsMap, nftMeta: NftMateMap, id) => {


        if (account) {
            let _meta: any = await HttpServer.alchemyapiGetNft(id, account)
            let listMate: NftMetadata[] = path(['ownedNfts'], _meta) || []
            listMate.map((ev) => {
                let tokenId = parseInt(ev.id.tokenId);
                let uqid = `${account}_${tokenId}`
                nftMeta[uqid] = {
                    ...ev,
                    uqid,
                    ownedId: account,
                    averagePrice: ''
                };
            })
        }

        mapObjIndexed(async (value, key) => {

            let _type: PoolType = parseInt(value.type)
            // if (!value.nftIds.length) return;
            if (_type === PoolType.nft && !value.nftIds.length) {
                return;
            }
            if (_type === PoolType.token && value.ethBalance === '0') {
                return
            }
            let _meta: any = await HttpServer.alchemyapiGetNft(id, value.id)
            let listMate: NftMetadata[] = path(['ownedNfts'], _meta) || []

            const pairContract = new web3.eth.Contract(PairABI as any, value.id)
            let averagePrice = '0'
            let sellPrice = '0'

            // let fee = await pairContract.methods.fee().call()
            // console.log(fee)
            // if(fee){
            //
            // }
            if (_type === PoolType.token || _type === PoolType.trade) {
                let ruselt = await pairContract.methods.getSellNFTQuote(1).call()
                sellPrice = ruselt.outputAmount
                pools[key].spotPrice = ruselt.newSpotPrice

                // pools[key].sellPrice = sellPrice
            }
            if (_type === PoolType.nft || _type === PoolType.trade) {
                let ruselt = await pairContract.methods.getBuyNFTQuote(1).call()
                pools[key].spotPrice = ruselt.newSpotPrice
            }

            let ids = listMate.map((ev) => {
                let tokenId = parseInt(ev.id.tokenId);
                let uqid = `${key}_${tokenId}`
                nftMeta[uqid] = {
                    ...ev,
                    uqid,
                    ownedId: value.id,
                    averagePrice: averagePrice,
                    sellPrice
                };
                return tokenId
            })
            setPairsItems({...pools})
            setNftMeta(nftMeta)
        }, pools)

    }, [pairsItems])

    const handleGetPairs = useCallback(async (id) => {
        let result: any;
        result = await HttpServer.thegraphGetNft(id);
        if (result.errors) return;

        if (!result.data.collection) return;

        let list = path(['data', 'collection', 'pairs'], result);

        // @ts-ignore
        let permissionMap = indexBy<any>(prop("id"), list);
        // setPairsItems(permissionMap)
        handeGetMateData(permissionMap, nftMeta, id);
    }, [])


    return {pairsItems, nftMeta, handleGetPairs}

}
