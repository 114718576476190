import {useWeb3} from "../provider/web3_provider";
import {useSendFn} from "../utils/web3_send";
import {useCallback} from "react";
import BigNumber from "bignumber.js";
import PairABI from "../web3/abi/pair_eth"
import {getBalanceNumber, numberToUint256} from "../utils/formatBalance";

// withdrawETH
export const useWithdrawETH = (pairAddress: string) => {
    const {contrcts, web3} = useWeb3();
    const pairContract = new web3.eth.Contract(PairABI, pairAddress)

    const handleSendFn = useSendFn(pairAddress);
    const handleWithdrawETH = useCallback(async (amount: string): Promise<any> => {

        let method = pairContract.methods.withdrawETH(
            new BigNumber(amount).times(1e18).toFixed(0),
        )
        return await handleSendFn(method);
    }, [contrcts, web3]);
    return handleWithdrawETH;
}

// withdrawNft
export const useWithdrawERC721 = (pairAddress: string) => {
    const {contrcts, web3} = useWeb3();


    const pairContract = new web3.eth.Contract(PairABI, pairAddress)
    const handleSendFn = useSendFn(pairAddress);
    const handleWithdrawETH = useCallback(async ({
                                                     ids,
                                                     nftAddress
                                                 }: { ids: string[], nftAddress: string }): Promise<any> => {
        let method = pairContract.methods.withdrawERC721(
            nftAddress,
            ids
        )
        return await handleSendFn(method);
    }, [contrcts, web3]);
    return handleWithdrawETH;
}

// withdrawETH
export const useMulticall = (pairAddress: string) => {
    const {contrcts, web3} = useWeb3();
    const pairContract = new web3.eth.Contract(PairABI, pairAddress)

    const handleSendFn = useSendFn(pairAddress);
    const handleWithdrawETH = useCallback(async ({delta, price}: { delta: string, price: string }): Promise<any> => {

        const changeDelta = pairContract.methods.changeDelta(numberToUint256(delta)).encodeABI()
        const changeFee = pairContract.methods.changeSpotPrice(numberToUint256(price)).encodeABI()
        let method = pairContract.methods.multicall(
            [changeFee, changeDelta], true
        )
        return await handleSendFn(method);
    }, [contrcts, web3]);
    return handleWithdrawETH;
}


