import axios, {AxiosInstance} from 'axios';

export class NetAxios {
    private axios: AxiosInstance;
    private baseURL: string;


    constructor(baseURL: string) {
        this.baseURL = baseURL;
        this.axios = axios.create({
            timeout: 30000,
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
            },
            transformRequest: [
                (data) => {
                    return JSON.stringify(data);
                }
            ],
            transformResponse: [
                (data) => {
                   try{
                       return JSON.parse(data);
                   }catch (e) {
                       return {};

                   }
                }
            ]
        });
        this.interceptors();
    }

    /**
     * 默认拦截器
     */
    interceptors() {
        // 请求拦截器
        this.axios.interceptors.request.use(
            (config) => {
                // if (localStorage.getItem('token')) {
                //     config.headers.common['token'] = localStorage.getItem('token');
                // }
                return Promise.resolve(config);
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        // 响应拦截器
        this.axios.interceptors.response.use(
            (response) => {

                const {status, data} = response;
                // const {code, result} = data;
                //
                //
                if (status === 200) {
                    return Promise.resolve(data);

                }

                return Promise.reject(data);
            },
            (error) => {

                return Promise.reject(error);
            }
        );
    }

    get(url: string, params: any, baseUrl = true, headers: any = {}) {

        return this.axios.get(`${baseUrl ? this.baseURL : ''}${url}`, {
            params: {...params}, headers
        })
    }

    post(url: string, data: any, headers: any = {}) {
        return this.axios.post(`${this.baseURL}${url}`, data, {
            headers
        })
    }

    put(url: string, data: any) {
        return this.axios.put(url, data)
    }

    patch(url: string, data: any) {
        return this.axios.patch(url, data)
    }

    delete(url: string) {
        return this.axios.delete(url)
    }
}


const apis = new NetAxios('https://api.coingecko.com/api');

export const EthToUsd = async () => {

    let coingeckoIDs = ["ethereum", "sunder-goverance-token", "compound-governance-token",];



    let data: any = await apis.get(`/v3/simple/price?ids=${coingeckoIDs.toString()}&vs_currencies=usd`, []);
    return data;
}
