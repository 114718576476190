import styled from "styled-components";
import React, {useMemo, useState} from "react";
import {BalanceInput} from "./balance_input";
import {PoolType} from "../../collection/pool/nft_pool_item";
import {useUpDataPool} from "../../../provider/updata_pool_provicer";
import {useDepositETH} from "../../../server/factory";
import {useSendModal} from "../../../widget/modal/send_modal";
import {UpdataLoadingBtn} from "./loading_btn";
import {useWallet} from "use-wallet";
import {ModelType} from "../type";
import {HttpStatus} from "../../../utils/wait_fn";

type TokenBalanceInputProps = {
    balance?: string,
    handleOpen: Function

}
export const TokenBalanceInput: React.FC<TokenBalanceInputProps> = ({
                                                                        balance,
                                                                        handleOpen
                                                                    }) => {

    const {sudo: sudoData, collections} = useUpDataPool()
    const [amount, setAmount] = useState('0')

    const {account} = useWallet();

    const disabled = useMemo(() => {
        if (!sudoData) {
            return true;
        }
        return parseInt(sudoData.type) === PoolType.nft;
    }, [sudoData])

    const isOwnerId = useMemo(() => {
        if (!sudoData) {
            return false
        }
        if (sudoData.owner.id.toLowerCase() == (account || '').toLowerCase()) {
            return true;
        }
        return false
    }, [sudoData])

    if (!collections || !sudoData) {
        return <div/>
    }

    return (
        <BalanceWidget>
            <BalanceInput
                label={'Token'}
                name={'balance'}
                placeholder={`${balance}`}
                unit={`ETH`}
                imgUrl={'eth'}
                onChange={(ev) => {
                    setAmount(ev.target.value)
                }}
                disabledText={disabled ? ["This pool has no token.", ""] : undefined}
            >
                <>
                    <UpdataLoadingBtn disabled={disabled || !isOwnerId} isLoading={HttpStatus.success} onClick={() => {
                        handleOpen(ModelType.TokenDeposit)
                        // onSend(amount)
                    }}>
                        <p className={'deposit'}>Deposit</p>
                    </UpdataLoadingBtn>
                    <div style={{height: "4px"}}/>

                    <UpdataLoadingBtn disabled={disabled || !isOwnerId} isLoading={HttpStatus.success} onClick={() => {
                        handleOpen(ModelType.TokenWithdraw)
                        // sendWithdraw(amount)
                    }}>
                        <p className={'deposit'}>Withdraw</p>
                    </UpdataLoadingBtn>
                </>
            </BalanceInput>
        </BalanceWidget>
    );
}


export const BalanceWidget = styled.div`
  margin-top: 10px;

  .deposit {
    font-size: 12px;
  }

`
