import { createStore } from 'effector'
import {getCollections} from "./effects";
import {asPayload} from "../../../utils/reducer";
import {CollectionsData} from "../../../mock_data/collections";

const initial: any = CollectionsData

export const $getCollections = createStore(initial).on(
    getCollections.doneData,
    asPayload
)
