import React, {useCallback, useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import {Grid} from "@material-ui/core";
import {useForm} from "react-hook-form";
import {LabelInput} from "./widget/label_input";
import {KLink} from "./k_link";
import {useCreaterDataPool} from "../../provider/creater_lp_provider";
import {CURVEEnum} from "./type";
import ethIcon from "../../assets/images/pool_eth.png"
import {range, scan} from "ramda";
import {PoolType} from "../collection/pool/nft_pool_item";
import BigNumber from "bignumber.js";

const SolFee = 0.01;

function addFee(fee: number = 5, number: any) {
    let _fee = new BigNumber(number).times(1 + fee / 100 - SolFee).toNumber()
    return _fee;
}


function sellFee(fee: number = 5, number: any) {
    let _fee = new BigNumber(number).times(1 - fee / 100 - SolFee);
    return _fee.toNumber();
}

export const SunderBarPage: React.FC = () => {
    const {register, errors, watch, getValues, setValue} = useForm();
    const {moralisData, poolType, curve, nftidsStateAction} = useCreaterDataPool()
    let [pool] = poolType
    const [update, setUpdate] = useState('');
    const [option, setOption] = useState<any[]>([]);

    let [moralis, _] = moralisData;
    let [_curve] = curve

    const watchFields = watch(["spotPrice", "delta", "amount"]);


    let {spotPrice, delta, bonding, amount, feeRate, sell_up_to} = getValues();

    const handelOption = useCallback(() => {

        let xL = amount ? range(1, parseInt(amount)) : []

        let buySpotPrice = parseFloat(spotPrice);

        if (_curve === CURVEEnum.EXPONENTIAL) {
            let _elem = new BigNumber(spotPrice).times(Math.pow(1 + (bonding / 100), spotPrice)).toFixed(6);
            buySpotPrice = addFee(0, _elem)
        } else {
            buySpotPrice = addFee(0, spotPrice)
        }
        if (pool === PoolType.token) {
            let _list = scan((acc, elem) => {
                if (_curve === CURVEEnum.EXPONENTIAL) {
                    let _elem = new BigNumber(spotPrice).times(Math.pow(1 + (bonding / 100), elem)).toFixed(6);
                    return addFee(0, _elem)
                }
                return acc += addFee(0, parseFloat(delta))
            }, buySpotPrice, xL);
            return _list;
        }

        let sellList = scan((acc, elem) => {
            if (_curve === CURVEEnum.EXPONENTIAL) {
                let _elem = new BigNumber(spotPrice).times(Math.pow(1 - (bonding / 100), elem)).toFixed(6);
                return sellFee(feeRate, _elem)
            }
            let _a = acc - parseFloat(delta);
            return sellFee(feeRate, _a)
        }, spotPrice, xL);


        if (pool === PoolType.trade) {
            let sL = sell_up_to ? range(1, parseInt(sell_up_to)) : xL;

            let buyList = scan((acc, elem) => {
                if (_curve === CURVEEnum.EXPONENTIAL) {
                    let _elem = new BigNumber(spotPrice).times(Math.pow(1 + (bonding / 100), elem)).toFixed(6);
                    return addFee(feeRate, _elem);
                }

                return new BigNumber(acc).plus(addFee(feeRate, parseFloat(delta))).toNumber();

            }, spotPrice, sL);


            return [sellList, buyList];
        }
        return sellList
    }, [_curve, pool, spotPrice, delta, bonding, amount, feeRate, sell_up_to])

    const optionProps = useMemo(() => {
        return handelOption();
    }, [_curve, pool, spotPrice, delta, bonding, amount, feeRate, sell_up_to]);

    const total = useMemo(() => {
        let t = new BigNumber(0);
        option.forEach((ev) => {
            t = t.plus(ev);
        })
        return t.toFixed(6);
    }, [option])

    useEffect(() => {
        let ids = nftidsStateAction[0];
        if (ids.length) {
            setValue('amount', ids.length)
        }
        if (pool === PoolType.trade) {
            setValue('feeRate', 5)
        }
    }, [])

    useEffect(() => {
        const timer = setTimeout(() => {
            setOption(handelOption())
        }, 400)
        return () => clearTimeout(timer);
    }, [update, bonding])


    return (
        <WapStyle>
            <CellBottomStyle container>

                <Grid item xs={12} sm={12} md={6}>
                    <div className="row_header">
                        <div className="pt header_title">
                            <h3>Select Token</h3>
                            <h4>
                                <img src={ethIcon} alt=""/> <span>ETH</span>
                            </h4>
                        </div>

                        {
                            pool === PoolType.trade && <div className="pt header_title">
                                <LabelInput errors={errors}
                                            inputRef={register({
                                                required: ''
                                            })}
                                            setUpdate={setUpdate}
                                            tis={'----'}
                                            label={'Swap Fee Rate'}
                                            name={'feeRate'}
                                            placeholder={'5.00'}
                                            unit={"%"}
                                />
                            </div>
                        }

                    </div>
                    <div className="pt">
                        <LabelInput errors={errors}
                                    inputRef={register({
                                        required: ''
                                    })}
                                    setUpdate={setUpdate}

                                    tis={'----'}
                                    lastTis={`Floor Price: ${watchFields.spotPrice || '--'} ETH`}
                                    label={'Start Price'}
                                    name={'spotPrice'}
                                    placeholder={'0.00'}
                                    unit={"ETH"}
                        />
                    </div>


                    <div className="pt">
                        {
                            _curve == CURVEEnum.LINEAR ? <>
                                <LabelInput errors={errors}
                                            inputRef={register({
                                                required: ''
                                            })}
                                            setUpdate={setUpdate}

                                            tis={'----'}
                                            label={'Delta'}
                                            name={'delta'}
                                            placeholder={'0.00'}
                                            unit={"ETH"}
                                />
                            </> : <>
                                <LabelInput errors={errors}
                                            inputRef={register({
                                                required: ''
                                            })}
                                            setUpdate={setUpdate}

                                            tis={'----'}
                                            label={'Delta'}
                                            name={'bonding'}
                                            placeholder={'0.00'}
                                            unit={"%"}
                                />
                            </>
                        }

                    </div>
                    <div className=" pt assets">
                        <h3>Asset Amounts</h3>
                        <h4>Set how many tokens you deposit into the pool.</h4>
                    </div>

                    <div className="pt">
                        {
                            pool !== PoolType.trade && <LabelInput errors={errors}
                                                                   inputRef={register({
                                                                       required: 'amount'
                                                                   })}
                                                                   setUpdate={setUpdate}

                                                                   label={'Amount'}
                                                                   name={'amount'}
                                                                   placeholder={'0'}
                            >
                                <div className="btn_max">
                                    {moralis.symbol}
                                </div>
                            </LabelInput>
                        }

                        {
                            pool === PoolType.trade && <div>
                                <LabelInput errors={errors}
                                            inputRef={register({
                                                required: 'amount'
                                            })}
                                            setUpdate={setUpdate}

                                            label={'Buy up to'}
                                            name={'amount'}
                                            placeholder={'0'}
                                >
                                    <div className="btn_max">
                                        {moralis.symbol}
                                    </div>
                                </LabelInput>
                                <LabelInput errors={errors}
                                            inputRef={register({
                                                required: 'sell_up_to'
                                            })}
                                            setUpdate={setUpdate}

                                            label={'Sell up to'}
                                            name={'sell_up_to'}
                                            placeholder={'0'}
                                >
                                    <div className="btn_max">
                                        {moralis.symbol}
                                    </div>
                                </LabelInput>
                            </div>
                        }

                        {
                            (pool === PoolType.token) &&
                            <p className='bottom_msg'>You will need to deposit {total} ETH total.</p>
                        }
                        {
                            pool === PoolType.nft &&
                            <p className='bottom_msg'>You will receive {total} ETH total.</p>
                        }

                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <KLink total={total}
                           option={option.length == 0 ? optionProps : option}
                           watch={watch(["spotPrice", "bonding", "delta", "amount", "sell_up_to", "feeRate"])}
                           getValues={getValues}
                    />
                </Grid>
            </CellBottomStyle>

        </WapStyle>
    );
}

const WapStyle = styled.div`


  .assets {
    h3 {

      font-family: 'ZX';
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 18px;
      color: #FFFFFF;
    }

    h4 {

      font-family: 'ZX';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: -0.14em;
      color: #FFFFFF;
    }
  }


  .MuiPaper-root {
    background: transparent;
  }

  .MuiAccordionSummary-root {
    padding: 0 20px;
  }

  .MuiButtonBase-root {
    background: #161515;
    border-radius: 10px;
    height: 78px;
    border: 1px solid transparent;

    .MuiButtonBase-root {
      background: transparent;
    }
  }

  .MuiAccordionSummary-root.Mui-expanded {
    background: #262525;
    border: 1px solid #A19F9F;
  }

  .MuiAccordionDetails-root {
    padding-left: 0;
    padding-right: 0;
  }

  @media (max-width: ${({theme}) => theme.sm}) {
    .MuiButtonBase-root, .MuiAccordionSummary-root.Mui-expanded {
      height: 240px;
    }
  }
`

const CellBottomStyle = styled(Grid)`
  padding: 10px 0;
  box-sizing: border-box;
  margin-top: 8px;

  .row_header {
    display: flex;

    .inputStyle {
      padding-top: 0;
      padding-bottom: 0;

    }

    .header_title {
      .left {
        font-size: 18px;
        line-height: 18px;
      }

      h3 {
        font-family: 'ZX';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 18px;
        color: #FFFFFF;
      }

      h4 {
        cursor: pointer;
        width: 150px;
        display: flex;
        align-items: center;
        margin: 10px 0;
        padding: 5px;
        border-radius: 100px;
        background: #2C2F36;

        img {
          display: block;
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }

        font-family: 'Skinny';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 18px;


        color: #FFFFFF;
      }
    }

  }

  .pt {
    padding: 5px 30px;
    box-sizing: border-box;

    .btn_max {
      background-color: #2d2f35;
      padding: 4px 10px;
      border-radius: 100px;
      font-size: 18px;
      font-weight: 400;
      color: #FFFFFF;
      cursor: pointer;
    }

    .bottom_msg {
      font-size: 14px;
      font-weight: 400;
      color: #FFFFFF;
      margin-bottom: 20px;
    }
  }

  .tis {
    font-size: 16px;
    font-weight: 500;
    color: #BABAC8;
    padding: 10px;
    box-sizing: border-box;
    line-height: 20px;

    p {
      margin-bottom: 5px;
    }
  }

  .bottom_text {
    padding: 0 30px 20px;
    box-sizing: border-box;
    color: #FE9000;
    font-size: 12px;
  }

  @media (max-width: ${({theme}) => theme.sm}) {
    .tis {
      padding: 20px;
      font-size: 14px;
    }
  }
`
