import {Contract} from 'web3-eth-contract'
import BigNumber from "bignumber.js";
import FACTORY_ABI from "./abi/factory_abi"
import Router_ABI from "./abi/router_abi"

//[转发自 apple]
// goerli Contract Addresses
// EXPONENTIAL_CURVE: "0xA5Db357Ae0b8d4FC24a9b47DdE49cD237a7b4e57" // 曲线
// LINEAR_CURVE: "0x0aaaCF44a62bBff5D903Dae61bbC19aE4566a0c9"
// PAIR_FACTORY: "0x5F619ED251B1b948D942fd15B5dc4216D10f428E"
// PAIR_ROUTER: "0x3E37e5446b7CB15B234908E84E5A8E133DEF26d5"
export const ContractMap: any = {
    FACTORY: {
        address: {
            4: "0x63684c8377f76931A69494420EA25126Bb72e97E",
        },
        abi: FACTORY_ABI
    },
    PAIR_ROUTER: {
        address: {
            4: "0x99426F0D6bC709Bd8F7FE10bA6208deD1E2F6b90",
        },
        abi: Router_ABI
    },
    EXPONENTIAL: {
        address: {
            4: "0xA5Db357Ae0b8d4FC24a9b47DdE49cD237a7b4e57",
        },
        abi: []
    },

}


export const CURVE = {
    EXPONENTIAL_CURVE: "0xa5db357ae0b8d4fc24a9b47dde49cd237a7b4e57",
    LINEAR_CURVE: "0x0aaacf44a62bbff5d903dae61bbc19ae4566a0c9"
}
