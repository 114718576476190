import React from "react";
import styled from "styled-components";

export const ButtonWidget: React.FC<{ text: string, onClick?: Function, isDisable?: boolean }> = ({
                                                                                                      text,
                                                                                                      onClick,
                                                                                                      isDisable = false
                                                                                                  }) => {
    return (
        <ButtonStyle className={'ButtonWidget'} isDisable={isDisable} onClick={
            () => {

                if (!isDisable) {
                    onClick && onClick();
                }

            }
        }>
            {text}
        </ButtonStyle>
    );
}


export const ButtonStyle = styled.div<{
    isDisable: boolean
}>`
  background-color: ${({isDisable}) => isDisable ? "#45464A" : '#D9D6D6'};
  border-radius: 10px;
  height: 38px;
  display: flex;
  align-items: center;

  justify-content: center;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: ${({isDisable}) => isDisable ? "#898989" : "#191919"};
  padding: 0 10px;
  cursor: ${({isDisable}) => isDisable ? "no-drop" : "pointer"};

`
