import React, {useEffect} from "react";
import styled from "styled-components";
import twitterImg from "../assets/images/twitter.png"
import githubImg from "../assets/images/gihtub.png"
import dicoverImg from "../assets/images/dicover.png"


export const FooterWidget: React.FC = () => {

    return (
        <FooterStyle>
            <a href="https://twitter.com/rootswapxyz" target="_blank">
                <img src={twitterImg} alt=""/>
            </a>
            <a href="https://github.com/rootswapdev" target="_blank">
                <img src={githubImg} alt=""/>
            </a>
            <a href="https://discord.gg/p2KhYjvawk" target="_blank">
                <img src={dicoverImg} alt=""/>
            </a>
            <a href="https://docs.rootswap.xyz/"  target="_blank">
                Docs
            </a>
            {/*<a href="">*/}
            {/*    Analytics*/}
            {/*</a>*/}
        </FooterStyle>

    );
}

const FooterStyle = styled.div`
  padding: 10px 0;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    padding: 0 20px;
    box-sizing: border-box;
    color: #FFFFFF;

    img {
      display: block;
      height: 30px;
    }
  }
`

