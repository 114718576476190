import {RightWidget} from "./widget/rigth";
import React, {useMemo} from "react";
import styled from "styled-components";
import LeafImg from "../../assets/images/index_logo.png"
import bgImg from "../../assets/images/g_bg.png"
import {useHistory} from "react-router-dom";
import {SearchBarWidget} from "./widget/search_bar";


export const MainPage: React.FC = () => {
    const history = useHistory();

    return (
        <MainStylee>

            <div className="bgImg">
                <img src={bgImg} alt=""/>
            </div>
            <div className="inner">
                <img src={LeafImg} alt="" className="leaf"/>
                <h2 className={'marketplace'}>The most <span>rewarding</span> decentralized NFT marketplace.</h2>
                <h3 className={'supply'}>Supply single-side, both sides, or trade to earn <span>$ROOT!</span></h3>
                <SearchBarWidget/>
                <RightWidget/>
                <div className="all_collections" onClick={() => {
                    history.push("/main/all");
                }}>
                    View All Collections
                </div>
            </div>

        </MainStylee>
    )
}

const MainStylee = styled.div`
  background-size: cover;
  background-repeat: no-repeat;

  .bgImg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;

    img {
      display: block;
      height: 100%;
      width: 100%;
    }
  }

    //background-image: url(${bgImg});
  margin-bottom: -30px;

  .inner {
    max-width: 1600px;
    width: 90%;
    margin: 0 auto;

    padding: 40px 0;
    box-sizing: border-box;
  }


  .leaf {
    width: 200px;
    display: block;
    margin: 0 auto;
  }

  .all_collections {
    cursor: pointer;
    padding: 15px 0;
    text-align: center;
    width: 400px;
    background: #FFFFFF;
    border: 1px solid #060606;
    border-radius: 12px;
    margin: 150px auto 0 auto;
  }

  @media (min-width: 1300px) {
    .all_collections {
      margin: 50px auto 0 auto;
    }
  }

  .marketplace, .supply {
    margin-top: 20px;

    span {
      color: #E0446A;

    }
  }

  .marketplace {
    text-align: center;
    color: white;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
  }

  .supply {
    text-align: center;
    color: white;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
  }


  .center {
    margin: 40px 0;
    display: flex;
    justify-content: flex-end;

    .buttons {

    }
  }
`

export const MinButtonStyle = styled.span`
  border: 1px solid #FFFFFF;
  padding: 10px 25px;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF;
  cursor: pointer;
`
