import styled from "styled-components";
import React from "react";
import {useCreaterDataPool} from "../../../provider/creater_lp_provider";

type StepBtnWidgetProps = {
    page: number,
    disabled?: boolean,
    text?:string
}
export const StepBtnWidget: React.FC<StepBtnWidgetProps> = ({page, disabled, text}) => {
    const {setActiveStep} = useCreaterDataPool()

    return (<StepStyle className={disabled ? "disabled" : ""} onClick={() => {
        if (disabled) return
        setActiveStep(page)
    }}>
        {text ||"Next Step"}
    </StepStyle>);
}

export const StepStyle = styled.div`
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.15em;
  color: #C1C1C2;
  background: #191B1F;
  border: 1px solid #C1C1C2;
  border-radius: 12px;
  padding: 7px 18px;

  &.disabled {
    cursor: not-allowed;
  }
`
