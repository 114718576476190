import {createReducer} from "@reduxjs/toolkit";
import {addToken, deleteToken, initTokenList, upDataBalance} from "./actions";
import BigNumber from "bignumber.js";



export interface AirDropState {
    readonly listToken:{ [key: string]: any & {balance ?: BigNumber} }
}

const initialState: AirDropState = {
    listToken: {},
}


export default createReducer(initialState, builder =>
    builder
        .addCase(initTokenList, (state, action) => {
            state.listToken = action.payload.tokenMap;
        })
        .addCase(upDataBalance, (state, action) => {
            const {payload:{valueKey,balance}} = action;
            state.listToken[valueKey].balance = balance;
        })
        .addCase(deleteToken, (state, action) => {
            const {payload:{tokenKey}} = action;
            delete  state.listToken[tokenKey];
        })
        .addCase(addToken, (state, action) => {
            const {payload:{tokenKey,tokenItem}} = action;
            state.listToken[tokenKey] = tokenItem;
        })

)
