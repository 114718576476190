export enum ShoppingType {
    buy,
    sell
}


export type SellItem = {
    sellPrice: string,
    id: string
}
