import React, {useCallback, useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import {WidthStyle} from "../../widget/style/width_style";
import {HttpStatus} from "../../utils/wait_fn";
import {Grid, Switch, TextField} from "@material-ui/core";
import * as R from "ramda";
import {EnsNameWidget} from "../inventory/widget/ens_name";
import {NftPoolItem} from "../collection/pool/nft_pool_item";
import {RootMyPoolData} from "../../model/my_pool_data";
import {useCollections} from "./hook/useCollections";
import {defaultCollections} from "./type";
import {LoadingWidget} from "../../widget/base/loading_widget";
import {TypeSelectionWidget} from "./widget/type_selection";
import {usePool} from "./hook/usePool";
import {useHistory} from "react-router-dom";
import {anyPass, filter, propSatisfies} from "ramda";


export const MyPoolMain: React.FC = () => {
    const {push} = useHistory()
    const [filters, setFilters] = useState<any>({});
    const [isShow, setIsShow] = useState(false)
    const [isShowEmpty, setIsShowEmpty] = useState(true)
    const [searchText, setSearch] = useState("");
    const [keyLists, setkeyLists] = useState<string[]>([])

    const {
        request,
        status,
        pools,
        productionFeeding,
    } = usePool(filters, isShow)

    const {handeGetCollection, collectionMap} = useCollections()

    const getCollection = useCallback((id: string) => {
        if (collectionMap[id]) {
            return collectionMap[id];
        }
        return defaultCollections;
    }, [collectionMap])

    const groupPools = useMemo(() => {
        let _pools = R.groupBy((ev: RootMyPoolData) => ev.collection.id)(pools)
        return _pools;
    }, [pools, collectionMap])

    const handerKeyLists = useCallback(() => {
        let _searchText = searchText?.toLowerCase();
        let _keys = filter(
            anyPass([
                propSatisfies(
                    (name: string ) => {
                        return name?.toLowerCase().indexOf(_searchText) > -1;
                    },
                    'name'
                ),
                propSatisfies(
                    (name: string) => {
                        return name?.toLowerCase().indexOf(_searchText) > -1;
                    },
                    'symbol'
                ),
                propSatisfies(
                    (address: string) => {
                        return address?.toLowerCase().indexOf(_searchText) > -1;
                    },
                    'address'
                ),
            ]),
            Object.values(collectionMap) || []
        );
        setkeyLists(_keys.map((ev) => ev.address.toLowerCase()))
    }, [collectionMap, searchText])

    const getData = useCallback(async () => {
        try {
            let data = await request('');
            if (!data) {
                return
            }
            let _groupData = R.compose(
                R.keys,
                R.groupBy((student: RootMyPoolData) => {
                    return student.collection.id;
                }), R.path(['data', 'pairOwner', 'pairs']),)(data);
            handeGetCollection(_groupData)
        } catch (e) {
            console.log(e)
        }
    }, [handeGetCollection])


    useEffect(() => {
        getData()
    }, [])

    useEffect(()=>{
        let itemFn = setTimeout(()=> handerKeyLists(), 500)
        return () => clearTimeout(itemFn)
    },[collectionMap, searchText])

    if (status === HttpStatus.wait) {
        return <LoadingWidget/>;
    }


    return (
        <InventoryStyle>
            <div className={"title"}>
                <EnsNameWidget/>
                <div className={'btn'}
                     onClick={() => {
                         push('/lp')
                     }}
                >New Position
                </div>
            </div>
            <div className="body">

                <LeftItemStyle>

                    <h4>
                        Hiding Empty Pools
                        {/*{isShow ? "Show Hidden Pools" : "Hiding Empty Pools"}*/}
                        <Switch
                            color="primary"
                            value={isShowEmpty}
                            defaultChecked={true}
                            onChange={() => {
                                setIsShowEmpty(!isShowEmpty)
                            }}
                            inputProps={{'aria-label': 'checkbox with default color'}}/>
                    </h4>


                    <div className="input">
                        <TextField
                            unselectable={'off'}
                            fullWidth={true}
                            value={searchText}
                            onChange={(ev) => setSearch(ev.target.value)}
                        />
                    </div>

                    {
                        keyLists.map((pools) => {
                            return <div key={`${pools}_`} onClick={() => {
                                let type = filters[pools] || false
                                let _data = {
                                    ...filters,
                                    [pools]: !type
                                }
                                setFilters(_data);
                            }}>
                                <TypeSelectionWidget
                                    name={getCollection(pools).symbol}
                                    value={`${groupPools?.[pools]?.length}`}
                                    isSelect={!filters[pools]}
                                />
                            </div>;
                        })
                    }
                </LeftItemStyle>

                <Grid className={'nft_wap'} container spacing={1}>
                    {productionFeeding.map((ev: RootMyPoolData) =>
                        <NftPoolItem key={`${ev.id}`} props={{
                            type: parseInt(ev.type),
                            sudoData: ev,
                            collections: getCollection(ev.collection.id),
                            isShowEmpty,
                        }}/>)}
                </Grid>
            </div>

        </InventoryStyle>
    );
}

const LeftItemStyle = styled.div`
  width: 23%;


  .input {
    margin-top: 20px;
    background: #080808;
    border: 1px solid #8D909A;
    border-radius: 10px;
    padding: 4px 10px;
    box-sizing: border-box;
  }

  .MuiInputBase-root {
    color: white;
  }

  .MuiInput-underline:before, .MuiInput-underline:after {
    display: none;
  }

  .MuiInputBase-input {
    font-family: 'ZX', sans-serif;

    &::-webkit-input-placeholder {
      text-align: start;
    }
  }

  h4 {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: -0.14em;
    color: #8D909A;
    justify-content: space-between;
  }
`


const InventoryStyle = styled.div`
  ${() => WidthStyle.MinWidth};
  padding-top: 40px;
  box-sizing: border-box;
  max-width: 100%;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
    box-sizing: border-box;

    .btn {
      padding: 10px 20px;
      box-sizing: border-box;
      background: #FFFFFF;
      border: 2px solid #000000;
      border-radius: 12px;
      cursor: pointer;
    }
  }

}

.body {
  padding-bottom: 40px;

  .nft_wap {
    width: 80%;
    border-radius: 20px;
    padding: 10px;
    box-sizing: border-box;
  }


  max-width: 96%;
  margin: 0 auto;
  justify-content: space-between;
  padding-top: 30px;
  display: flex;


}

`
