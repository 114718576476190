import React, {useMemo} from "react";
import styled from "styled-components";
import Jazzicon from "@raugfer/jazzicon";
import {useWallet} from "use-wallet";
import {FormatAddr} from "../../../utils/format_addr";
import {useENSName} from 'use-ens-name';

export const EnsNameWidget: React.FC = () => {
    const {account} = useWallet()

    const buildDataUrl = useMemo(() => {
        return 'data:image/svg+xml;base64,' + btoa(Jazzicon(account || ''));
    }, [account])

    const name = useENSName(account)

    return (<EnsNameStyle>
        <img src={buildDataUrl} alt="" className="img"/>
        <div className="right">
            <p className={'name'}>
                {name?.toUpperCase()}
            </p>
            <p>{FormatAddr(account)}</p>
        </div>
    </EnsNameStyle>)
}


const EnsNameStyle = styled.div`
  display: flex;
  padding-left: 24%;

  img {
    display: block;
    width: 70px;
    border-radius: 20px;
    margin-right: 20px;
  }

  .right {
    .name {
      font-style: normal;
      font-size: 32px;
      line-height: 32px;
      display: flex;
      align-items: center;
      font-weight: bold;
      color: #FFFFFF;
      letter-spacing: -0.05em;
      margin-bottom: 10px;
    }

    p {
      font-family: 'ZX';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 18px;

      display: flex;
      align-items: center;
      letter-spacing: -0.05em;
      text-decoration-line: underline;

      color: #D9D9D9;

    }
  }
`
