import React, {useCallback, useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {useForm} from "react-hook-form";
import {LabelInput} from "../../updata_pool/widget/label_input";
import Question from "../../../assets/images/question-mark.svg"
import {ModalContext} from "../../../provider/model_provider";
import {NftHeaderMsg} from "../type";
import {PoolType} from "../../collection/pool/nft_pool_item";
import {HttpStatus} from "../../../utils/wait_fn";
import {useCheckAvailable} from "../hook/use_search_nft";
import {LoadingImgWidget} from "../../../widget/base/loading_widget";


type NftPoolProps = {
    nfts: NftHeaderMsg[],
    setMoralis: Function,
    poolType: PoolType
}

export const ListTokenModel: React.FC<NftPoolProps> = (props) => {

    const [select, setSelect] = useState('');
    const {createPair, data, status, setStatus} = useCheckAvailable();

    const requestCreate = useCallback(async () => {
        await createPair(select);
    }, [createPair, select, data, status]);

    const {register, errors} = useForm();
    const {onDismiss} = useContext(ModalContext);
    useEffect(() => {
        if (!select) {
            setStatus(HttpStatus.start)
            return;
        }
        let timeOut = setTimeout(requestCreate, 500);
        return () => clearInterval(timeOut);
    }, [select])


    return (
        <NftPollStyle>
            <div className="model_title">
                <h3>Select token</h3>
            </div>

            <div className="pt">
                <LabelInput errors={errors}
                            inputRef={register({})}
                            label={''}
                            onChange={(ev) => {
                                setSelect(ev.target.value);
                            }}
                            name={'Price'}
                            placeholder={'Search NFT Name'}
                >
                </LabelInput>
            </div>

            <div className="list">

                {props.nfts.map((ev) => {

                    return <NftItemStyle onClick={() => {
                        props.setMoralis(ev)
                        onDismiss()
                    }}>
                        <div className={'img'}>
                            <img src={Question} onError={(event) => Question}
                                 alt="-"/>
                        </div>
                        <div>
                            <h2>{ev.name}</h2>
                            <h4>{ev.symbol}</h4>
                        </div>

                    </NftItemStyle>
                })}


                {
                    status === HttpStatus.wait && <LoadingImgWidget/>
                }
                {
                    status === HttpStatus.failure &&
                    <h4 className="err_msg">{data.err}</h4>
                }
                {
                    status === HttpStatus.success &&
                    <NftItemStyle onClick={() => {
                        props.setMoralis(data)
                        onDismiss()
                    }}>
                        <div className={'img'}>
                            <img src={Question} onError={(event) => Question}
                                 alt="-"/>
                        </div>
                        <div>
                            <h2>{data.name}</h2>
                            <h4>{data.symbol}</h4>
                        </div>

                    </NftItemStyle>
                }
            </div>


        </NftPollStyle>
    )
}


const NftItemStyle = styled.div`
  display: flex;
  padding: 10px 10px;
  background: #52565F;
  border: 1px solid #323841;
  margin-bottom: 20px;
  border-radius: 10px;
  cursor: pointer;

  .img {
    margin-right: 10px;

    img {
      display: block;
      width: 30px;
      height: 30px;
    }
  }

  div {
    h2 {
      font-family: 'Skinny';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 18px;
      color: #FFFFFF;
      letter-spacing: 0;
    }

    h4 {
      font-family: 'Skinny';
      font-style: normal;
      font-weight: 400;
      line-height: 15px;
      font-size: 15px;
      color: #333;
      letter-spacing: 0;
    }
  }

`

const NftPollStyle = styled.div`

  .list {
    padding: 20px 0;

    p {
      color: white;
    }

    .err_msg {
      color: red;
      text-align: center;
    }
  }

  width: 60%;
  margin: 0 auto;
  background-color: #191b1f;
  border-radius: 20px;
  border: 1px solid #2C2F36;
  padding: 0 20px;
  box-sizing: border-box;

  .model_title {
    padding: 17px 0;
    text-align: center;
    border-bottom: 2px solid rgba(255, 255, 255, .1);


    h3 {
      font-weight: 400;
      font-size: 20px;
      line-height: 20px;
      letter-spacing: -0.14em;
      color: #FFFFFF;
      margin-bottom: 20px;
    }

    h4 {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: #52565F;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        display: block;
        width: 35px;
      }
    }

  }

  .pt {
    margin-top: 20px;
  }

`
