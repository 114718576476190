import React, {useMemo} from "react";
import styled from "styled-components";
import {WidthStyle} from "../../widget/style/width_style";
import {TitleCompone} from "../../compones/title";
import {LoadingWidget} from "../../widget/base/loading_widget";
import {SelectType} from "./select_type";
import {SelectToken} from "./select_token";
import {SunderBarPage} from "./sunder_bar";
import {CreaterPoolProvider} from "../../provider/creater_lp_provider";
import {SelectNft} from "./select_nft";
import {StepEnum} from "./type";



export const CreaterLP: React.FC = () => {
    const [activeStep, setActiveStep] = React.useState<StepEnum>(0);


    const Widget = useMemo(() => {
        let _widget: any = LoadingWidget;
        switch (activeStep) {
            case StepEnum.SelectType:
                _widget = SelectType;
                break;
            case StepEnum.SelectToken:
                _widget = SelectToken;
                break;
            case StepEnum.SelectNft:
                _widget = SelectNft;
                break;
            case StepEnum.CreaterPool:
                _widget = SunderBarPage;
                break;
        }
        return _widget

    }, [activeStep])

    return (
        <CreaterPoolProvider setActiveStep={setActiveStep}>
            <div style={{paddingTop: "40px"}}>
                <ValueStyle>
                    <TitleCompone step={activeStep} setStep={setActiveStep}/>
                    {/*<ChartsWIdget />*/}
                    <Widget/>

                </ValueStyle>
            </div>
        </CreaterPoolProvider>


    );
}

const ValueStyle = styled.div`
  padding: 15px 0;
  box-sizing: border-box;
  ${() => WidthStyle.MinWidth};
  max-width: 1000px;
  background: #191B1F;
  border-radius: 20px;
  border: 1px solid #2A2F37;
`
