import React from "react";
import InventorySelectAc from "../../../assets/images/inventory_select_ac.png";
import InventorySelectUn from "../../../assets/images/inventory_select_un.png";
import styled from "styled-components";

type TypeSelectionInterface = {
    name: string,
    value: string,
    isSelect?: boolean
}
export const TypeSelectionWidget: React.FC<TypeSelectionInterface> = ({name, value, isSelect}) => {
    return (
        <TypeStyle className={isSelect ? "ac" : ""}>
            <div className={'name'}>
                <p>
                    <img src={isSelect ? InventorySelectAc : InventorySelectUn} alt=""/>
                    {name}

                </p>
                <p><span>{value || '0'}</span></p>
            </div>
        </TypeStyle>
    );
}


const TypeStyle = styled.div`
  cursor: pointer;

  .name {
    padding: 7px 10px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #242424;
    border-radius: 8px;
    box-sizing: content-box;

    img {
      display: block;
      width: 14px;
      margin-right: 10px;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      align-items: center;
      letter-spacing: -0.14em;
      color: #FFFFFF;
      display: flex;
    }

    span {
      display: inline-block;
      padding: 4px 5px;
      border-radius: 5px;
      color: white;
      margin-left: 5px;
      background-color: #000;
    }
  }

  &.ac {
   
  }
`
