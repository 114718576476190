import {createEffect} from 'effector'
import axios, { AxiosRequestHeaders } from 'axios'
import {path, prop, uniq} from 'ramda'

export interface RequestEffectPayload {
    url: string
    method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
}

export function createRequestMoralisEffect<R, P extends Record<string, any> | void>({
                                                                                 url,
                                                                                 method = 'GET',
                                                                             }: RequestEffectPayload) {
    const keys = uniq(url.match(/:\w+/g) ?? [])
    let innerUrl = url

    return createEffect<P extends void ? void : P & { _noAuth?: boolean }, R>(
        async (payload) => {
            const hasData = !['GET', 'DELETE'].includes(method)
            const hasParams = !hasData && keys?.length === 0

            if (keys?.length > 0 && payload != null) {
                innerUrl = keys.reduce(
                    // @ts-ignore
                    (acc, el) => acc.replaceAll(el, payload![el.slice(1)]),
                    url
                )
            }

            const headers: AxiosRequestHeaders = {
                'x-api-key': 'Vwg5s1IazsJoFonJXfUStuQ5KTAPMEnhtJUiZCij29Y51ov8BdrtR8SgQoOUVpTS'
            }
            if (
                payload == null ||
                (typeof payload === 'object')
            ) {

            }



            return axios
                .request({
                    url: `${innerUrl}${payload}`,
                    method,
                    headers,
                    data: hasData ? payload : undefined,
                    params: hasParams ? payload : undefined,
                })
                .then(prop('data'))
        }
    )
}

axios.interceptors.response.use(undefined, (error) => {
    const msg = path(['response', 'data'], error) || error.message

    throw error
})

// export const getToken = () =>
//     import('../users/store').then(({default: $user}) =>
//         ifElse(
//             isNil,
//             always(undefined),
//             (token) => `Bearer ${token}`
//         )($user.getState().token)
//     )
