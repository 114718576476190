
import {blacks, blues, greens, oranges, reds, whites} from './colors'


export const theme = {
    borderRadius: 12,
    breakpoints: {
        mobile: 400,
    },
    root: {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'white',
        height: 48,
        padding: '0 30px',
    },
    color: {
        blacks,
        whites,
        blues,
        oranges,
        reds,
        greens,

    },
    siteWidth: 1200,
    spacing: {
        1: 4,
        2: 8,
        3: 16,
        4: 20,
        5: 32,
        6: 48,
        7: 64,
    },
    topBarSize: 72,
    xs:'0px',
    sm:'600px',
    md:'960px',
    lg:'1280px',
    xl:'1920px',
};

