import React, {} from "react";
import {TextField} from "@material-ui/core";
import styled from "styled-components";
import indexEth from "../../../assets/images/index_eth.png"

interface InputInterFace {
    label: string,
    placeholder: string,
    inputRef: React.Ref<any>,
    errors: any;
    name?: string,
    type?: string,
}


export const LabelInput: React.FC<InputInterFace> = ({
                                                         label,
                                                         placeholder,
                                                         inputRef,
                                                         errors,
                                                         name,
                                                         type,
                                                         children
                                                     }) => {


    return (
        <>
            <LabelStyle className={'LabelInput_title'}>
                <p className={'left'}>
                    {label}

                </p>
                <p className='right'>
                </p>


            </LabelStyle>

            <FlexStyle>
                <InputStyle>
                    <TextField name={name || label}
                               error={Boolean(errors[name || label])}
                               placeholder={placeholder}
                               unselectable={'off'}
                               fullWidth={true}
                               inputRef={inputRef}
                               type={type}
                    />
                    {
                        children ? children : <img src={indexEth} alt=""/>
                    }

                </InputStyle>


            </FlexStyle>
            {/*<HelperTextStyle>*/}
            {/*    <p className={'err'}>{errors[name || label] ? errors[name || label].message : ''}</p>*/}
            {/*</HelperTextStyle>*/}
        </>
    );
}

export const ButtonStyle = styled.div`
  height: 30px;
  width: 45px;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  margin-right: 15px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
const HelperTextStyle = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;

  .err {
    color: red;
    font-size: 14px;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    font-size: 14px;
    align-items: center;
    line-height: 1;
    color: #2A1D69FF;

    p {
      display: block;
      margin-right: 5px;
    }

    a {
      color: ${({theme}) => theme.color.greens[100]};
      text-decoration: none;
      font-size: 12px;

    }
  }
`;

const LabelStyle = styled.h2`
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #FFFFFF;
  height: 24px;

  .left {
    color: #FFFFFF;
    line-height: 1;
    font-size: 14px;
    font-weight: 400;
    margin-right: 5px;
    display: flex;
    align-items: center;
  }

  .right {
    font-size: 14px;
    font-weight: 400;
    color: #7094C7;
  }

  img {
    display: block;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  @media (max-width: ${({theme}) => theme.sm}) {
    .left {
      font-size: 14px;
    }
  }

`


export const FlexStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  margin: 5px 0;
  box-sizing: border-box;

  .MuiInputBase-root {
    color: white;
  }

  .MuiInput-underline:before, .MuiInput-underline:after {
    display: none;
  }

  .balance {
    color: #6F6F7D;
    font-size: 14px;
    width: 120px;

    span {
      color: #7B7A89;

    }
  }
`

export const InputStyle = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  //clip-path: inset(0 round 4px);
  padding: 2px 10px;
  background: #080808;
  border: 1px solid #8D909A;
  border-radius: 6px;

  img {
    display: block;
    width: 15px;
  }

  span {
    font-size: 14px;
    font-weight: 400;
    color: #7688B7;
    display: inline-block;
  }

  .EXPONENTIAL{
    color: white;
  }

  .MuiInputBase-input {
    font-family: 'ZX', sans-serif;

    &::-webkit-input-placeholder {
      text-align: start;
    }
  }


  .balance {
    color: #7B7A89;
    width: 200px;

    span {
      color: #7B7A89;

    }
  }

`


