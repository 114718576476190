import {NetAxios} from "./api";


// https://eth-goerli.g.alchemy.com/v2/V0a5t63BSf7sP8CPhq_FMlzqdJ4lt80c
// const alchemyapi = new NetAxios(`https://api.thegraph.com/subgraphs/id/QmXs4pbTJq7oksfixLSQpytypXhXFxsd7VRhHrmis4VJT9`)
const alchemyapi = new NetAxios(`https://eth-goerli.g.alchemy.com/v2/V0a5t63BSf7sP8CPhq_FMlzqdJ4lt80c/`)
export const zgf = "https://api.thegraph.com/subgraphs/name/zgfzgf/graphname"
export class HttpServer {

    // static getCollectionList() {
    //     return new NetAxios(`https://sudoapi.xyz/v1/collections/`).get("", {
    //         sort: "volume_all_time",
    //         desc: true,
    //         pageNumber: 1
    //     });
    // }


    static alchemyapi(contractAddress: string,
                      tokenId: string,
                      tokenType: string = 'erc721'
    ) {
        return alchemyapi.get("getNFTMetadata/", {
            contractAddress, tokenId, tokenType
        });
    }

    static alchemyapiGetNft(contractAddress: string,
                            owner: string,
    ) {
        return alchemyapi.get("getNFTs/", {
            contractAddresses: [contractAddress], owner
        });
    }

    static getCollectionById(id: string) {
        return new NetAxios(`https://sudoapi.xyz/v1/collections/`).get(id, "", true, {});
    }

    static thegraphGetNft(address: string) {
        address = address.toLowerCase();
        const apis = new NetAxios(zgf);
        return apis.post(``, {
            "query": `{
        collection(id: "${address}") {
          pairs(first: 100, skip: 0) {
            id
            owner {
              id
            }
             collection {
            id
          }
            assetRecipient
            type
            bondingCurve
            delta
            fee
            spotPrice
            nftIds
            ethBalance
            ethVolume
          }
        }
        }`
        })
    }

    static thegraphAaronGetNft(address: string) {
        address = address.toLowerCase();
        const apis = new NetAxios(zgf);
        return apis.post(``, {
            "query": `{
  newPairs(first:100, where:{
    nft:"${address}"
  }){
    pair{
      id,
      nft,
      type:poolType,
      bondingCurve:bondingCurveAddress,
      delta,
      fee,
      spotPrice,
      ethLiquidity,
    }
    newPairsId:id,
    initialETHLiquidity,
    nftIds:initialNFTIdInventory,
    initialDelta,
    owner,
    nft
    
    
  }
}`
        })
    }


    static myThegraph(address: string) {
        const apis = new NetAxios(zgf);
        return apis.post(``, {
            "query": `{
  newPairs(first:100, where:{
    owner:"${address}"
  }){
    pair{
      id,
      nft,
      type:poolType,
      bondingCurve:bondingCurveAddress,
      delta,
      fee,
      spotPrice,
      ethLiquidity,
    }
    initialETHLiquidity,
    nftIds:initialNFTIdInventory,
    initialDelta,
    owner,
    nft
    
    
  }
}`
        })
    }

    static thegraph(address: string) {
        const apis = new NetAxios(zgf);
        return apis.post(``, {
            "query": `{
      pairOwner(id: "${address}") {
        pairs(first: 100, skip: 0) {
          id
          owner {
              id
            }
             collection {
            id
          }
          type
          bondingCurve
          delta
          fee
          spotPrice
          nftIds
          ethBalance
          ethVolume
        }
      }
    }`
        })
    }

    static getMyNft(address: string = '0x7302509251F3bdA6428365bAe08206D4C3D3173C') {
        const apis = new NetAxios('https://deep-index.moralis.io/api/v2/0x7302509251F3bdA6428365bAe08206D4C3D3173C/nft?chain=rinkeby&format=decimal');
        return apis.get(``, {}, true, {'x-api-key': 'Vwg5s1IazsJoFonJXfUStuQ5KTAPMEnhtJUiZCij29Y51ov8BdrtR8SgQoOUVpTS'});
    }

    static getActivity(address: string = '0x7302509251F3bdA6428365bAe08206D4C3D3173C') {
        const apis = new NetAxios('https://api.defined.fi/');
        return apis.post(``, {
            "query": `{
          getNftEvents(address: "${address}", networkId: 5, exchangeAddress: "0x3E37e5446b7CB15B234908E84E5A8E133DEF26d5", limit: 50) {
            items {
              tokenId
              maker
              taker
              totalPrice
              individualPrice
              eventType
              transactionHash
              timestamp
              numberOfTokens
              poolAddress
            }
          }
        }`
        }, {'x-api-key': 'ZDhetvbpiAB1dMn9FPP8SRkcSnoueg2hf1poyY30'});
    }
}
