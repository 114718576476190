import React from "react";
import styled from "styled-components";
import {WidthStyle} from "../../widget/style/width_style";
import {SearchBarWidget} from "./widget/search_bar";
import {AllCollectionTable} from "./collections/tablse";

export const AllCollectionsPage: React.FC = () => {
    return (
        <MainStylee>
            <SearchBarWidget/>
            <div className="refresh">Refresh</div>
            <AllCollectionTable />
        </MainStylee>
    )
}



const MainStylee = styled.div`
  ${() => WidthStyle.MinWidth};
  max-width: 95%;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 40px 0;
  box-sizing: border-box;

  .refresh {
    display: inline-block;
    padding: 10px 20px;
    box-sizing: border-box;
    background: #242424;
    border: 1px solid #D9D9D9;
    border-radius: 12px;
    color: #FFFFFF;
    cursor: pointer;
    margin-bottom: 20px;
  }
`
