import {useCallback, useState} from "react";


export const useWait = (backFn: Function) => {
    const [status, setStatus] = useState<HttpStatus>(HttpStatus.start);
    const [data, setData] = useState<any>();

    const request = useCallback(async (ev: any): Promise<any> => {
        try {
            setStatus(HttpStatus.wait);
            let data = await backFn(ev);
            setData(data);
            setStatus(HttpStatus.success);
            return data;
        } catch (e) {
            setData({err: e});
            setStatus(HttpStatus.failure);

        }


    }, [backFn]);

    return {
        status,
        request,
        data,
        setStatus
    };
}


export enum HttpStatus {
    start,
    wait,
    success,
    failure
}
