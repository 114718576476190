import React from "react";
import styled from "styled-components";

type PoolBalanceProps = {
    label: string,
    value: string,
    img?: string
}
export const PoolBalanceItem: React.FC<PoolBalanceProps> = ({label, value, img}) => {
    return (
        <PoolBalanceStyle>
            <h3>{label}</h3>

            <h4>
                {value}
                {img && <img src={img} alt=""/>}
            </h4>
        </PoolBalanceStyle>
    );
}

const PoolBalanceStyle = styled.div`
  background: #242424;
  border-radius: 10px;
  padding: 7px 10px;
  box-sizing: border-box;
  
  h3{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    align-items: center;
    letter-spacing: -0.18em;
    color: #8D909A;
  }
  
  h4 {
    display: flex;
    align-items: center;
    justify-content: end;
    font-style: normal;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.18em;
    color: #FFFFFF;
    font-weight: 500;
    height: 20px;
    
    img{
      display: block;
      width: 20px;
      margin-left: 10px;
    }

  }
`

