import log from "../assets/images/question-mark.svg"
export const CollectionsData: any = {
    "collections": [
        {
            "_id": "62eebb196f80b9ea2e644bdb1",
            "address": "0xf5de760f2e916647fd766b4ad9e85ff943ce3a2b",
            "name": "MFNFT",
            "symbol": "MFNFT",
            "isVerified": true,
            "images": {
                "image_url": "https://ipfs.io/ipfs/bafybeifvwitulq6elvka2hoqhwixfhgb42l4aiukmtrw335osetikviuuu",
                "banner_image_url": "https://i.seadn.io/gcs/files/4d606c8e4b86886a56a0f71e61100639.png?w=500&auto=format"
            },
            "buy_quote": 262000000000000030,
            "sell_quote": 256284190207454820,
            "pool_count": 44,
            "item_count": 1036,
            "offer_tvl": 17738530635072830000,
            "ethOfferTVL": "0",
            "ethVolume": "0",
            "analytics": {
                "volume_24_hour": 0,
                "volume_all_time": 2071.1323276012336
            }
        },
        {
            "ethOfferTVL": "0",
            "ethVolume": "0",
            "_id": "62eebb196f80b9ea2e644bdb1",
            "address": "0x37c25b308c6c67b396cb42a91b2fce80ffd0a735",
            "name": "Root Swap",
            "symbol": "RST",
            "isVerified": true,
            "images": {
                "image_url": log,
                "banner_image_url": log
            },
            "buy_quote": 0,
            "sell_quote": 0,
            "pool_count": 0,
            "item_count": 0,
            "offer_tvl": 0,
            "analytics": {
                "volume_24_hour": 0,
                "volume_all_time": 0
            }
        }
    ]
}
