import React, {useContext, useEffect, useMemo} from "react";
import styled from "styled-components";
import {HeaderWidget} from "./widget/header";
import {NavLink, Route, Switch, useParams} from "react-router-dom";
import {WidthStyle} from "../../widget/style/width_style";
import {Grid, PropTypes} from "@material-ui/core";
import {NftActivityPage} from "./activity/nft_activity";
import {NftPool} from "./pool/nft_pool";
import {CollectionContext, useCollection} from "../../provider/collection_provicer";
import {usePairs} from "./hook/usePairs";
import {HttpStatus, useWait} from "../../utils/wait_fn";
import {HttpServer} from "../../net/http_server";
import {LoadingWidget} from "../../widget/base/loading_widget";
import {useActivity} from "./hook/useActivity";
import {useStore} from "effector-react";
import {$getMoralisFileData} from "../inventory/store";
import {MoralisData} from "../../model/moralis_data";
import {SellWidget} from "./sell";
import {useWeb3} from "../../provider/web3_provider";
import {Contract} from "web3-eth-contract";
import erc721 from "../../web3/abi/erc721";
import {ApproveButton} from "../../widget/base/approve_button";
import {getCollectionById} from "../my_pool/hook/useCollections";
import {useGetList} from "./hook/useGetList";
import {useWallet} from "use-wallet";
import {BuyWidget} from "./buy";
import {$getCollections} from "../main/store";


export const CollectionMain: React.FC = () => {

    let {id} = useParams<{ id: string, type: string }>();
    const {account} = useWallet()
    const {web3} = useWeb3()
    // const {data, request, status} = useWait(() => HttpServer.getCollectionById(id))
    const allCollections = useStore($getCollections)



    const {pairsItems, nftMeta, handleGetPairs} = usePairs({account, web3})

    useEffect(() => {
        // request('')
        handleGetPairs(id)
    }, [id])

    // if (status === HttpStatus.wait || !data) {
    //     return <LoadingWidget/>
    // }

    return (
        <CollectionContext.Provider value={{
            nftMeta,
            collections: getCollectionById(id, allCollections.collections) as any,
            sudo: pairsItems
        }}>
            <CollectionBody/>
        </CollectionContext.Provider>

    );
}

const CollectionBody: React.FC = () => {
    const {sudo, collections, nftMeta} = useCollection();
    let {id} = useParams<{ id: string, type: string }>();
    const data = useStore($getMoralisFileData)
    const listNft = useMemo(() => {
        if (!data?.result) return [];
        return data?.result?.filter((ev: MoralisData) => ev.token_address.toLowerCase() === id.toLowerCase())
    }, [data, id])
    const {buyList, bestOffer, floorPrice, ethVolume} = useGetList({sudo, nftMeta})
    let {nftEvents, nftMeta: poolNfts} = useActivity()

    const {web3, contrcts,} = useWeb3();

    const contract: Contract = useMemo(() => {
        return new web3.eth.Contract(erc721, id)
    }, [id])


    if (!collections || !sudo || !nftMeta) {
        return <div/>
    }


    return <CollectionMainStyle>
        <HeaderWidget ethVolume={ethVolume}
                      bestOffer={bestOffer}
                      floorPrice={floorPrice}
                      collections={collections}/>

        <div className="bar">
            <div className="line">

                <StyledLink to={`/collection/buy/${id}`}>Buy <span>{buyList.length}</span></StyledLink>
                <StyledLink to={`/collection/sell/${id}`}>Sell <span>{listNft.length}</span></StyledLink>
                <StyledLink to={`/collection/activity/${id}`}>Activity</StyledLink>
                <StyledLink to={`/collection/pools/${id}`}>Pools</StyledLink>
            </div>
        </div>
        <div className="list_data">
            <Switch>
                <Route path="/collection/buy/:id">
                    <Grid container spacing={4}>
                        <BuyWidget nftMeta={nftMeta} buyList={buyList} />
                        {/*{*/}
                        {/*    buyList.length === 0 && <NaData>empty</NaData>*/}
                        {/*}*/}
                        {/*{*/}
                        {/*    buyList.map((ev) => {*/}
                        {/*        let uqid = `${ev.id}_${ev.nftId}`*/}
                        {/*        let img = path<string>([uqid, 'metadata', 'image'], nftMeta)*/}
                        {/*        if (!img) {*/}
                        {/*            img = path<string>([uqid, 'tokenUri', 'gateway'], nftMeta)*/}
                        {/*        }*/}
                        {/*        let name = path<string>([uqid, 'metadata', 'name'], nftMeta)*/}
                        {/*        let averagePrice = path<string>([uqid, 'averagePrice'], nftMeta)*/}
                        {/*        return (*/}
                        {/*            <Grid item key={`${uqid}_buy_`}>*/}
                        {/*                <NftBuyItem data={{*/}
                        {/*                    imgUrl: img,*/}
                        {/*                    symbol: name,*/}
                        {/*                    id: ev.id,*/}
                        {/*                    nftId: ev.nftId,*/}
                        {/*                    averagePrice: averagePrice || '',*/}
                        {/*                    uqid,*/}
                        {/*                    delta: ev.delta,*/}
                        {/*                    spotPrice: ev.spotPrice,*/}
                        {/*                    nftIds: ev.nftIds,*/}
                        {/*                }}/>*/}
                        {/*            </Grid>*/}
                        {/*        );*/}
                        {/*    })*/}
                        {/*}*/}
                    </Grid>
                </Route>
                <Route path="/collection/sell/:id">
                    <SellWidget/>
                    <ApproveButton contract={contract} contractAddress={id} spender={contrcts.PAIR_ROUTER_A}>
                        <span></span>
                    </ApproveButton>
                </Route>

                <Route path="/collection/activity/:id">
                    <NftActivityPage nftEvents={nftEvents} nftMetas={poolNfts}/>
                </Route>
                <Route path="/collection/pools/:id" component={NftPool}/>


            </Switch>
        </div>
    </CollectionMainStyle>
}

export const NaData = styled.div`
  text-align: center;
  color: white;
`


const StyledLink = styled(NavLink)`
  text-decoration: none;
  padding: 10px 20px;
  box-sizing: border-box;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.18em;

  color: #8D909A;
  text-align: center;
  border-bottom: 2px solid transparent;


  &.active {
    color: #FFFFFF;
    border-bottom: 2px solid #FFFFFF;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 0;

    &.active {
    }
  }
`;


const CollectionMainStyle = styled.div`
  ${() => WidthStyle.MinWidth};
  padding-top: 40px;
  box-sizing: border-box;
  max-width: 100%;

  background-color: black;

  .bar {
    display: flex;
    margin: 30px 0;

    .line {
      display: flex;
      align-items: center;
      width: 65%;
      padding-left: 50px;
      margin: 0 auto;

      .sweep {
        width: 180px;
        padding-right: 30px;

        > div {
          background: #353840;
          border: 1px solid #FFFFFF;
          border-radius: 12px;
          display: flex;
          padding: 5px 10px;
          cursor: pointer;
          align-items: center;
          font-style: normal;
          font-weight: 400;
          font-size: 22px;
          line-height: 22px;
          text-align: center;
          letter-spacing: 0em;
          color: #FFFFFF;
          margin: 10px;

          img {
            display: block;
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
        }
      }

      a {
        flex: 1;
      }

      span {
        background: #242424;
        border-radius: 12px;
        margin-left: 10px;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 18px;
        align-items: center;
        text-align: center;
        letter-spacing: -0.18em;
        padding: 4px 10px;

        color: #FFFFFF;
      }
    }
  }

  .list_data {
    width: 96%;
    margin: 0 auto;
  }
`
