import {useStore} from "effector-react";
import {$getMoralisFileData} from "../../inventory/store";
import {useShoppingCart} from "../../../provider/shopping_cart_provider";
import {useEffect, useMemo, useState} from "react";
import {MoralisData} from "../../../model/moralis_data";
import {useParams} from "react-router-dom";
import {SellPoolMap, SellQty} from "../type";
import {PoolType} from "../pool/nft_pool_item";
import BigNumber from "bignumber.js";
import {compose, path, sort} from "ramda";
import {sequenceFn} from "../../../utils/sequence";
import {MyPoolData} from "../../../model/my_pool_data";
import {useCollection} from "../../../provider/collection_provicer";
import * as R from "ramda";
import {useWallet} from "use-wallet";

export const useSellList = () => {
    let {id} = useParams<{ id: string, type: string }>();
    const {sudo} = useCollection();
    const [poolMap, setPoolMap] = useState<SellPoolMap>({});

    const data = useStore($getMoralisFileData)
    const {collectionsMap, nftMateMap} = useShoppingCart()
    const {account} = useWallet();

    const listNft = useMemo(() => {
        if (!data?.result) return [];
        return data?.result?.filter(
            (ev: MoralisData) => ev.token_address.toLowerCase() === id.toLowerCase(),
        )
    }, [data, id])


    const myNfts: any = useMemo(() => {
        let buyLists = compose(
            R.partition((ev: MoralisData) => {
                let uqid = `${account}_${ev.token_id}`

                let averagePrice = path<string>([uqid, 'averagePrice'], nftMateMap)
                return averagePrice !== undefined;
            }),
        )(listNft || []);
        return [...buyLists[0], ...buyLists[1]]
    }, [nftMateMap, account, listNft])

    const poolsList = useMemo<SellQty[]>(() => {
        let pools: SellPoolMap = {}

        for (const key in poolMap) {
            let item = poolMap[key];
            let aSpotPrice = item.spotPrice;
            let aQty = collectionsMap[item.id.toLowerCase()]?.length;
            let aFee = new BigNumber(item.fee).div(1e20).toFixed()
            aFee = aFee === '0' ? '0.01' : aFee
            let aSequenceFn = sequenceFn(new BigNumber(aSpotPrice), item.delta, item.bondingCurve, aQty || 0);
            let aV = aSequenceFn.times(1 - (parseFloat(aFee) * (aQty || 0))).toString();
            item.sellPrice = aV;
            pools[key] = item
        }

        let list = Object.values(pools);

        let poolsList = sort((a: SellQty, b: SellQty) => {
            return new BigNumber(b.sellPrice).minus(a.sellPrice).toNumber()
        }, list)

        return poolsList
    }, [collectionsMap, poolMap])

    const currentPaire: MyPoolData = useMemo(() => {

        let item: SellQty = poolsList[0]
        if (!item) {
            return item
        }

        item.averagePrice = item.sellPrice;
        return item
    }, [poolMap, poolsList])

    useEffect(() => {
        const timer = setTimeout(()=>{
            if (!Object.keys(poolMap).length) {
                let pools: SellPoolMap = {}
                for (const key in sudo) {
                    let item = sudo[key];
                    let eType = parseInt(item.type)
                    if (eType === PoolType.trade || eType === PoolType.token) {
                        let isAc = new BigNumber(item.ethBalance).gt(0)
                        if (isAc) {
                            pools[key] = item
                        }
                    }
                }
                setPoolMap(pools)
            }
        },300)
        return () => clearTimeout(timer)
    }, [sudo])

    return {currentPaire, myNfts, poolsList: Object.values(poolMap)}
}
