import React, {useMemo} from "react";
import styled from "styled-components";
import Roy from "../../../assets/images/question-mark.svg"
import Jazzicon from '@raugfer/jazzicon';
import indexEth from "../../../assets/images/index_eth.png"
import {getBalanceNumber} from "../../../utils/formatBalance";
import {useShoppingCart} from "../../../provider/shopping_cart_provider";
import {useCollection} from "../../../provider/collection_provicer";
import {RootMyPoolData} from "../../../model/my_pool_data";


type NftBuyItemProps = {
    data: {
        id: string,
        uqid: string,
        nftIds: any[],
        averagePrice: string;
        nftId?: string | number,
        spotPrice?: string,
        delta?: string,
        imgUrl?: string,
        symbol?: string
    },
    poolData: RootMyPoolData,
    type?: 'max' | 'min'
}
export const NftBuyItem: React.FC<NftBuyItemProps> = ({data, type = 'max', poolData}) => {
    let {uqid} = data;

    const buildDataUrl = useMemo(() => {
        return 'data:image/svg+xml;base64,' + btoa(Jazzicon(data.id));
    }, [data])

    const {nftMeta} = useCollection();
    const {addShopping, deleteShopping, nftMateMap} = useShoppingCart()
    return (
        <NftBuyItemStyle className={`${type} ${nftMateMap[uqid] && 'ac'}`} onClick={() => {
            if (nftMateMap[uqid]) {
                deleteShopping(uqid)
                return;
            }
            addShopping({...nftMeta[uqid], averagePrice: data.averagePrice, spotPrice: data.spotPrice}, {poolData})
        }}
        >
            <div className="top">
                {
                    data.spotPrice && <img src={buildDataUrl} alt="" className="addr"/>
                }
                <img src={data.imgUrl || Roy} alt="" className="nft_img"/>
            </div>
            <div className="bottom">
                {
                    type === 'max' && <>
                        <p className={'nftId_'}>#{data.nftId}</p>
                        <p className={'nftId'}>{data.symbol}</p>
                    </>
                }

                {
                    type === 'min' && <>
                        <p className={'nftId'}>{data.symbol}</p>
                    </>
                }

                {
                    data.spotPrice && <>
                        <div className="price">
                            <h3>Price</h3>
                            <h4><img src={indexEth}
                                     alt=""/> {getBalanceNumber(data.averagePrice, 6)}
                            </h4>
                        </div>
                    </>
                }


            </div>


        </NftBuyItemStyle>
    );
}

const NftBuyItemStyle = styled.div`
  background: #353840;
  width: 180px;

  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid transparent;

  &.ac {
    border: 1px solid #fff;
  }

  &:hover {
    background-color: #5A5A64;
  }

  &.min {
    border-radius: 10px;

    .bottom {
      padding: 5px;

      .nftId {
        font-size: 12px;
        overflow: hidden; //超出的文本隐藏
        text-overflow: ellipsis; //溢出用省略号显示
        white-space: nowrap; //溢出不换行
      }

      .nftId_ {
        font-size: 8px;
        color: #A0A0AA;
        overflow: hidden; //超出的文本隐藏
        text-overflow: ellipsis; //溢出用省略号显示
        white-space: nowrap; //溢出不换行
      }

      .price {
        margin: 0;

        h3 {
          font-size: 8px;
        }

        h4 {
          font-size: 12px;

          img {
            width: 10px;
          }
        }
      }

    }
  }

  .bottom {
    padding: 5px 10px;
    box-sizing: border-box;

    .price {
      .lastSale {
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 11px;
        margin-top: 10px;
        letter-spacing: -0.14em;
        color: #A0A0AA;

        h4 {
          display: flex;
          align-items: center;
          color: #A0A0AA;
          font-size: 11px;

          img {
            display: block;
            width: 10px;
          }
        }
      }

    }

    .price {
      margin: 5px 0 0px 0;

      h3 {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        align-items: center;
        letter-spacing: -0.14em;
        color: #FFFFFF;
        margin-bottom: 5px;
      }

      h4 {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 20px;
        /* identical to box height */

        display: flex;
        align-items: center;
        letter-spacing: -0.14em;
        color: #FFFFFF;

        img {
          display: block;
          width: 20px;
          margin-right: 5px;
        }
      }
    }

    .nftId {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      display: flex;
      align-items: center;
      letter-spacing: -0.1em;

      color: #FFFFFF;
    }

    .nftId_ {
      font-size: 8px;
      color: #A0A0AA;
      overflow: hidden; //超出的文本隐藏
      text-overflow: ellipsis; //溢出用省略号显示
      white-space: nowrap; //溢出不换行
    }
  }

  .top {
    position: relative;

    .addr {
      display: block;
      width: 20px;
      border-radius: 40px;
      position: absolute;
      top: 10px;
      left: 10px;
    }

    .nft_img {
      display: block;
      width: 180px;
      height: 160px;
      margin: 0 auto;

    }
  }

`
