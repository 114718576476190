import React from "react";
import styled from "styled-components";
import {SelectInput} from "./widget/select_input";
import {StepBtnWidget} from "./widget/step_btn";
import ethIcon from "../../assets/images/pool_eth.png"
import {useCreaterDataPool} from "../../provider/creater_lp_provider";
import {PoolType} from "../collection/pool/nft_pool_item";
import {StepEnum} from "./type";

export const SelectToken: React.FC = () => {
    const {poolType, moralisData} = useCreaterDataPool()

    let [type, _] = poolType;
    const [moralis] = moralisData;

    return (
        <SelectTypeStyle>
            <div className={'ctx'}>
                <h4>I want to</h4>
                <h4>deposit {type === PoolType.token ? <EthItemWidget/> : <SelectInput/>}</h4>
                <h4>and</h4>
                <h4>receive {type === PoolType.token ? <SelectInput/> : <EthItemWidget/>}</h4>
            </div>

            <div className={'footer'}>
                <StepBtnWidget text={"Previos Step"} page={0}/>
                <StepBtnWidget disabled={!moralis.token_address}
                               page={type !== PoolType.token ? StepEnum.SelectNft : StepEnum.CreaterPool}/>
            </div>
        </SelectTypeStyle>
    );
}

const EthItemWidget: React.FC = () => {

    return <EthItemStyle>
        <img src={ethIcon} alt=""/>
        ETH
    </EthItemStyle>
}
const EthItemStyle = styled.div`
  background: #2C2F36;
  border-radius: 100px;
  align-items: center;
  padding: 10px 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.05em;
  margin-left: 20px;
  color: #fff;
  display: flex;
  cursor: pointer;

  img {
    display: block;
    width: 20px;
    margin-right: 10px;
  }



`


const SelectTypeStyle = styled.div`

  padding: 20px 20px 0 20px;
  box-sizing: border-box;
  text-align: center;

  .ctx {
    display: inline-block;

    h4 {
      margin: 10px auto;
      height: 40px;
      font-weight: 400;
      font-size: 28px;
      line-height: 28px;
      display: flex;
      align-items: center;
      letter-spacing: -0.05em;
      color: #52565F;
    }
  }

  .footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
  }

`

