import styled from "styled-components";
import React, {useCallback, useState} from "react";

import {BalanceInput} from "./balance_input";
import {PoolType} from "../../collection/pool/nft_pool_item";
import {useUpDataPool} from "../../../provider/updata_pool_provicer";
import {UpdataLoadingBtn} from "./loading_btn";
import {HttpStatus} from "../../../utils/wait_fn";


export const NFTBalanceInput: React.FC = ({children}) => {
    const {sudo: sudoData, collections, nfts} = useUpDataPool()



    if (!collections || !sudoData) {
        return <div/>
    }
    return (
        <BalanceWidget>
            <BalanceInput
                label={'NFT'}
                name={'balance'}
                placeholder={`${nfts.length}`}
                unit={`${collections.symbol || collections.name}`}
                imgUrl={collections.images.image_url}
                disabledText={parseInt(sudoData.type) === PoolType.token ? ["This pool has no NFT.", ""] : undefined}
            >
                {children}
            </BalanceInput>
        </BalanceWidget>
    );
}


const BalanceWidget = styled.div`
  margin-top: 10px;
  .deposit{
    font-size: 12px;
  }
`
