import React, {createContext, useContext} from "react";

import {useWallet} from "use-wallet";
import {useWeb3} from "./web3_provider";
import {CollectionsDataModel} from "../model/collections_data";
import {NftMateMap, PairsMap} from "../view/collection/type";
import {useAllPairs} from "../hook/useAllPairs";


export const CollectionsProvider: React.FC = ({children}) => {
    const {account} = useWallet()
    const {web3} = useWeb3()
    const {collections} = useAllPairs({account, web3})
    return (
        <CollectionsContext.Provider value={{
            collections: collections,
        }}>
            {children}

        </CollectionsContext.Provider>
    );
}

export const CollectionsContext = createContext<Collections>({
    collections: {},
});

export interface Collections {
    collections: { [key: string]: CollectionInterface },
}

export interface CollectionInterface {
    collections: CollectionsDataModel | undefined,
    sudo: PairsMap,
    nftMeta: NftMateMap,
}


export const useCollections = (): Collections => {
    const {collections} = useContext(CollectionsContext);
    return {
        collections
    }
}

