import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {CollectionsDataModel} from "../model/collections_data";
import {NftMateMap, PairsMap} from "../view/collection/type";

export const CollectionContext = createContext<CollectionInterface>({
    nftMeta: {},
    collections: undefined,
    sudo: {}
})

interface CollectionInterface {
    collections: CollectionsDataModel | undefined,
    sudo: PairsMap,
    nftMeta: NftMateMap,
}


export const useCollection = (): CollectionInterface => {
    const {sudo, collections, nftMeta} = useContext(CollectionContext);
    return {
        sudo, collections, nftMeta
    }
}





