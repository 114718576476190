import React from "react";
import styled from "styled-components";
import Jazzicon from '@raugfer/jazzicon';
import {path} from "ramda";
import {Grid} from "@material-ui/core";
import {NftMetadata} from "../../../model/nft_metadata";
import {FormatAddr} from "../../../utils/format_addr";
import {ActivityItem} from "./activity_item";
import indexEth from "../../../assets/images/index_eth.png"
import {NftEventMap, NftMateMap} from "../type";

type NftActivityProps = {
    nftEvents: NftEventMap,
    nftMetas: NftMateMap
}

let colors: { [key: string]: string } = {
    'SwapTokenForSpecificNFTs': "#E0446A",
    'SwapNFTsForToken': "#4FCFA9",
}
export const NftActivityPage: React.FC<NftActivityProps> = ({nftEvents, nftMetas}) => {
// ${colors[ev.eventType]}
    return (
        <WapStyle>
            {
                Object.values(nftEvents).map((ev) => {
                    let item: NftMetadata = nftMetas[ev.tokenId]
                    let imgBase64 = 'data:image/svg+xml;base64,' + btoa(Jazzicon(ev.maker));
                    return <ItemStyle color={colors[ev.eventType]} className={`item_wap`} key={`${ev.tokenId}_lll`}>
                        <Grid container spacing={2}>
                            <Grid item xs={4} md={2}>
                                <div className="msg">
                                    <h3 className={'title'}>
                                        <img src={imgBase64} alt=""/>
                                        <p>#{FormatAddr(ev.maker, 6)}</p>
                                    </h3>
                                    <h4>Sells <span>{ev.numberOfTokens}</span> {path(['metadata', 'name'], item)}</h4>
                                    <h5>Total received <span> <img src={indexEth} alt=""/>7.3</span></h5>
                                    <h6>Avg Price: <span>7.4 per</span></h6>
                                </div>
                            </Grid>
                            <ActivityItem data={{
                                symbol: ev.tokenId,
                                imgUrl: path(['metadata', 'image'], item),
                            }}/>

                        </Grid>
                    </ItemStyle>;
                })
            }
        </WapStyle>
    );
}

const ItemStyle = styled.div<{ color: string }>`
    .msg{
      .title{
        p {
          color: ${({color})=> color};
        }
      }
      span {
        color: ${({color})=> color};
      }
    }
`

const WapStyle = styled.div`
  .item_wap {
    background: #242424;
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 20px;
    box-sizing: border-box;

    .msg {
      .title {
        display: flex;
        align-items: center;
        img {
          display: block;
          width: 20px;
          border-radius: 100%;
          margin-right: 10px;
        }

        p {
          font-style: normal;
          font-size: 14px;
          letter-spacing: -0.18em;
          text-decoration-line: underline;
        }
      }

     

      h4 {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        display: flex;
        align-items: center;
        letter-spacing: -0.18em;
        color: #FFFFFF;
        margin: 10px 0;

        span {
          display: block;
          margin: 0 5px;
        }
      }

      h5 {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        display: flex;
        align-items: center;
        letter-spacing: -0.18em;
        color: #FFFFFF;


        span {
          display: flex;
          margin-left: 10px;
          align-items: center;

          img {
            display: block;
            width: 20px;
            margin-right: 5px;
          }
        }
      }

      h6 {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        margin-top: 20px;
        /* identical to box height */

        display: flex;
        align-items: center;
        letter-spacing: -0.14em;

        color: #8D909A;

      }
    }

  }
`



