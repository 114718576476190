import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import {EnsNameWidget} from "./widget/ens_name";
import {WidthStyle} from "../../widget/style/width_style";
import {Grid, Switch, TextField} from "@material-ui/core";
import {MoralisData} from "../../model/moralis_data";
import {NftCardItem} from "./widget/nft_card_item";
import * as R from "ramda";
import Roy from "../../assets/images/question-mark.svg";
import InventorySelectAc from "../../assets/images/inventory_select_ac.png"
import InventorySelectUn from "../../assets/images/inventory_select_un.png";

import {LoadingWidget} from "../../widget/base/loading_widget";
import {ModalContext} from "../../provider/model_provider";
import {SellWidget} from "./sell";
import {useStore} from "effector-react";
import {$getMoralisFileData} from "./store";
import {getMoralisFileData} from "./store/effects";
import {FullNaData} from "../../widget/base/na_data";
import {anyPass, filter, propSatisfies} from "ramda";
import {useSellOffer} from "./hook/use_sell_offer";

export const InventoryMain: React.FC = () => {
    const data = useStore($getMoralisFileData)
    const loading = useStore(getMoralisFileData.pending)
    const [searchText, setSearch] = useState("");
    const [group, setGroup] = useState<any>({});
    const {sellOffer} = useSellOffer()


    const [result, setResult] = useState(data?.result || []);
    const [checkeds, setChecked] = useState<MoralisData[]>([]);
    const [isShowEmpty, setIsShowEmpty] = useState(true)


    useEffect(() => {
        if (!result?.length) {
            setResult(data?.result)
        }
    }, [data])

    let [hideKeys, setHideKeys] = useState<any[]>([])

    const showKeys = useMemo(() => {
        let a = hideKeys.map((ev: MoralisData) => {
            if (ev.isChecked) {
                return ev.token_address
            }
        })
        return a;
    }, [hideKeys])

    const handelGroup = useCallback(() => {
        if (!data || !data.result) {
            return []
        }
        let group = R.compose(
            R.values,
            R.groupBy((student: MoralisData) => {
                return student.token_address;
            }),
            R.path(['result'])
        )(data);
        let _keys = R.map(R.head)(group);

        if (searchText) {
            let _searchText = searchText.toLowerCase();

            _keys = filter(
                anyPass([
                    propSatisfies(
                        (name: string) => {
                            return name.toLowerCase().indexOf(_searchText) > -1;
                        },
                        'name'
                    ),
                    propSatisfies(
                        (name: string) => {
                            return name.toLowerCase().indexOf(_searchText) > -1;
                        },
                        'symbol'
                    ),
                    propSatisfies(
                        (address: string) => {
                            return address.toLowerCase().indexOf(_searchText) > -1;
                        },
                        'token_address'
                    ),
                ]),
                _keys || []
            );

        }
        setHideKeys(_keys.map((ev: any) => ({...ev})))
        setGroup(group)
    }, [data, searchText])


    const checkedMoralis = useCallback(() => {
        let _data = R.compose(
            R.filter((student: MoralisData) => {
                return student.isChecked;
            }),
            R.path(['result'])
        )(data);
        setChecked(_data)
    }, [data])


    const {onPresent} = useContext(ModalContext);

    useEffect(() => {
        let itemFn = setTimeout(handelGroup, 500)
        return () => clearTimeout(itemFn)
    }, [searchText])

    if (loading || !data.result) {
        return <LoadingWidget/>;
    }

    if (!data?.result.length) {
        return <FullNaData>empty</FullNaData>;
    }
    return (
        <InventoryStyle>
            <EnsNameWidget/>
            <div className="body">

                <LeftItemStyle>
                    <h4>Only Show Offers <Switch
                        color="primary"
                        value={isShowEmpty}
                        defaultChecked={true}
                        onChange={() => {
                            setIsShowEmpty(!isShowEmpty)
                        }}
                        inputProps={{'aria-label': 'checkbox with default color'}}/></h4>

                    <div className="input">
                        <TextField
                            unselectable={'off'}
                            fullWidth={true}
                            value={searchText}
                            onChange={(ev) => setSearch(ev.target.value)}
                        />
                    </div>

                    <div className="nftName">
                        {
                            hideKeys.map((item: MoralisData, index: number) => {

                                return <div key={`11_${item.token_address}`} className={'name'} onClick={() => {
                                    hideKeys[index].isChecked = !hideKeys[index].isChecked
                                    setHideKeys([...hideKeys])
                                }}>
                                    <img src={item.isChecked ? InventorySelectUn : InventorySelectAc} alt=""/>
                                    <p>
                                        {item.symbol}
                                    </p>
                                    <span>{
                                        group?.[index]?.length
                                    }</span>

                                </div>;
                            })
                        }
                    </div>
                </LeftItemStyle>

                <Grid className={'nft_wap'} container spacing={2}>
                    {
                        (result || []).map((ev: MoralisData, index: number) => {
                            if (showKeys.indexOf(ev.token_address) !== -1) {
                                return <span/>
                            }

                            let metadata = JSON.parse(ev.metadata)
                            if (!metadata) {
                                metadata = {
                                    name: ev.name,
                                    image: Roy
                                }
                            }
                            let poolData = sellOffer[ev.token_address];

                            if(isShowEmpty && !poolData){
                                return  <span />
                            }

                            return <Grid item key={`${ev.name}_${ev.token_id}`}>
                                <NftCardItem
                                    data={{
                                        id: ev.token_address,
                                        imgUrl: metadata.image || metadata.image_url,
                                        nftId: ev.token_id,
                                        nftIds: [],
                                        symbol: metadata.name || ev.name,
                                        isSelect: ev.isChecked,
                                        hidePrice: poolData !== undefined,
                                        spotPrice: poolData?.sellPrice,

                                    }}
                                    onCb={(select: boolean) => {
                                        result[index]['isChecked'] = select;
                                        checkedMoralis()
                                    }}
                                />
                            </Grid>
                        })
                    }
                </Grid>

            </div>

            <div className="footer">
                <div className="your" onClick={() => {

                    let _result = result.map((ev: any) => {
                        ev.isChecked = false;
                        return ev
                    })

                    setResult(_result)
                }}>
                    Deselect All
                </div>
                <div className="list" onClick={() => {
                    if (!checkeds.length) return;

                    let _group = R.compose(
                        R.groupBy((student: MoralisData) => {
                            return student.token_address;
                        }),
                    )(checkeds)
                    onPresent(<SellWidget nfts={checkeds} group={_group}/>)
                }}>
                    List {checkeds.length} NTFS
                </div>
            </div>
        </InventoryStyle>
    );
}


const LeftItemStyle = styled.div`
  width: 20%;


  .name {
    padding: 13px 10px;
    background: #242424;
    cursor: pointer;
    border-radius: 8px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      display: block;
      width: 14px;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      display: flex;
      align-items: center;
      letter-spacing: -0.14em;
      color: #FFFFFF;
      flex: 1;
      padding: 0 10px;
    }

    span {
      display: inline-block;
      padding: 4px 5px;
      border-radius: 5px;
      color: white;
      background-color: #000;
    }
  }


  .input {
    margin-top: 20px;
    background: #080808;
    border: 1px solid #8D909A;
    border-radius: 10px;
    padding: 4px 10px;
    box-sizing: border-box;
  }

  .nftName {
    height: 300px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .MuiInputBase-root {
    color: white;
  }

  .MuiInput-underline:before, .MuiInput-underline:after {
    display: none;
  }

  .MuiInputBase-input {
    font-family: 'ZX', sans-serif;

    &::-webkit-input-placeholder {
      text-align: start;
    }
  }

  h4 {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: -0.14em;
    color: #8D909A;
  }
`


const InventoryStyle = styled.div`
  ${() => WidthStyle.MinWidth};
  padding-top: 40px;
  box-sizing: border-box;
  max-width: 100%;

  .footer {
    padding: 10px 0;
    display: flex;
    width: 100%;
    position: fixed;
    bottom: 0;
    background-color: #141414;
    border-top: 2px solid #333;
    justify-content: center;

    div {
      padding: 10px 20px;
      box-sizing: border-box;
      color: #fff;
      border-radius: 12px;
      cursor: pointer;
    }

    .your {
      background: #242424;
      border: 1px solid #D9D9D9;
    }

    .list {
      background: #FFFFFF;
      border: 2px solid #FFFFFF;
      color: #000;
      margin-left: 40px;

    }
  }
}

.body {
  padding-bottom: 40px;

  .nft_wap {
    width: 80%;
    background: #101010;
    border: 1px solid #2C2F36;
    border-radius: 20px;
    padding: 10px;
    box-sizing: border-box;
  }


  max-width: 96%;
  margin: 0 auto;
  justify-content: space-between;
  padding-top: 30px;
  display: flex;


}

`
