import styled from "styled-components";
import React, {useContext} from "react";
import {PoolItemTitle} from "../widget/pool_item_title";
import {SudoMetaData} from "../../../model/sudo_meta_data";
import nftImg from "../../../assets/images/null_nft_icon.png"
import indexEthImg from "../../../assets/images/index_eth.png"
import {PoolBalanceItem} from "../widget/pool_balance_item";
import {Grid} from "@material-ui/core";
import {getBalanceFormat} from "../../../utils/formatBalance";
import {ModalContext} from "../../../provider/model_provider";
import {NftPoolModel} from "../../updata_pool";
import {NftPoolItemHeader} from "../widget/nft_pool_item_header";
import {CollectionsDataModel} from "../../../model/collections_data";
import {RootMyPoolData} from "../../../model/my_pool_data";
import {BondingList, BondingText} from "../../my_pool/hook/usePool";
import {useBalance} from "../hook/useBalance";
import {useParams} from "react-router-dom";
import {CURVE} from "../../../web3/token.config";


export enum PoolType {
    token,
    nft,
    trade
}

type NftPoolItemProps = {
    type: PoolType,
    sudoData: RootMyPoolData,
    collections: CollectionsDataModel,
    isShowEmpty: boolean
}

export const NftPoolItem: React.FC<{ props: NftPoolItemProps }> = ({props}) => {
    const {
        sudoData: {id, collection: {id: nftAddress}, nftIds, ethVolume, owner, delta, spotPrice, bondingCurve},
        collections,
        isShowEmpty
    } = props;

    const {onPresent} = useContext(ModalContext);

    let {name, symbol, images: {image_url}} = collections

    let {balance, nfts} = useBalance({address: id, type: props.type, nftAddress: nftAddress})

    if (isShowEmpty && (nftIds.length === 0 && balance == '0.0000')) {
        return <div/>
    }

    return (
        <Grid item md={4} xs={12} xl={4} sm={6}>
            <NftPoolItemStyle onClick={() => {
                onPresent(<NftPoolModel balance={balance} nfts={nfts} type={props.type} collections={collections}
                                        sudo={props.sudoData}/>);
            }}>
                <PoolItemTitle type={props.type}/>
                <NftPoolItemHeader id={id} name={name} image_url={image_url} ownerId={owner.id}/>
                <div style={{height: "10px"}}/>

                {
                    props.type === PoolType.token && <>
                        <PoolBalanceItem label={'Balance:'} value={balance} img={indexEthImg}/>
                    </>
                }
                {
                    props.type === PoolType.nft && <>
                        <PoolBalanceItem label={'Balance:'} value={`${nftIds.length} ${symbol}`}
                                         img={image_url || nftImg}/>
                    </>
                }

                {
                    props.type !== PoolType.trade && <div style={{height: "10px"}}/>
                }

                <Grid container spacing={1}>
                    {
                        props.type === PoolType.trade && <>
                            <Grid xs={6} sm={6} md={6} item>
                                <PoolBalanceItem label={'Balance:'} value={balance} img={indexEthImg}/>
                            </Grid>
                            <Grid xs={6} sm={6} md={6} item>
                                <PoolBalanceItem label={'Balance:'} value={`${nftIds.length} ${symbol}`}
                                                 img={image_url || nftImg}/>
                            </Grid>
                        </>
                    }

                    <Grid xs={6} sm={6} md={6} item>
                        <PoolBalanceItem label={'Current price:'} value={getBalanceFormat(spotPrice, 4)}
                                         img={indexEthImg}/>
                    </Grid>
                    <Grid xs={6} sm={6} md={6} item>
                        <PoolBalanceItem label={'Bonding Curve:'} value={BondingText[bondingCurve]}/>
                    </Grid>
                    <Grid xs={6} sm={6} md={6} item>
                        <PoolBalanceItem label={'Delta:'}
                                         value={`${getBalanceFormat(delta, BondingText[bondingCurve] === "linear" ? 4 : 2)} ${BondingText[bondingCurve] === "linear" ? "ETH" : "%"}`}/>
                    </Grid>
                    <Grid xs={6} sm={6} md={6} item>
                        <PoolBalanceItem label={'Volume:'} value={getBalanceFormat(ethVolume, 2)} img={indexEthImg}/>
                    </Grid>
                </Grid>
            </NftPoolItemStyle> </Grid>
    );
}


const NftPoolItemStyle = styled.div`
  padding: 15px;
  border-radius: 20px;
  border: 1px solid #2C2F36;
  cursor: pointer;
`
