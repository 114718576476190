import {HttpStatus} from "../../utils/wait_fn";
import React, {useEffect, useState} from "react";
import {CircularProgress} from "@material-ui/core";
import styled from "styled-components";
import {useModel} from "../../hook/use_model";
import successImg from '../../assets/images/send_success.png'
import errImg from '../../assets/images/send_err.png'
import {UrlHaxFormat} from "../../utils/url";
import {useWallet} from "use-wallet";

type TransactionModalProps = {
    txHash: any, status: HttpStatus, onDismiss?: any,
    startDate: number
}
export const TransactionModal: React.FC<TransactionModalProps> = ({
                                                                      txHash,
                                                                      children,
                                                                      onDismiss,
                                                                      status,
                                                                      startDate
                                                                  }) => {

    let seconds = (new Date().valueOf() - startDate) / 1e3

    const [widget, setWidget] = useState<any>(children);

    useEffect(() => {
        if (status == HttpStatus.failure) {
            if (txHash.err.code && txHash.err.code === 4001) {
                onDismiss()
            }
            setWidget(<ErrWidget msg={txHash.err.message}/>)
        }
        if (status == HttpStatus.wait) {
            setWidget(<LoadingWidget/>)
        }
        if (status == HttpStatus.success) {
            setWidget(<SuccessWidget seconds={seconds} hash={txHash}/>)
        }
    }, [status])
    return (
        <div>
            {widget}
        </div>
    );
}


const LoadingWidget: React.FC = () => {
    return (
        <WapStyle>
            <span> </span>
            <CircularProgress size={100} color="primary"/>
            <LoadingStyle>
                <h2>Waiting For Confirmation</h2>
                <h4>Confirm this transaction in your wallet</h4>
            </LoadingStyle>
        </WapStyle>
    );
}


const ErrWidget: React.FC<{ msg: string }> = ({msg}) => {
    const [onDismisss, onDismiss] = useModel(<div/>);

    return (
        <WapStyle>
            <span> </span>

            <img className={'err'} src={errImg} alt=""/>

            <LoadingStyle>
                <h2>Error</h2>

                {/*<a href={UrlHaxFormat(msg, 'tx')} target="_blank">View on Etherscan</a>*/}

                <h3>{msg}</h3>

                <div style={{marginTop: "30px"}} className={'ButtonWidget'} onClick={onDismiss}>Close</div>

            </LoadingStyle>
        </WapStyle>
    );
}


const SuccessWidget: React.FC<{ hash: string, seconds: number }> = ({hash, seconds}) => {
    const [_, onDismiss] = useModel(<div/>);
    const {chainId} = useWallet()
    return (
        <WapStyle>
            <span> </span>

            <img className={'success'} src={successImg} alt=""/>

            <LoadingStyle>
                <h2>Success</h2>

                <a href={UrlHaxFormat(hash, 'tx', chainId )} target="_blank">View on Etherscan</a>

                <h3>Transaction completed in <span>{seconds}</span> seconds 🎉</h3>

                <div className={'ButtonWidget'} onClick={onDismiss}>Close</div>

            </LoadingStyle>
        </WapStyle>
    );
}


const LoadingStyle = styled.div`
  text-align: center;
  margin-top: 30px;
  width: 100%;

  h2 {
    font-size: 18px;
    font-weight: 600;
    color: #FEFEFE;
  }

  h3 {
    span {
      color: #D9D9D9
    }
  }


  h3, a {
    font-size: 14px;
    font-weight: 400;
    color: #B4B7C6;
    padding: 10px 0;
    display: block;
  }

  a {
    display: block;
    color: #FFFFFF;
  }


  .ButtonWidget {
    width: 80%;
    cursor: pointer;
    margin: 0 auto;
    height: 50px;
    background: #52565F;
    border-radius: 30px;
    font-size: 24px;
    font-weight: 400;
    color: #fff;
    line-height: 50px;
    opacity: initial;

  }
`

const WapStyle = styled.div`
  width: 100vw;
  margin: 0 auto;
  max-width: 430px;
  background: #1f1b1f;
  border-radius: 16px;
  border: 1px solid #2B2828;
  height: 375px;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 20px;
  box-sizing: border-box;
  justify-content: center;

  .loading {
    display: block;
    width: 50%;
    margin-top: -40px;
  }

  .err {
    width: 134px;
    display: block;
    margin-top: -40px;
  }

  .success {
    display: block;
    width: 120px;
  }

  .MuiCircularProgress-colorPrimary {
    color: rgba(0, 117, 255, 1);
  }
`;
