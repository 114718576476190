export const white = '#FFF';




export const blacks = {
    100: '#111111',
    200: 'rgba(10,70,40,.5)',
    300: '#0A0C46',

};

export const whites = {
    100: '#f0f0f0',
    200:"#BBBBBB",
    300:'#fbf2f1',
    400:'#999999',
    500:"#eeeeee"
};

export const oranges = {
    100: '#FE9000',
};
export const reds = {
    100: '#ff0e18',
};
export const greens = {
    100: '#4FCBFA',
    200: '#7ddd7b',
};

export const blues = {
    100:'#06A2D0',
    200:"#70adf0",
    300:"#12C4EA",
    400:"#1482E4",
    500: '#2A1D69',
    600: '#696190'

}



