import React, {useMemo} from "react";
import styled from "styled-components";
import {Grid} from "@material-ui/core";
import {useSendModal} from "../../../widget/modal/send_modal";
import {NftPoolProps} from "../index";
import {LoadingBtn} from "../../../compones/loading_btn";
import {useStore} from "effector-react";
import {$getMoralisFileData} from "../../inventory/store";
import {MoralisData} from "../../../model/moralis_data";
import Roy from "../../../assets/images/question-mark.svg";
import {NftCardItem} from "../../inventory/widget/nft_card_item";
import {useDepositNFTs} from "../../../server/factory";
import {ApproveButton} from "../../../widget/base/approve_button";
import {useCreaterDataPool} from "../../../provider/creater_lp_provider";
import {useWeb3} from "../../../provider/web3_provider";
import {Contract} from "web3-eth-contract";
import erc721 from "../../../web3/abi/erc721";

type NftSelectModelProps = {
    onDismiss: Function
} & NftPoolProps
export const NftSelectModel: React.FC<NftSelectModelProps> = ({sudo}) => {

    const handleDepositNFTs = useDepositNFTs(sudo?.id);
    const {status, onSend} = useSendModal(handleDepositNFTs)
    const {web3, contrcts,} = useWeb3();
    const contract: Contract = useMemo(() => {
        return new web3.eth.Contract(erc721, sudo.collection.id)
    }, [sudo.collection])
    const data = useStore($getMoralisFileData)
    const listNft = useMemo(() => {
        if (!data?.result) {
            return []
        }
        return data.result.filter((ev: MoralisData) => ev.token_address === sudo.collection.id);
    }, [data])
    return (
        <WapStyle>
            <div className="model_title">Deposit NFT</div>
            <CellBottomStyle container>
                <Grid className={'nft_wap'} container spacing={2}>

                    {
                        listNft.map((ev: MoralisData, index: number) => {
                            let metadata = JSON.parse(ev.metadata)
                            if (!metadata) {
                                metadata = {
                                    name: ev.name,
                                    image: Roy
                                }
                            }
                            return <Grid item xs={4} md={3} key={`${ev.name}_${ev.token_id}`}>
                                <NftCardItem
                                    data={{
                                        id: ev.token_address,
                                        imgUrl: metadata.image || metadata.image_url,
                                        nftId: ev.token_id,
                                        nftIds: [],
                                        symbol: metadata.name || ev.name,
                                        hidePrice: true
                                    }}
                                    onCb={(select: boolean) => {
                                        data.result[index]['isChecked'] = select;
                                    }}
                                />
                            </Grid>
                        })
                    }
                </Grid>

            </CellBottomStyle>

            <div className={'bottom'}>
                <ApproveButton
                    contractAddress={sudo.collection.id}
                    contract={contract}
                    spender={contrcts.FACTORY_A}

                >
                    <LoadingBtn isLoading={status} onClick={() => {
                        let ids = data.result.filter((ev: any) => ev.isChecked).map((ev: any) => ev.token_id);
                        onSend({
                            nftAddress: sudo.collection.id,
                            ids
                        })
                    }}>
                        <h4>Deposit NFT</h4>
                    </LoadingBtn>
                </ApproveButton>

            </div>
        </WapStyle>
    )
}

const NftItemStyle = styled.div`
  display: flex;
  padding: 10px 10px;
  background: #52565F;
  border: 1px solid #323841;
  margin-bottom: 20px;
  border-radius: 10px;
  cursor: pointer;

  .img {
    margin-right: 10px;

    img {
      display: block;
      width: 30px;
      height: 30px;
    }
  }

  div {
    h2 {
      font-family: 'Skinny';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 18px;
      color: #FFFFFF;
      letter-spacing: 0;
    }

    h4 {
      font-family: 'Skinny';
      font-style: normal;
      font-weight: 400;
      line-height: 15px;
      font-size: 15px;
      color: #333;
      letter-spacing: 0;
    }
  }

`


const WapStyle = styled.div`

  width: 70%;
  margin: 0 auto;
  background-color: #191b1f;
  border-radius: 20px;
  border: 1px solid #2C2F36;
  padding: 10px 20px;
  box-sizing: border-box;

  .bottom {
    display: flex;
    justify-content: center;

    > div {
      width: 50%;
    }
  }

  .model_title {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    color: #D9D9D9;
    border: 1px solid #191B1F;
  }
`


export const CellBottomStyle = styled(Grid)`
  padding: 10px;
  box-sizing: border-box;
  margin-top: 8px;
  border: 1px solid #52565F;
  border-radius: 10px;

  .pt {
    box-sizing: border-box;
    cursor: pointer;
    width: 100%;

    .btn_max {
      background-color: #2d2f35;
      padding: 4px 10px;
      border-radius: 100px;
      font-size: 18px;
      font-weight: 400;
      color: #FFFFFF;
      cursor: pointer;
    }

    .bottom_msg {
      font-size: 14px;
      font-weight: 400;
      color: #FFFFFF;
      margin-bottom: 20px;
    }

    .ethImg {
      display: block;
      width: 20px;
    }
  }

  .tis {
    font-size: 16px;
    font-weight: 500;
    color: #BABAC8;
    padding: 30px;
    box-sizing: border-box;
    line-height: 20px;

    p {
      margin-bottom: 16px;
    }
  }

  .bottom_text {
    padding: 0 30px 20px;
    box-sizing: border-box;
    color: #FE9000;
    font-size: 12px;
  }

  @media (max-width: ${({theme}) => theme.sm}) {
    .tis {
      padding: 20px;
      font-size: 14px;
    }
  }
`
