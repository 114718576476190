import styled from "styled-components";
import React, {useMemo} from "react";
import Roy from "../../../assets/images/question-mark.svg"
import ethImg from "../../../assets/images/index_eth.png";
import DeletcIcon from "../../../assets/images/deletc_icon.png"
import {useShoppingCart} from "../../../provider/shopping_cart_provider";
import {useModel} from "../../../hook/use_model";
import {NftMetadata} from "../../../model/nft_metadata";
import {getBalanceFormat} from "../../../utils/formatBalance";
import Jazzicon from "@raugfer/jazzicon";

type NftItemCartProps = {
    uqid: string
}
export const NftItemCart: React.FC<NftItemCartProps> = ({uqid}) => {
    const {nftMateMap, deleteShopping} = useShoppingCart()

    const nftMetadata: NftMetadata = useMemo(() => {
        return nftMateMap[uqid]
    }, [uqid, nftMateMap])

    const buildDataUrl = useMemo(() => {
        return 'data:image/svg+xml;base64,' + btoa(Jazzicon(nftMetadata.ownedId));
    }, [nftMetadata])
    return (
        <NftItemCartStyle>
            <div className="hander">
                <img className={'nft_icon'} src={nftMetadata.metadata.image} alt=""/>
                <img src={buildDataUrl} alt="" className="addr"/>
            </div>
            <div className="center">
                <h3>{nftMetadata.metadata.name}</h3>
                <h4>#{parseInt(nftMetadata.id.tokenId)}</h4>
            </div>
            <div className="end">
                <h4><img src={ethImg} alt=""/>{getBalanceFormat(nftMateMap[uqid].averagePrice)}</h4>

                <img onClick={() => {
                    deleteShopping(uqid)
                }} src={DeletcIcon} alt="" className="delete"/>
            </div>
        </NftItemCartStyle>
    );
}


const NftItemCartStyle = styled.div`
  margin-top: 5px;
  background: #303030;
  border-radius: 12px;
  padding: 5px;
  display: flex;
  align-items: center;
  
  .hander{
    position: relative;
    .addr{
      position: absolute;

      display: block;
      width: 10px;
      height: 10px;
      top: 5px;
      left: 5px;
    }
  }

  .end {
    display: flex;
    align-items: center;

    .delete {
      margin-left: 20px;
      margin-right: 20px;
      display: block;
      width: 12px;
      height: 12px;
    }

    h4 {
      display: flex;
      align-items: center;

      img {
        display: block;
        width: 15px;
      }

      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      text-align: center;

      color: #FFFFFF;

    }

  }

  .nft_icon {
    display: block;
    border-radius: 5px;
    height: 60px;
    margin-right: 10px;
  }

  .center {
    flex: 1;

    h3 {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 18px;
      display: flex;
      align-items: center;
      letter-spacing: -0.1em;
      color: #FFFFFF;
      margin-bottom: 10px;
    }

    h4 {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      display: flex;
      align-items: center;
      letter-spacing: -0.1em;
      color: #FFFFFF;
    }
  }
`
