import Web3 from "web3";
import {Contrcts} from "./contrcts";
import WalletConnect from "@walletconnect/client";
import {AppConfig} from "../config";
import React from "react";

export class Sunder {
    public web3: Web3;
    public networkId: number;
    public contrcts: Contrcts;
    public walletConnect: WalletConnect | undefined;

    constructor(provider: any, networkId: number, options: any) {
        this.networkId = networkId;
        let realProvider;
        if (typeof provider === 'string') {
            if (provider.includes('wss')) {
                realProvider = new Web3.providers.WebsocketProvider(
                    provider,
                    options.ethereumNodeTimeout || 10000,
                )
            } else {
                realProvider = new Web3.providers.HttpProvider(
                    provider,
                    options.ethereumNodeTimeout || 10000,
                )
            }
        } else {

            if (options.connector === 'walletconnect') {
                realProvider = AppConfig.rpcUrl[networkId];
                this.walletConnect = new WalletConnect({bridge: AppConfig.rpcUrl[networkId],});
            } else {
                realProvider = provider;
            }
        }
        this.web3 = new Web3(realProvider);
        this.contrcts = new Contrcts(this.web3, "4")
    }
}

// [转发自 apple]
// goerli Contract Addresses
// EXPONENTIAL_CURVE: "0xA5Db357Ae0b8d4FC24a9b47DdE49cD237a7b4e57"
// LINEAR_CURVE: "0x0aaaCF44a62bBff5D903Dae61bbC19aE4566a0c9"
// PAIR_FACTORY: "0x5F619ED251B1b948D942fd15B5dc4216D10f428E"
// PAIR_ROUTER: "0x3E37e5446b7CB15B234908E84E5A8E133DEF26d5"
