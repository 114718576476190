import React, {useMemo} from "react";
import {TextField} from "@material-ui/core";
import styled from "styled-components";
import {CollectionImgWidget} from "../../../widget/collection_img";
import ethImg from "../../../assets/images/pool_eth.png";
import {useCollection} from "../../../provider/collection_provicer";
import {SudoMetaData} from "../../../model/sudo_meta_data";
import {head} from "ramda";
import {PoolType} from "../../collection/pool/nft_pool_item";
import {useUpDataPool} from "../../../provider/updata_pool_provicer";
import {useCreatePairETH, useDepositETH} from "../../../server/factory";
import {useSendModal} from "../../../widget/modal/send_modal";
import {LoadingBtn} from "../../../compones/loading_btn";


interface InputInterFace {
    label: string,
    unit?: string,
    onChange?: (e: any) => void,
    placeholder: string,
    name?: string,
    type?: string,
    imgUrl: string,
    disabledText?: string[],


}


export const BalanceInput: React.FC<InputInterFace> = ({
                                                           label,
                                                           placeholder,
                                                           unit,
                                                           name,
                                                           type,
                                                           onChange,
                                                           imgUrl,
                                                           disabledText,
                                                           children
                                                       }) => {

    const {sudo: sudoData} = useUpDataPool()


    if (!sudoData) {
        return <div/>
    }

    return (
        <div>
            <LabelStyle>
                <p className={'left'}>
                    {label} Balance
                </p>
            </LabelStyle>

            <FlexStyle>
                <InputStyle>
                    {
                        disabledText ? <p className={'disabledText'}>
                            <span
                                style={{color: parseInt(sudoData.type) === PoolType.nft ? "#4FCFA9" : "#E0446A"}}>{disabledText[0]}</span>{disabledText[1]}
                        </p> : <>
                            <TextField name={name || label}
                                       placeholder={placeholder}
                                       unselectable={'off'}
                                       value={placeholder}
                                       fullWidth={true}
                                       disabled={true}
                                       type={type}
                                       onChange={(ev) => onChange && onChange(ev)}
                            />
                            <span className={'unit'}>
                        {
                            imgUrl.toLowerCase() === 'eth' ? <img className={'eth'} src={ethImg} alt=""/> :
                                <CollectionImgWidget imgUrl={imgUrl}/>
                        }
                                {unit}</span></>
                    }
                </InputStyle>

                {children && <div className={`right_btn ${disabledText ? "disabled" : null}`}>
                    {children}
                </div>}
            </FlexStyle>
        </div>
    );
}


export const FlexStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  margin: 5px 0;
  box-sizing: border-box;

  .right_btn {
    width: 110px;
    padding-left: 10px;
    box-sizing: border-box;

    &.disabled {
      h4 {
        background-color: #2C2F36;
        color: #878A94;
      }

    }

    h4 {
      background: #52565F;
      border-radius: 20px;
      padding: 4px 0;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: -0.14em;
      color: #FFFFFF;
      text-align: center;

      &:last-child {
        margin-top: 5px;
      }
    }
  }

  .MuiInputBase-root {
    color: white;
  }

  .MuiInput-underline:before, .MuiInput-underline:after {
    display: none;
  }

  .balance {
    color: #6F6F7D;
    font-size: 14px;
    width: 120px;

    span {
      color: #7B7A89;

    }
  }
`

export const InputStyle = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  border: 1px solid #323841;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 600;
  padding: 6px 20px;
  box-sizing: border-box;
  height: 49px;
  position: relative;
  background: #2C2F36;

  .disabledText {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    display: flex;

    span {
      color: #E0446A;
      display: inline-block;
      padding-right: 20px;
    }

    color: #8D909A;
  }

  span {
    font-size: 14px;
    font-weight: 400;
    color: #7688B7;
    display: inline-block;
    //padding-left: 20px;
  }

  .unit {
    position: absolute;
    right: 0;
    padding-right: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.18em;
    color: #8D909A;

    .collection_img {
      width: 20px;
      margin-right: 5px;
    }

    .eth {
      display: block;
      width: 20px;
      margin-right: 5px;
    }
  }

  .MuiInputBase-input {
    font-family: 'ZX', sans-serif;

    &::-webkit-input-placeholder {
      text-align: start;
    }
  }


  .balance {
    color: #7B7A89;
    width: 200px;

    span {
      color: #7B7A89;

    }
  }

`

const LabelStyle = styled.h2`
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #FFFFFF;

  .left {
    color: #FFFFFF;
    line-height: 1;
    font-size: 14px;
    font-weight: 400;
    margin-right: 5px;
    display: flex;
    align-items: center;
  }

  .right {
    font-size: 14px;
    font-weight: 400;
    color: #7094C7;
  }

  img {
    display: block;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  @media (max-width: ${({theme}) => theme.sm}) {
    .left {
      font-size: 14px;
    }
  }

`

