import {useWeb3} from "../../../provider/web3_provider";
import {useCallback, useState} from "react";
import {HttpStatus} from "../../../utils/wait_fn";
import ERC721ABI from "../../../web3/abi/erc721"
import {NftHeaderMsg} from "../type";

export const useCheckAvailable = () => {
    const {contrcts, web3} = useWeb3();
    const [status, setStatus] = useState<HttpStatus>(HttpStatus.start);
    const [data, setData] = useState<any>({});


    const createPair = useCallback(async (adds: string) => {
        setStatus(HttpStatus.wait);
        try {

            if (!web3.utils.isAddress(adds)) {
                setStatus(HttpStatus.failure);
                setData({err: 'Invalid token address'});
                return;
            }
            console.log("0x37c25b308C6c67B396cb42A91B2FCE80Ffd0a735")
            const erc721 = new web3.eth.Contract(ERC721ABI, adds);
            let supportsInterface = await erc721.methods.supportsInterface("0x80ac58cd").call();
            console.log(supportsInterface)

            if (!supportsInterface) {
                setStatus(HttpStatus.failure);
                setData({err: 'Invalid ERC721token address'});
                return;
            }
            let _name = await erc721.methods.name().call();
            let _symbol = await erc721.methods.symbol().call();
            let _result: NftHeaderMsg = {
                token_address: adds,
                name: _name,
                symbol: _symbol
            };
            console.log(_result)
            setData(_result)
            // if (new BigNumber(_dTokenEToken[0]).toFixed() === '0') {
            //     setData({..._result, isCreate: false})
            // } else {
            //     setData({..._result, isCreate: true})
            // }
            setStatus(HttpStatus.success);
            return;

        } catch (e) {
            console.error(e)
            setStatus(HttpStatus.failure);
            setData({err: 'Invalid token address'});
            return;
        }
    }, [contrcts, web3,]);

    return {
        status,
        data,
        setStatus,
        createPair,
    };
}
