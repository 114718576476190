import {BuyProps, SellPoolMap, SellQty} from "../type";
import {useCollection} from "../../../provider/collection_provicer";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useShoppingCart} from "../../../provider/shopping_cart_provider";
import {PoolType} from "../pool/nft_pool_item";
import BigNumber from "bignumber.js";
import {compose, flatten, map, path, sort} from "ramda";
import {sequenceBuyFn} from "../../../utils/sequence";
import * as R from "ramda";
import {MyPoolData, RootMyPoolData} from "../../../model/my_pool_data";
import {useWallet} from "use-wallet";

export const useBuyList = () => {
    const {sudo} = useCollection();
    const [poolMap, setPoolMap] = useState<SellPoolMap>(sudo);
    const {collectionsMap, nftMateMap} = useShoppingCart()
    const {account} = useWallet();


    const pools: any = useMemo<RootMyPoolData[]>(() => {
        let pools: SellPoolMap = {}
        for (const key in poolMap) {
            let item = poolMap[key];
            let eType = parseInt(item.type)
            if (eType === PoolType.trade || eType === PoolType.nft) {
                let isAc = item.nftIds.length > 0
                if (isAc) {
                    let aSpotPrice = item.spotPrice
                    let aQty = collectionsMap[item.id.toLowerCase()]?.length
                    let aFee = new BigNumber(item.fee).div(1e20).toFixed()
                    aFee = aFee === '0' ? '0.01' : aFee
                    let aSequenceFn = sequenceBuyFn(new BigNumber(aSpotPrice), item.delta, item.bondingCurve, aQty || 0);
                    let aV = aSequenceFn.times(1 + (parseFloat(aFee) * aQty || 0)).toString();
                    item.averagePrice = aV;
                    pools[key] = item
                }
            }
        }
        let list = Object.values(pools);

        list = sort((a: SellQty, b: SellQty) => {
            return new BigNumber(a.averagePrice).minus(b.averagePrice).toNumber()
        }, list)


        let buys = compose(
            R.partition((ev: MyPoolData) => ev.owner.id.toLowerCase() === (account || '').toLocaleString()),
            flatten,
            map((ev: MyPoolData) => {
                return ev.nftIds.map((id: string) => {
                    return {
                        ...ev,
                        nftId: id,
                    }
                })
            }),
        )(list || [])


        let buyLists = compose(
            R.partition((ev: MyPoolData) => {
                let uqid = `${ev.id}_${ev.nftId}`
                let averagePrice = path<string>([uqid, 'averagePrice'], nftMateMap)
                return averagePrice !== undefined;
            }),
        )(buys[1] || []);


        return [...buyLists[0], ...buyLists[1]]

    }, [collectionsMap, poolMap])

    useEffect(() => {
        if (!Object.keys(poolMap).length) {
            setPoolMap(sudo)
        }
    }, [sudo])



    return {pools}
}
