import { createStore } from 'effector'
import {asPayload} from "../../../utils/reducer";
import {getMoralisFileData} from "./effects";

const initial: any = {}

export const $getMoralisFileData = createStore(initial).on(
    getMoralisFileData.doneData,
    asPayload
)
