import React, {useMemo} from "react";
import styled from "styled-components";
import {Grid} from "@material-ui/core";
import {useSendModal} from "../../../widget/modal/send_modal";
import {NftPoolProps} from "../index";
import {LoadingBtn} from "../../../compones/loading_btn";
import {NftCardItem} from "../../inventory/widget/nft_card_item";
import {values} from "ramda";
import {useWithdrawERC721} from "../../../server/pair";
import {OwnedNft} from "../../../model/get_nft_model";

type NftSelectModelProps = {
    onDismiss: Function
} & NftPoolProps
export const NftWithdrawModel: React.FC<NftSelectModelProps> = ({sudo, nfts}) => {

    const handleWithdrawERC721 = useWithdrawERC721(sudo?.id);
    const {status, onSend} = useSendModal(handleWithdrawERC721)
    let idsMap: any = {}
    return (
        <WapStyle>
            <div className="model_title">Withdraw NFT</div>
            <CellBottomStyle container>
                {
                    !nfts?.length  && <p>
                        Not Nft
                    </p>
                }
                <Grid className={'nft_wap'} container spacing={2}>
                    {
                        (nfts || []).map((ev: OwnedNft) => {
                            let key = `${sudo.id}_${ev}`

                            return <Grid item xs={4} md={3} key={key}>
                                <NftCardItem
                                    data={{
                                        id: sudo.collection.id,
                                        imgUrl: ev.metadata.image,
                                        nftId: parseInt(ev.id.tokenId).toFixed(0),
                                        nftIds: [],
                                        symbol: ev?.contractMetadata.symbol,
                                        hidePrice: true,
                                        isSelect: false
                                    }}
                                    onCb={(select: boolean) => {
                                        if (select) {
                                            idsMap[key] = ev;
                                        } else {
                                            delete idsMap[key]
                                        }
                                    }}
                                />
                            </Grid>
                        })
                    }
                </Grid>

            </CellBottomStyle>

            <div className={'bottom'}>
                <LoadingBtn isLoading={status} onClick={() => {
                    let ids:any = values(idsMap);
                    ids = ids.map((ev:any)=> ev.id.tokenId)
                    onSend({
                        ids: ids,
                        nftAddress: sudo.collection.id
                    })
                }}>
                    <h4>Withdraw NFT</h4>
                </LoadingBtn>
            </div>
        </WapStyle>
    )
}

const NftItemStyle = styled.div`
  display: flex;
  padding: 10px 10px;
  background: #52565F;
  border: 1px solid #323841;
  margin-bottom: 20px;
  border-radius: 10px;
  cursor: pointer;

  .img {
    margin-right: 10px;

    img {
      display: block;
      width: 30px;
      height: 30px;
    }
  }

  div {
    h2 {
      font-family: 'Skinny';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 18px;
      color: #FFFFFF;
      letter-spacing: 0;
    }

    h4 {
      font-family: 'Skinny';
      font-style: normal;
      font-weight: 400;
      line-height: 15px;
      font-size: 15px;
      color: #333;
      letter-spacing: 0;
    }
  }

`


const WapStyle = styled.div`

  width: 70%;
  margin: 0 auto;
  background-color: #191b1f;
  border-radius: 20px;
  border: 1px solid #2C2F36;
  padding: 10px 20px;
  box-sizing: border-box;

  .bottom {
    display: flex;
    justify-content: center;

    > div {
      width: 50%;
    }
  }

  .model_title {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    color: #D9D9D9;
    border: 1px solid #191B1F;
  }
`


export const CellBottomStyle = styled(Grid)`
  padding: 10px;
  box-sizing: border-box;
  margin-top: 8px;
  border: 1px solid #52565F;
  border-radius: 10px;

  .pt {
    box-sizing: border-box;
    cursor: pointer;
    width: 100%;

    .btn_max {
      background-color: #2d2f35;
      padding: 4px 10px;
      border-radius: 100px;
      font-size: 18px;
      font-weight: 400;
      color: #FFFFFF;
      cursor: pointer;
    }

    .bottom_msg {
      font-size: 14px;
      font-weight: 400;
      color: #FFFFFF;
      margin-bottom: 20px;
    }

    .ethImg {
      display: block;
      width: 20px;
    }
  }

  .tis {
    font-size: 16px;
    font-weight: 500;
    color: #BABAC8;
    padding: 30px;
    box-sizing: border-box;
    line-height: 20px;

    p {
      margin-bottom: 16px;
    }
  }

  .bottom_text {
    padding: 0 30px 20px;
    box-sizing: border-box;
    color: #FE9000;
    font-size: 12px;
  }

  @media (max-width: ${({theme}) => theme.sm}) {
    .tis {
      padding: 20px;
      font-size: 14px;
    }
  }
`
