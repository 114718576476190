import {useCallback, useEffect, useState} from "react";
import {useWeb3} from "../../../provider/web3_provider";
import {getBalanceFormat} from "../../../utils/formatBalance";
import {PoolType} from "../pool/nft_pool_item";
import {HttpServer} from "../../../net/http_server";
import {OwnedNft} from "../../../model/get_nft_model";


type useBalanceProps = {
    address: string,
    type: PoolType,
    nftAddress?: string
}
export const useBalance = ({address, type, nftAddress}: useBalanceProps) => {

    const {web3} = useWeb3()
    const [balance, setBalance] = useState('---');
    const [nfts, setNfts] = useState<OwnedNft[]>([])

    const handleGetNfts = useCallback(async () => {
        if (type == PoolType.token) {
            return;
        }
        if (!address || !nftAddress) {
            return 0
        }
        let _balance:any = await HttpServer.alchemyapiGetNft(nftAddress, address)
        setNfts(_balance.ownedNfts)
    }, [address, nftAddress])

    const handleGetBanlance = useCallback(async () => {
        if (!address) {
            return 0
        }
        let _balance = await web3.eth.getBalance(address)


        setBalance(getBalanceFormat(_balance, 4))
    }, [address])

    useEffect(() => {
        handleGetBanlance()
        handleGetNfts()
    }, [address])
    return {balance, nfts}
}
