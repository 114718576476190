import React, {useCallback, useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import {Grid} from "@material-ui/core";
import {LabelInput} from "./widget/label_input";
import {useForm} from "react-hook-form";
import {MoralisData} from "../../model/moralis_data";
import Roy from "../../assets/images/question-mark.svg";
import {NftCardItem, NftSellCardItem} from "./widget/nft_card_item";
import {useCreatePairETH} from "../../server/factory";
import {useSendModal} from "../../widget/modal/send_modal";
import {LoadingBtn} from "../../compones/loading_btn";
import {PoolType} from "../collection/pool/nft_pool_item";
import {CURVEEnum} from "../creater_lp/type";
import cloneImg from "../../assets/images/colse_img.png"
import {ModalProps} from "../../provider/model_provider";
import {ApproveButton} from "../../widget/base/approve_button";
import {useWeb3} from "../../provider/web3_provider";
import {Contract} from "web3-eth-contract";
import erc721 from "../../web3/abi/erc721";
import indexEth from "../../assets/images/index_eth.png";


type SellProps = {
    nfts: MoralisData[]
    group: { [key: string]: MoralisData[] }
} & ModalProps

export const SellWidget: React.FC<SellProps> = ({nfts, group, onDismiss}) => {
    const {register, errors, getValues, watch} = useForm();
    const handleCreatePairETH = useCreatePairETH()
    const {onSend, status,} = useSendModal(handleCreatePairETH)
    const [key, setKey] = useState(nfts[0].token_address)
    const {web3, contrcts,} = useWeb3();
    const [_curve, setCurve] = useState(CURVEEnum.LINEAR)

    const watchData = watch(['spotPrice', 'delta'])

    const contract: Contract = useMemo(() => {
        return new web3.eth.Contract(erc721, key)
    }, [key])

    const [disabled, setDisabled] = useState(false);

    const handleChecking = useCallback(() => {
        let {spotPrice, delta} = getValues();
        let keys = group[key].filter((ev: MoralisData) => !ev.isNotSell)
        let ids = keys.map((ev: MoralisData) => ev.token_id);

        if(!spotPrice || !delta){
            setDisabled(true)
            return
        }
        if(ids.length == 0){
            setDisabled(true)
            return
        }
        setDisabled(false)
    }, [group])

    useEffect(()=>{
        handleChecking()
    },[watchData])


    return (
        <SellStyle>
            <div className="sell_left">
                <h4>Collections</h4>
                <div className="nft_table">
                    {
                        Object.keys(group).map((ev: string) => {
                            return <h4 className="table" onClick={() => {
                                setKey(ev);
                            }}>
                                <p>{group[ev][0].symbol}</p>
                                <span>{group[ev].length}</span>
                            </h4>
                        })
                    }
                </div>



            </div>
            <div className="right">
                <h3 className="title">You are Listing {
                    onDismiss && <img onClick={() => onDismiss()} src={cloneImg} alt=""/>
                }</h3>
                <div className="box">
                    <Grid container spacing={2}>
                        {
                            group[key].map((ev: MoralisData, index: number) => {
                                if (!ev.isChecked) {
                                    return <span/>
                                }
                                let metadata = JSON.parse(ev.metadata)
                                if (!metadata) {
                                    metadata = {
                                        name: ev.name,
                                        image: Roy
                                    }
                                }

                                return <Grid item key={`${ev.name}_${ev.token_id}`}>
                                    <div className="ntf_card">
                                        <NftSellCardItem
                                            data={{
                                                id: ev.token_address,
                                                imgUrl: metadata.image || metadata.image_url,
                                                nftId: ev.token_id,
                                                nftIds: [],
                                                symbol: metadata.name || ev.name,
                                                isSell: true,
                                                isSelect: true,
                                            }}
                                            onCb={(select: boolean) => {
                                                group[key][index].isNotSell = !select;
                                                handleChecking()
                                            }}
                                        />

                                    </div>
                                </Grid>
                            })
                        }
                    </Grid>
                </div>
                <div className="bottom">
                    <Grid container spacing={3}>
                        <Grid item md={8} xs={8}>
                            <LabelInput errors={errors}
                                        inputRef={register({
                                            required: ''
                                        })}
                                        type={"tel"}
                                        name={'spotPrice'}
                                        label={'Start Price:'}
                                        placeholder={"1.0"}

                            />

                            <div className="bottom_type">
                                <h3>Bonding Curve</h3>
                                <div className="curve">
                                    <div onClick={() => setCurve(CURVEEnum.LINEAR)}
                                         className={`btn ${_curve === CURVEEnum.LINEAR && 'ac'}`}>Linear
                                    </div>
                                    <div onClick={() => setCurve(CURVEEnum.EXPONENTIAL)}
                                         className={`btn ${_curve === CURVEEnum.EXPONENTIAL && 'ac'}`}>Exponential
                                    </div>
                                </div>
                            </div>

                            <LabelInput errors={errors}
                                        inputRef={register({
                                            required: 'Please enter a number > 0'
                                        })}
                                        type={"tel"}
                                        name={'delta'}
                                        label={'Price Increase:'}
                                        placeholder={"1.0"}
                            >
                                {
                                    _curve === CURVEEnum.LINEAR ? <img src={indexEth} alt=""/> : <p className={'EXPONENTIAL'}>%</p>
                                }
                            </LabelInput>
                            <div style={{width:"70%"}}>
                                <ApproveButton
                                    contractAddress={key}
                                    contract={contract}
                                    spender={contrcts.FACTORY_A}
                                >
                                    <LoadingBtn disabled={disabled} isLoading={status} onClick={() => {
                                        let {spotPrice, delta} = getValues();
                                        let item: MoralisData = group[key][0]
                                        let keys = group[key].filter((ev: MoralisData) => !ev.isNotSell)
                                        onSend({
                                            delta,
                                            bonding: "0",
                                            nftAddress: item.token_address,
                                            poolType: PoolType.nft,
                                            spotPrice: spotPrice,
                                            curve: _curve,
                                            nftIds: keys.map((ev: MoralisData) => ev.token_id),
                                        })
                                    }}>
                                        Confirm
                                    </LoadingBtn>
                                </ApproveButton>
                            </div>
                        </Grid>

                    </Grid>
                </div>
            </div>
        </SellStyle>
    );
}


const SellStyle = styled.div`

  .bottom_type {
    margin: 10px 0;
    h3 {
      color: #FFFFFF;
      line-height: 1;
      font-size: 14px;
      font-weight: 400;
      margin-right: 5px;
      display: flex;
      align-items: center;
      padding: 5px 0;
      
    }

    .curve {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 100px;
      border: 1px solid #fff;
      width: 60%;
      overflow: hidden;

      .btn {
        font-size: 10px;
        padding: 8px 0;
        flex: 1;
        text-align: center;
        color: #000000;
        background-color: #303030;
        cursor: pointer;
        &.ac {
          background: #FFFFFF;
          color: #000;
        }
      }
    }
  }


  .right {
    padding: 20px;
    flex: 1;
    display: flex;
    flex-direction: column;

    .bottom {
      height: 35%;

      .LoadingBtn{
        margin-top: 5px;
        height: 35px;
      }
      .LabelInput_title{
        padding: 5px 0;
        height: 10px;
        .right{
          padding: 0;
        }
      }
      .confirm {
        background: #FFFFFF;
        border: 2px solid #000000;
        border-radius: 12px;
        display: inline-block;
        padding: 10px;

        font-weight: 400;
        font-size: 18px;
        line-height: 18px;
        align-items: center;
        text-align: center;
        letter-spacing: -0.14em;
        color: #000000;
       
      }
    }

    .box {
      flex: 1;
      border: 1px solid #2C2F36;
      border-radius: 20px;
      padding: 10px;
      box-sizing: border-box;
      height: 270px;
      overflow-y: scroll;
    }

    .title {
      padding-bottom: 20px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      letter-spacing: -0.15em;
      color: #D9D9D9;
      position: relative;

      img {
        display: block;
        width: 20px;
        position: absolute;
        cursor: pointer;

        right: 0;
      }
    }
  }

  .sell_left {
    border-radius: 12px 0 0 12px;
    width: 200px;
    padding: 20px 10px;
    background: #080808;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h4 {
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      text-align: center;
      letter-spacing: -0.15em;
      color: #D9D9D9;
      margin-bottom: 10px;
    }
    
    .nft_table{
      flex: 1;
    }

    .table {
      background: #242424;
      padding: 8px;
      box-sizing: border-box;
      border-radius: 8px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        flex: 1;
        text-align: start;
      }

      span {
        font-family: 'ZX';
        background: #121212;
        border-radius: 6px;
        padding: 5px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0;
        color: #FFFFFF;
      }
    }
  }


  width: 80%;
  margin: 0 auto;
  background-color: #191b1f;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  height: 700px;
  border: 2px solid #FFFFFF;
  border-radius: 12px;
`
