import React, {useEffect, useMemo, useState} from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend, ChartOptions,
} from 'chart.js';
import {Line} from 'react-chartjs-2';
import styled from "styled-components";
import {getBalanceFormat} from "../../../utils/formatBalance";
import BigNumber from "bignumber.js";


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

export const Options = {
    plugins: {
        legend: {
            position: 'top' as const,
            display: false,

        },
        title: {
            display: false,
            text: 'Chart.js Line Chart',
        },
        tooltip: {
            callbacks: {
                label: (tooltipItems: any) => {
                    let {raw} = tooltipItems;
                    return raw
                },
            },
        }
    },


};


export const data: any = {
    datasets: [
        {
            fill: true,
            label: '',
            data: [1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9],
            backgroundColor: [
                'rgba(255, 255, 255, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)'
            ],
            tension: 0.1
        },
    ],

};

let chartArea = {
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0,
};
const chartAreaBorder = {
    id: 'chartAreaBorder',
    beforeDraw(chart: any, args: any, options: any) {
        chartArea = chart.chartArea;
    }
};
export const DoubleChartsWIdget: React.FC<{
    option: any[],
}> = ({option}) => {


    let [_data, setData] = useState(data)
    let [plugins, setPlugins] = useState([chartAreaBorder]);

    let allLength = useMemo(() => {
        return option[0].length + option[1].length;
    }, [option])
    useEffect(() => {
        setPlugins([]);

        let buyList: any[] = option[0].reverse();
        let sellList: any[] = option[1];
        let labels: any = [
            ...buyList.map((ev, index) => buyList.length - index),
            ...sellList.map((ev, index) => index + 1)
        ];
        let options = [...buyList, ...sellList]
            .map((ev) => getBalanceFormat(new BigNumber(ev).times(1e18), 6),)
        setData({
            labels,
            datasets: [
                {
                    fill: true,
                    label: '',
                    data: options,
                    borderColor: [
                        ...buyList.map(() => '#00FF4E'),
                        ...sellList.map(() => '#FF0000'),
                    ],
                    tension: 0.5,
                },
            ],
        })
    }, [option])
    console.log(chartArea);
    return <WapSrtle posit={chartArea}>
        <Line
            options={Options}
            plugins={plugins}
            data={_data}
        >
        </Line>
        <div className="bgColor">
            <div>
                <div className="left" style={{width: `${option[0].length / allLength * 100}%`}}></div>
                <div className="right" style={{width: `${option[1].length / allLength * 100}%`}}></div>
            </div>
        </div>
    </WapSrtle>
}

type WapType = {
    bottom: number,
    height: number,
    left: number,
    right: number,
    top: number,
    width: number,
}
const WapSrtle = styled.div<{ posit: WapType }>`
  position: relative;

  .bgColor {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding-left: ${({posit}) => posit.left}px;
    box-sizing: border-box;
    padding-top: ${({posit}) => posit.top}px;

    > div {

      height: ${({posit}) => posit.height}px;
      width: ${({posit}) => posit.width}px;
      display: flex;
      >div{
        height: 100%;
        
      }
      
      .left{
        background-color: rgba(255, 0, 0, .5);
      }
      .right{
        background-color: rgba(0, 255, 78, .5);
      }
    }
  }

  canvas {
    position: relative;
    z-index: 10;
  }

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video, input {

    letter-spacing: 0;
  }

`
