import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Sunder} from "../web3/sunder";
import {useWallet} from "use-wallet";

import {Contrcts} from "../web3/contrcts";
import WalletConnect from "@walletconnect/client";



let current: number = 0;
export const Web3Provider: React.FC = ({children}) => {

    const [rootSwap, setRootSwap] = useState<any>();


    const {ethereum, chainId, connector, account} = useWallet();

    const [data, setData] = useState({
        block: current, token: {}
    });

    const callBack = useCallback(async (nsuer: Sunder) => {
        if (!account) return;
        const latestBlockNumber = await nsuer.web3.eth.getBlockNumber();

        if (current !== latestBlockNumber) {
            setData(
                {
                    block: latestBlockNumber,
                    token: {},
                }
            );
            current = latestBlockNumber;
        }
    }, [ethereum]);


    useEffect(() => {
            if (!ethereum) return;
            let _rootSwap = new Sunder(ethereum, chainId || 0, {connector});
            console.log(_rootSwap)
            setRootSwap(_rootSwap);
            callBack(_rootSwap);
            const interval = setInterval(async () => {
                callBack(_rootSwap);
            }, 3000);
            return () => clearInterval(interval)
        }, [ethereum],
    );

    return (
        <web3Context.Provider value={{
            rootSwap,
            block: data.block,
            // setCustom,
            listToken: {},
            customProjectMap: {}
        }}>{children}</web3Context.Provider>
    )
}


const web3Context = createContext<Web3Interface>({
    rootSwap: null,
    block: current,
    listToken: {},
    customProjectMap: {},
    // setCustom: () => null,
})


interface Web3Interface {
    rootSwap: Sunder | any,
    block: number,
    listToken: { [key: string]: any },
    customProjectMap: {}
    // setCustom: Function
}


export const useWeb3 = () => {
    const {rootSwap} = useContext(web3Context);
    return {
        web3: rootSwap?.web3,
        contrcts: rootSwap?.contrcts as Contrcts,
        walletConnect: rootSwap?.walletConnect as WalletConnect,
        // setCustom,
        rootSwap,
        listToken: {} as any,
        nsuer: rootSwap
    }
}





