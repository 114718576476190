import styled from "styled-components";
import React from "react";
import CreateImg from "../assets/images/create_arrow.png";
import {StepEnum} from "../view/creater_lp/type";
import {useCreaterDataPool} from "../provider/creater_lp_provider";
import CloseImg from "../assets/images/deletc_icon.png"
import {useHistory} from "react-router-dom";

type TitleProps = {
    step: StepEnum,
    setStep: Function
}
export const TitleCompone: React.FC<TitleProps> = ({step, setStep}) => {
    const {poolType} = useCreaterDataPool()
    let [type, _] = poolType;
    const history = useHistory();

    return (
        <WapStyle>
            {
                step === StepEnum.CreaterPool && <img
                    src={CreateImg}
                    onClick={() => {
                        setStep(StepEnum.SelectToken)
                        // setStep(type !== PoolType.token ? StepEnum.SelectNft : StepEnum.SelectToken)
                    }}
                    className={'back'}
                    alt=""
                />
            }
            <h2>Create Pool</h2>
            <h3>Provide liquidity to buy, sell, or trade NFTs on your behalf.</h3>

            <img onClick={() => {
                history.push("/my_pool");
            }}
                 className={'CloseImg'}
                 src={CloseImg}
                 alt=""/>
        </WapStyle>
    );
}

const WapStyle = styled.div`
  text-align: center;
  border-bottom: 3px solid #323841;
  padding-bottom: 15px;
  width: 90%;
  margin: 0 auto;
  position: relative;

  .back {
    position: absolute;
    left: 10px;
    width: 15px;
    cursor: pointer;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .CloseImg {
    position: absolute;
    right: 10px;
    width: 25px;
    cursor: pointer;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  h2 {
    font-size: 24px;
    font-weight: 400;
    color: #FFFFFF;
  }

  h3 {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      margin-left: 10px;
      display: block;
      width: 20px;
    }
  }
`
