import React from "react";
import styled from "styled-components";
import indexEth from "../../../assets/images/index_eth.png"
import {CellCollectionsData} from "../type";
import {getBalanceFormat} from "../../../utils/formatBalance";
import {CollectionInterface} from "../../../provider/collections_provicer";
import {useGetFoorPrice} from "../hook/useGetFoorPrice";
import nftImg from "../../../assets/images/null_nft_icon.png"

type CellCollectionsProps = {
    index: number,
    collection: CollectionInterface,
    isAc: boolean
} & CellCollectionsData
export const CellCollectionsWidget: React.FC<CellCollectionsProps> = ({
                                                                          name,
                                                                          img,
                                                                          index,
                                                                          collection,
                                                                          isAc
                                                                      }) => {

    const {floorPrice} = useGetFoorPrice({sudo: collection?.sudo, nftMeta: collection?.nftMeta})
    return (
        <CellCollections className={isAc ? 'ac' : ''}>
            <p className="index">{index}</p>
            <img className={'account'} src={img || nftImg} onError={() => <img  className={'account'} src={nftImg} alt=""/>} alt=""/>
            <div className="center_box full">
                <h4>{name}</h4>
                <div>
                    <h5>
                        Floor price
                    </h5>
                    <h6>:{getBalanceFormat(floorPrice, 2)} <img src={indexEth} alt=""/></h6>
                </div>
            </div>
            <div className="center_box">
                <h4></h4>
                <div className={'bottom'}>
                    <h6></h6>
                </div>
            </div>
        </CellCollections>
    );
}

const CellCollections = styled.div`
  box-sizing: border-box;
  background: #242424;
  border: 1px solid #080808;
  border-radius: 20px;
  width: 94%;
  margin: 0 auto 5px auto;
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &.ac {
    background-color: #606060;
  }

  .full {
    flex: 1;

  }

  .center_box:last-child {
    h4 {
      text-align: end;
      color: #4FCFA9;
    }
  }

  .center_box {

    .bottom {
      display: flex;
      justify-content: end;
    }

    h4 {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 18px;
      margin-bottom: 10px;
      color: #FFFFFF;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    div {
      display: flex;

      h5 {
        width: 50px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: #FFFFFF;
      }

      h6 {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        display: flex;
        align-items: center;
        color: #FFFFFF;

        img {
          display: block;
          width: 20px;
          margin-left: 5px;
        }
      }
    }
  }

  .index {
    color: #FFFFFF;
    font-size: 22px;
    width: 28px;
  }

  .account {
    width: 60px;
    height: 60px;
    background-color: #D9D9D9;
    border-radius: 40px;
    margin: 0 15px 0 10px;

  }
`
