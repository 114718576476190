import {createEffect} from 'effector'
import axios, {AxiosRequestHeaders} from 'axios'
import {path, prop, uniq} from 'ramda'

type processingFn = (ev: any) => any

export interface RequestEffectPayload {
    url: string
    method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
    processing?: processingFn
}

export function createRequestEffect<R, P extends Record<string, any> | void>({
                                                                                 url,
                                                                                 method = 'GET',
                                                                                 processing
                                                                             }: RequestEffectPayload) {
    const keys = uniq(url.match(/:\w+/g) ?? [])
    let innerUrl = url

    return createEffect<P extends void ? void : P & { _noAuth?: boolean }, R>(
        async (payload) => {
            const hasData = !['GET', 'DELETE'].includes(method)
            const hasParams = !hasData && keys?.length === 0


            const headers: AxiosRequestHeaders = {}
            if (
                payload == null ||
                (typeof payload === 'object')
            ) {

            }


            let data: any = await axios
                .request({
                    url: innerUrl,
                    method,
                    headers,
                    data: hasData ? payload : undefined,
                    params: hasParams ? payload : undefined,
                });
            if (processing) {
                return processing(prop('data')(data))
            }
            return prop('data')(data)
        }
    )
}

axios.interceptors.response.use(undefined, (error) => {
    const msg = path(['response', 'data'], error) || error.message

    throw error
})

// export const getToken = () =>
//     import('../users/store').then(({default: $user}) =>
//         ifElse(
//             isNil,
//             always(undefined),
//             (token) => `Bearer ${token}`
//         )($user.getState().token)
//     )
