import styled, {css} from "styled-components";


export class WidthStyle {

    static MaxWidth = css`
      max-width: 980px;
      width: 100%;
      margin: 0 auto;
      @media (max-width: ${({theme}) => theme.lg}) {
        width: 96%;
      }
    `;

    static MinWidth = css`
      max-width: 1100px;
      width: 100%;
      margin: 0 auto;
    
      @media (max-width: ${({theme}) => theme.lg}) {
        width: 96%;
      }
    `;

    static MinInnerStyle = css`
      background: #161515;
      box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.9);
      border-radius: 16px;
      border: 1px solid #2B2828;
      @media (max-width: ${({theme}) => theme.sm}) {
        border-radius: 12px;
      }
    `

    static PaddingSize = css<{ size: any }>`
      padding: ${({size}) => size.all ? `${size.all}px` : `${size.x}px ${size.y}px`};
      box-sizing: border-box;
    `

    static LineStyle = styled.div<{ color: string, height: number, maxHeight: number }>`
      width: 1px;
      height: ${({height}) => height}px;
      background: ${({color}) => color};
      margin: 0 auto;

      @media (max-width: ${({theme}) => theme.md}) {
        margin: ${({maxHeight}) => maxHeight}px 0;
        height: 1px;
        width: 100%;
      }
    `


}


