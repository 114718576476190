import {Contract} from "web3-eth-contract";
import Web3 from "web3";
import {ContractMap} from "./token.config";

export class Contrcts {
    public FACTORY: Contract;
    public FACTORY_A: string;

    public PAIR_ROUTER: Contract;
    public PAIR_ROUTER_A: string;



    constructor(web3: Web3, id: string) {
        this.FACTORY_A = ContractMap.FACTORY.address[id]
        this.FACTORY = new web3.eth.Contract(ContractMap.FACTORY.abi, ContractMap.FACTORY.address[id])

        this.PAIR_ROUTER_A = ContractMap.PAIR_ROUTER.address[id]
        this.PAIR_ROUTER = new web3.eth.Contract(ContractMap.PAIR_ROUTER.abi, ContractMap.PAIR_ROUTER.address[id])
    }
}





