import React, {useEffect} from "react";
import {useSendModal} from "../modal/send_modal";
import {useAllowance, useApprove} from "../../utils/token_server";
import {Contract} from "web3-eth-contract";
import {LoadingBtn} from "../../compones/loading_btn";
import {HttpStatus} from "../../utils/wait_fn";


export const ApproveButton: React.FC<{
    contract: Contract,
    contractAddress: string,
    spender: string
}> = ({
          contract,
          contractAddress,
          spender,
          children
      }) => {


    const allowance = useAllowance(contract, spender); // 检查权限
    const [approve] = useApprove(contract, contractAddress, spender); // 授权方法

    const {onSend, status} = useSendModal(approve); // 申请授权


    useEffect(() => {
    }, [spender, contractAddress])
    if(status == HttpStatus.success){
        return <div>
            {children}
        </div>
    }
    return (
        <div>
            {allowance ? children : <LoadingBtn isLoading={status} onClick={onSend}>
                Approve
            </LoadingBtn>}
        </div>
    );
}
