import React from "react";
import styled from "styled-components";
import ethImg from "../../../assets/images/pool_eth.png";
import leafImg from "../../../assets/images/leaf.png";
import {FormatAddr} from "../../../utils/format_addr";
import share from "../../../assets/images/share.png";
import {CollectionImgWidget} from "../../../widget/collection_img";
import {UrlHaxFormat} from "../../../utils/url";
import {useWallet} from "use-wallet";

export type NftPoolItemHeaderProps = {
    image_url: string,
    name: string,
    id: string,
    ownerId: string
}

export const NftPoolItemHeader: React.FC<NftPoolItemHeaderProps> = ({image_url, name, id, ownerId}) => {
    const {chainId} = useWallet()

    return (
        <NftPoolItemStyle>
            <div className="icon">
                <div className="img">
                    <img src={ethImg} alt=""/>
                    <p>ETH</p>
                </div>
                <div className="img">
                    <CollectionImgWidget imgUrl={image_url}/>
                    <p>{name.substring(0, 5)}{name.length > 5 ? '..' : ''}</p>
                </div>
            </div>
            <div className="address">
                <div onClick={(ev) => {
                    ev.stopPropagation();

                    window.open(UrlHaxFormat(id, 'address', chainId ), "_block")
                }}>
                    <img className={'leaf'} src={leafImg} alt=""/>
                    <p>{FormatAddr(id, 6)}</p>
                    <img className={'share'} src={share} alt=""/>
                </div>
                <div  onClick={(ev) => {
                    ev.stopPropagation();
                    window.open(UrlHaxFormat(ownerId, 'address', chainId ), "_block")
                }}>
                    <p style={{textDecorationLine: "none"}}>Owner:{FormatAddr(ownerId, 6)}</p>
                </div>
            </div>
        </NftPoolItemStyle>
    );
}


const NftPoolItemStyle = styled.div`
  .icon {
    display: flex;
    justify-content: space-between;
    margin: 10px 0 20px 0;

    .img {
      display: flex;
      width: 48%;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      padding: 5px 0;
      box-sizing: border-box;
      background: #242424;
      border-radius: 80px;

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 22px;
        align-items: center;
        color: #D9D9D9;

      }

      .collection_img, img {
        margin-right: 5px;
        display: block;
        width: 30px;
      }
    }
  }

  .address {
    > div {
      background: #242424;
      border-radius: 6px;
      padding: 10px 15px;
      box-sizing: border-box;
      cursor: pointer;
      margin-bottom: 10px;


      display: flex;
      align-items: center;

      img {
        display: block;
        width: 20px;
      }

      .leaf {
        width: 25px;
        margin-right: 10px;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        display: flex;
        align-items: center;
        letter-spacing: -0.18em;
        text-decoration-line: underline;
        color: #8D909A;
        flex: 1;
        box-sizing: border-box;
      }

      .share {
        width: 15px;
        margin-left: 10px;

      }
    }
  }

`
