import React, {useEffect, useState} from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import {Line} from 'react-chartjs-2';
import styled from "styled-components";
import {getBalanceFormat} from "../../../utils/formatBalance";
import BigNumber from "bignumber.js";
import {useCreaterDataPool} from "../../../provider/creater_lp_provider";


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

export const options = {
    // responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
            display: false,

        },
        title: {
            display: false,
            text: 'Chart.js Line Chart',
        },

        tooltip: {
            callbacks: {
                label: (tooltipItems:any) => {
                    let {raw} = tooltipItems;
                    return raw
                },
            },

        },
    },

};


export const data: any = {
    datasets: [
        {
            fill: true,
            label: '',
            data: [1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9],
            backgroundColor: [
                'rgba(255, 255, 255, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)'
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)'
            ],
            tension: 0.1
        },
    ],
};

export const ChartsWIdget: React.FC<{ option: any[] }> = ({option}) => {

    let [_data, setData] = useState(data)

    useEffect(() => {
        let labels: any = option.map((ev, index) => index + 1);

        option = option.map((ev) => getBalanceFormat(new BigNumber(ev).times(1e18), 6))
        setData({
            labels,
            datasets: [
                {
                    fill: true,
                    label: '',
                    data: option,
                    borderColor: 'rgb(53, 162, 235)',
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    tension: 0.1,
                },
            ],
        })

    }, [option])
    return <WapSrtle>
        {/*<ReactECharts option={option}/>*/}
        <Line options={options} data={_data}/>

    </WapSrtle>
}


const WapSrtle = styled.div`

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video, input {

    letter-spacing: 0;
  }
`
