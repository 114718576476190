import React, {useEffect} from 'react';
import './App.css';
import {HashRouter as Router, Switch, Route, Redirect,} from "react-router-dom";
import {HeaderWidget} from "./widget/header";
import {MainPage} from "./view/main/main";
import {useWallet, UseWalletProvider} from 'use-wallet'
import {AppConfig} from "./config";
import {Web3Provider} from "./provider/web3_provider";
import {ModelProvider} from "./provider/model_provider";
import {BackdropProvider} from "./provider/backdrop_provider";
import {WalletConnect} from "./widget/modal/wallet_connect";
import styled, {ThemeProvider} from "styled-components";
import {CreaterLP} from "./view/creater_lp";
import {theme} from "./theme";
import store from "./state";
import {Provider} from "react-redux";
import {FooterWidget} from "./widget/footer";
import {AllCollectionsPage} from "./view/main/all_collections";
import {CollectionMain} from "./view/collection";
import {InventoryMain} from "./view/inventory";
import {MyPoolMain} from "./view/my_pool";
import {ShoppingCartProvider} from "./provider/shopping_cart_provider";
import useRequestEffect from "./hook/useRequestEffect";
import {getMoralisFileData} from "./view/inventory/store/effects";
import {CollectionsProvider} from "./provider/collections_provicer";
import {getCollections} from "./view/main/store/effects";


function App() {
    return (
        <Providers>
            <Page/>
        </Providers>

    );
}

const Providers: React.FC = ({children}) => {
    return (
        <UseWalletProvider autoConnect={true} connectors={{
            walletconnect: {rpcUrl: AppConfig.rpcUrl[AppConfig.chainId]}
        }}>
            <ThemeProvider theme={theme}>
                <Provider store={store}>
                    <Web3Provider>
                        <ModelProvider>
                            <BackdropProvider>
                                <CollectionsProvider>
                                    <ShoppingCartProvider>
                                        {children}
                                    </ShoppingCartProvider>
                                </CollectionsProvider>

                            </BackdropProvider>
                        </ModelProvider>
                    </Web3Provider>
                </Provider>

            </ThemeProvider>
        </UseWalletProvider>

    );
}


const Page: React.FC = () => {

    const {status} = useWallet();


    return (<PubBg>
        {
            status === 'connected' ? <div>
                <Loadgin/>
            </div> : <div
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'center',
                    height: '100vh',
                }}
            >
                <WalletConnect/>

            </div>

        }
    </PubBg>);
};



const Loadgin: React.FC = () => {
    const {account} = useWallet();

    useRequestEffect(getMoralisFileData, `${account}/nft?chain=goerli&format=decimal`)

    useEffect(()=>{
        getCollections({})
    }, [])

    return (
        <Router>
            <HeaderWidget/>
            <PageStyle>
                <Switch>
                    <Route path="/main">

                            <Switch>
                                <Route path="/main/all" component={AllCollectionsPage}/>
                                <Route path="/main/index" exact component={MainPage}/>
                                <Redirect to="/main/index"/>
                            </Switch>
                            {/*<FooterWidget/>*/}
                    </Route>

                    <Route path="/collection">
                        <Switch>
                            <Route path="/collection/:type/:id" component={CollectionMain}/>
                        </Switch>
                    </Route>
                    <Route path="/inventory">
                        <Switch>
                            <Route path="/inventory" component={InventoryMain}/>
                        </Switch>

                    </Route>
                    <Route path="/my_pool" component={MyPoolMain}/>
                    <Route path="/lp" component={CreaterLP}/>
                    <Redirect to="/main"/>
                </Switch>
            </PageStyle>
            <FooterWidget/>

        </Router>

    )
}
const PubBg = styled.div`

  

  .footer {

  }
`
const PageStyle = styled.div`
  position: initial;
  min-height: 85vh;
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video, input {
    &::-webkit-scrollbar {
      display: none;
    }
  }
 
  
`

export default App;
