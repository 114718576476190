import {useCollections} from "../../../provider/collections_provicer";
import {useCallback, useEffect, useState} from "react";
import {SellPoolMap, SellQty} from "../../collection/type";
import {PoolType} from "../../collection/pool/nft_pool_item";
import BigNumber from "bignumber.js";
import {sequenceFn} from "../../../utils/sequence";
import {sort} from "ramda";
import {MyPoolData} from "../../../model/my_pool_data";

let _sellOffer: any = {}

export const useSellOffer = () => {
    const {collections} = useCollections()
    const [sellOffer, setSellOffer] = useState<{[key:string]: MyPoolData}>({});
    const handlSetSellOffer = useCallback(() => {
        for (let collectionsKey in collections) {
            if (_sellOffer[collectionsKey]) {
                return;
            }
            let poolMap = collections[collectionsKey].sudo;

            if (!Object.keys(poolMap).length) {
                return;
            }

            let pools: SellPoolMap = {}

            for (const key in poolMap) {
                let item = poolMap[key];
                let eType = parseInt(item.type)
                if (eType === PoolType.trade || eType === PoolType.token) {
                    let isAc = new BigNumber(item.ethBalance).gt(0)
                    if (isAc) {
                        let aSpotPrice = item.spotPrice;
                        let aQty = 0;
                        let aFee = new BigNumber(item.fee).div(1e20).toFixed()
                        aFee = aFee === '0' ? '0.01' : aFee
                        let aSequenceFn = sequenceFn(new BigNumber(aSpotPrice), item.delta, item.bondingCurve, aQty || 0);
                        let aV = aSequenceFn.times(1 - (parseFloat(aFee) * (aQty || 0))).toString();
                        item.sellPrice = aV;
                        pools[key] = item
                    }
                }
            }

            let list = Object.values(pools);

            let poolsList = sort((a: SellQty, b: SellQty) => {
                return new BigNumber(b.sellPrice).minus(a.sellPrice).toNumber()
            }, list)

            _sellOffer[collectionsKey.toLowerCase()] = poolsList[0];
            setSellOffer(_sellOffer)
        }
    }, [sellOffer, _sellOffer, collections])

    useEffect(() => {
        let itemFn = setTimeout(handlSetSellOffer, 500)
        return () => clearTimeout(itemFn)
    }, [collections])
    return {sellOffer}
}
