import React, {useEffect, useState} from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import {Line} from 'react-chartjs-2';
import styled from "styled-components";
import {useUpDataPool} from "../../provider/updata_pool_provicer";
import {BondingText} from "../my_pool/hook/usePool";
import {getBalanceFormat} from "../../utils/formatBalance";
import BigNumber from "bignumber.js";


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

export const options = {
    // responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
            display: false,

        },
        title: {
            display: false,
            text: 'Chart.js Line Chart',
        },
        callbacks: {
            label: (tooltipItems: any) => {
                let {raw} = tooltipItems;
                return raw
            },
        },
    },
};

const labels = ['1', '2', '3', '4', '5', '6', '7'];

export const data = {
    labels,
    datasets: [
        {
            fill: true,
            label: '',
            data: [1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9],
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
            tension: 0.5
        },
    ],
};

export const KLink: React.FC<{ option: any[] }> = ({option}) => {

    const {collections, sudo} = useUpDataPool();


    let [_data, setData] = useState(data)

    useEffect(() => {
        let labels: any = option.map((ev, index) => index + 1);

        option = option.map((ev) => getBalanceFormat(new BigNumber(ev).times(1e18), 6))
        setData({
            labels,
            datasets: [
                {
                    fill: true,
                    label: '',
                    data: option,
                    borderColor: 'rgb(53, 162, 235)',
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    tension: 0.1,
                },
            ],
        })

    }, [option])


    return (
        <WapStyle>
            <h3 className={'tis'}>
                <p>ETH Price Per {collections?.name}</p>
                <p className={'sub_title'}>Displays how your buy price decreases with
                    each {collections?.name} bought.</p>

                <div className="kLine">
                    <Line options={options} data={_data}/>
                </div>

                <div className="curve">
                    <h3>Bonding Curve</h3>
                    <div>{BondingText[sudo?.bondingCurve || '']} Curve</div>
                </div>

                <div className="curve">
                    <h3>Swap Fee Rate</h3>
                    <div className={`btn`}>0<span>%</span></div>
                </div>


            </h3>
        </WapStyle>
    )
}

const WapStyle = styled.div`


  .curve {
    cursor: no-drop;

    h3 {
      font-family: 'ZX';
      margin-top: 10px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: #FFFFFF;
      margin-bottom: 15px;

    }

    div {
      text-transform: capitalize;
      text-align: center;
      border-radius: 5px;
      height: 40px;
      line-height: 40px;
      background: #2C2F36;
      padding: 0 20px;
    }

    .btn {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {

      }
    }
  }

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video, input {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    -webkit-font-smoothing: antialiased;
    font-family: 'ZX', sans-serif;
    letter-spacing: 0;
  }
`
