import {allPass, anyPass, filter, path, pathSatisfies, propSatisfies} from "ramda";
import {useMemo} from "react";


export const usePool = (
    filters: any,
    pools: any[]
) => {


    const productionFeeding = useMemo(() => {
        return filter(
            anyPass([
                propSatisfies(
                    (batch: string) => {
                        return path(['type', batch], filters) === true;
                    },
                    'type'
                ),
                propSatisfies(
                    (batch: string) => {
                        return path(['curve', batch], filters) === true;
                    },
                    'bondingCurve'
                ),
            ]),
            pools
        );
    }, [pools, filters])

    return {pools, productionFeeding}
}
