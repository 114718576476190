import {useWeb3} from "../provider/web3_provider";
import {useWallet} from "use-wallet";
import {useSendFn} from "../utils/web3_send";
import {useCallback} from "react";
import BigNumber from "bignumber.js";
import {log} from "util";


let EffectiveTime = 60 * 60 * 10 * 1000

function Deadline() {
    return new BigNumber(new Date().valueOf()).plus(EffectiveTime).toFixed(0);
}

export const useBuyNFTs = () => {
    const {contrcts} = useWeb3();
    const {account} = useWallet()
    const handleSendFn = useSendFn(contrcts.PAIR_ROUTER_A);
    const handleDepositNFTs = useCallback(async ({
                                                     buys,
                                                     sell,
                                                     value,
                                                     ethMinOutput
                                                 }: { buys: any[], sell: any[], value: string, ethMinOutput?: string }): Promise<any> => {

        let method
        if (buys.length && sell.length) {
            let sellValue = new BigNumber(0)
            sell.forEach((ev) => {
                sellValue = sellValue.plus(ev[1])
            })
            method = contrcts.PAIR_ROUTER.methods.robustSwapETHForSpecificNFTsAndNFTsToToken([buys, sell, value, account, account])
        }
        if (buys.length && !sell.length) {
            method = contrcts.PAIR_ROUTER.methods.robustSwapETHForSpecificNFTs(buys, account, account, Deadline())

        }
        if (sell.length && !buys.length) {
            sell = sell.map((ev) => ev[0])
            method = contrcts.PAIR_ROUTER.methods.swapNFTsForToken(sell, ethMinOutput, account, Deadline())
        }
        return await handleSendFn(method, value);
    }, [contrcts, account]);
    return handleDepositNFTs;
}
