import {createAction} from '@reduxjs/toolkit'
import BigNumber from "bignumber.js";


export const initTokenList = createAction<{ tokenMap: { [key: string]: any } }>('tokenList/initTokenList');
export const addToken = createAction<{ tokenItem: any, tokenKey: string  }>('tokenList/addToken');
export const deleteToken = createAction<{ tokenKey: string }>('tokenList/deleteToken');

export const upDataBalance = createAction<{ balance: BigNumber,valueKey:string }>('tokenList/upDataBalance');

