import {useWeb3} from "../provider/web3_provider";
import {useCallback} from "react";
import {useSendFn} from "../utils/web3_send";
import {useWallet} from "use-wallet";
import {PoolType} from "../view/collection/pool/nft_pool_item";
import BigNumber from "bignumber.js";
import {CURVEEnum} from "../view/creater_lp/type";
import {CURVE} from "../web3/token.config";
import {numberToUint256} from "../utils/formatBalance";


type CreatePairETHProps = {
    curve: CURVEEnum,
    nftAddress: string,
    poolType: PoolType,
    delta: string,
    bonding: string,
    spotPrice: string,
    nftIds?: [],
    total: string,
    feeRate: string,
}

export const useCreatePairETH = () => {
    const {contrcts} = useWeb3();
    const {account} = useWallet()
    const handleSendFn = useSendFn(contrcts.FACTORY_A);
    const handleCreatePairETH = useCallback(async (ev: CreatePairETHProps): Promise<any> => {
        let {nftAddress, curve, poolType, delta, nftIds, spotPrice, bonding, total, feeRate} = ev;
        let curveAddr = curve === CURVEEnum.LINEAR ? CURVE.LINEAR_CURVE : CURVE.EXPONENTIAL_CURVE;
        delta = new BigNumber(delta).times(1e18).toFixed(0)
        if (curve === CURVEEnum.EXPONENTIAL) {
            delta = new BigNumber((parseInt(bonding) / 100) + 1).times(1e18).toFixed(0)
        }

        let method = contrcts.FACTORY.methods.createPairETH(
            nftAddress,
            curveAddr,
            poolType === PoolType.trade ? "0x0000000000000000000000000000000000000000" : account,
            poolType,
            delta,
            feeRate,
            new BigNumber(spotPrice).times(1e18).toFixed(0),
            nftIds || []
        )
        let value = '0'
        if (poolType !== PoolType.nft) {
            value = numberToUint256(total).toFixed(0)
        }
        return await handleSendFn(method, value);
    }, [contrcts, account]);
    return handleCreatePairETH;
}

export const useDepositETH = (poolAddress: string) => {
    const {contrcts} = useWeb3();
    const {account} = useWallet()
    const handleSendFn = useSendFn(poolAddress);
    const handleCreatePairETH = useCallback(async (amount: string): Promise<any> => {
        return await handleSendFn(undefined, new BigNumber(amount).times(1e18).toFixed(0));
    }, [contrcts, account]);
    return handleCreatePairETH;
}

export const useDepositNFTs = (poolAddress: string) => {
    const {contrcts} = useWeb3();
    const {account} = useWallet()
    const handleSendFn = useSendFn(contrcts.FACTORY_A);
    const handleDepositNFTs = useCallback(async ({
                                                     nftAddress,
                                                     ids
                                                 }: { nftAddress: string, ids: string[] }): Promise<any> => {


        let method = contrcts.FACTORY.methods.depositNFTs(nftAddress, ids, poolAddress)
        return await handleSendFn(method);
    }, [contrcts, account]);
    return handleDepositNFTs;
}
