import {useCallback, useEffect, useMemo, useState} from "react";
import {NftMetadata} from "../../../model/nft_metadata";
import {useShoppingCart} from "../../../provider/shopping_cart_provider";
import PairABI from "../../../web3/abi/pair_eth";
import {useWeb3} from "../../../provider/web3_provider";

type PriceItem = {
    id: string,
    list: NftMetadata[]
}
let mapPrice = {}
export const useBuyPrice = () => {
    const {nftMateMap, collectionsMap} = useShoppingCart()

    const [allPrice, setAllPrice] = useState<{ [key: string]: string }>({})
    const {web3} = useWeb3()


    const group = useMemo(() => {
        return Object.values(collectionsMap).map((ev: NftMetadata[]) => {
            let ids = ev.filter((ev) => !ev.isSell)
            if (ids.length)
                return {
                    id: ids[0].ownedId,
                    list: ids
                }
        })
    }, [collectionsMap])

    const handelPrice = useCallback(() => {
        group.forEach(async (ev: any) => {
            if(!ev) return;
            const pairContract = new web3.eth.Contract(PairABI as any, ev.id)
            let ruselt = await pairContract.methods.getBuyNFTQuote(ev.list.length).call()
            mapPrice = {
                ...mapPrice,
                [ev.id]: ruselt.inputAmount
            }
            setAllPrice(mapPrice)
        })
    }, [group])

    useEffect(() => {
        handelPrice()
    }, [collectionsMap])

    return {group, allPrice, nftMateMap}
}
