import {useLocation} from "react-router-dom";
import {Dispatch, SetStateAction, useMemo, useState} from "react";
import {AppConfig} from "../config";


export function UseUrlData<S>(): [S | undefined, Dispatch<SetStateAction<S | undefined>>] {
    const [data, setData] = useState<S>();
    const location = useLocation();
    useMemo(() => {
        setData(JSON.parse(new URLSearchParams(location.search).get('data') || ''));
    }, [location.search]);


    return [data, setData]
}

export class UrlHerf {
    static data: any;

    static set setDara(ev: any) {
        this.data = ev;
    }

    static get getData() {
        return this.data;
    }
}


export function UrlHaxFormat(hax: string, type: 'tx' | 'address', chainId?: number) {
    let id = chainId || AppConfig.chainId;
    return AppConfig.etherscan[id] + `${type}` + '/' + hax;
}
