import styled from "styled-components";

export const FullNaData = styled.div`
  text-align: center;
  color: white;
  width: 100%;
  margin-top: 200px;
  font-size: 20px;

`
