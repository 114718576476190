import React, {useCallback, useEffect} from "react";
import styled from "styled-components";
import {WidthStyle} from "../style/width_style";
import {Accordion, AccordionDetails, AccordionSummary, Dialog, Grid, Typography} from "@material-ui/core";
import {useWallet} from "use-wallet";
import {ModalProps} from "../../provider/model_provider";
import metamaskLogo from '../../assets/images/metamask-fox.svg'
import walletLogo from '../../assets/images/wallet-connect.svg'
import {ImageWidget} from "../base/images";
import {Alert} from "@material-ui/lab";
import logoImg from "../../assets/images/sundr_max_logo.png"
import playImg from "../../assets/images/play.svg"
import {useModel} from "../../hook/use_model";
import {ButtonWidget} from "../base/button";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {AppConfig} from "../../config";

export const WalletConnectTis: React.FC = () => {
    const [handlePresent] = useModel(<WalletConnect/>);
    const [expanded, setExpanded] = React.useState<string | false>('panel1');

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };
    return (
        <TisWapStyle>
            <h2>A gradual process </h2>
            <div className={'wap'}>
                <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <h3>1 . The first step</h3>
                    </AccordionSummary>
                    <AccordionDetails>
                        <h4>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                            sit amet blandit leo lobortis eget.
                        </h4>
                    </AccordionDetails>
                </Accordion>
                <Accordion square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <h3>2 . The first step</h3>
                    </AccordionSummary>
                    <AccordionDetails>
                        <h4>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                            sit amet blandit leo lobortis eget.
                        </h4>
                    </AccordionDetails>
                </Accordion>
                <Accordion square expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                    >
                        <h3>3 . The first step</h3>
                    </AccordionSummary>
                    <AccordionDetails>
                        <h4>
                            When you mint tokens from your Native token, the protocol will sunder your Native token into
                            two, dTokens and eTokens. dTokens functions as the governance part of your Native token ,
                            and eToken functions as the inherent earnings part of your Native token.
                        </h4>
                    </AccordionDetails>
                </Accordion>
            </div>
            <ButtonWidget
                text={'Connect Wallet'}
                onClick={handlePresent}
            />
        </TisWapStyle>
    );
}


const TisWapStyle = styled.div`
  ${() => WidthStyle.MinInnerStyle};
  ${() => WidthStyle.MinWidth};
  padding: 40px 30px;
  box-sizing: border-box;

  h2 {
    font-size: 36px;
    font-weight: bold;
    color: #2A1D69;
  }

  .wap {
    margin: 40px 0 50px 0;

    h3 {
      font-size: 21px;
      font-weight: 500;
      color: #2A1D69;
    }

    h4 {
      background: #DDE8F6;
      border-radius: 16px;
      font-size: 14px;
      font-weight: 400;
      color: #696190;
      line-height: 20px;
      padding: 20px;
      box-sizing: border-box;
    }

    .MuiAccordion-root:before {
      display: none;
    }

    .MuiPaper-elevation1 {
      box-shadow: none;
      background-color: transparent;

      .MuiAccordionSummary-root {
        padding: 0;

        .MuiAccordionSummary-content {
          margin: 0;
        }

        .MuiAccordionSummary-root.Mui-expanded {
          min-height: 40px;
        }

        .MuiAccordion-root.Mui-expanded {
          margin: 0;
        }
      }

      .MuiAccordionDetails-root {
        padding: 0;
      }
    }
  }

  @media (max-width: ${({theme}) => theme.sm}) {
    h2 {
      font-size: 24px;
    }

    .wap {
      margin: 20px 0 25px 0;
    }
  }
`


export const WalletConnect: React.FC<ModalProps> = ({onDismiss}) => {
    const {connect, account, chainId, networkName} = useWallet();

    const [open, setOpen] = React.useState(false);
    const handleClose = (value: string) => {
        setOpen(false);
    };

    useEffect(() => {
        if (account && onDismiss) {
            onDismiss();
        }
    }, [account, onDismiss]);

    const connectFn = useCallback(async (id: any) => {
        let a = await connect(id);
        AppConfig.chainId = chainId;

    }, [chainId]);

    useEffect(() => {
        connectFn('injected');
    }, [])


    return (<WalletStyle size={{all: 20}}>

        <div className={'top'}>
            <h2>Connect Wallet</h2>
        </div>
        <div className={'inner'}>
            <WalletCard onClick={async () => {
                await connectFn('injected')
            }} img={metamaskLogo} title={`Metamask`} sub={`Connect to you metaMask Wallet`}/>
            <div style={{height: "2px", backgroundColor: "#202020"}}/>
            <WalletCard onClick={async () => {
                await connectFn('walletconnect')
            }} img={walletLogo} title={`Wallet Connect`} sub={`Connect to you Wallet Connect`}/>
        </div>


        <Dialog onClose={handleClose} open={open}>
            <Alert severity="info"> Please switch to {networkName} network!</Alert>
        </Dialog>
    </WalletStyle>);
}


const WalletCard: React.FC<{ img: string, title: string, sub: string, onClick: Function }> = ({
                                                                                                  title,
                                                                                                  sub,
                                                                                                  onClick,
                                                                                                  img
                                                                                              }) => {
    return (
        <WalletCardStyle size={{x: "30", y: "10"}} onClick={() => onClick()}>
            <ImageWidget bgColor={"transparent"} url={img} size={80}/>
            <h2>{title}</h2>
            <h3>{sub}</h3>
        </WalletCardStyle>
    );
}


const WalletCardStyle = styled.div`
  width: 100%;
  ${WidthStyle.PaddingSize};
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 250px;
  padding: 40px 0 72px 0;

  h2 {
    font-size: 18px;
    font-weight: bold;
    color: #FEFEFE;
  }

  > img {
    display: block;
    width: 66px;
    margin-right: 20px;
  }

  h3 {
    margin-top: 10px;
    font-size: 12px;
    font-weight: 400;
    color: #B4B7C6;

  }
`


const WalletStyle = styled.div`
  width: 100vw;
  max-width: 586px;
  ${WidthStyle.PaddingSize};
  height: 328px;
  margin: 0 auto;
  padding: 0;
  background: #161515;
  border-radius: 16px;
  border: 1px solid #2B2828;
  cursor: pointer;
  overflow: hidden;


  .inner {
    display: flex;
  }

  > .top {
    height: 76px;
    border-bottom: 2px solid #20202080;

    h2 {
      line-height: 76px;
      color: #FEFEFE;
      text-align: center;
      font-size: 20px;
    }
  }
`
