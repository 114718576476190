import React, {useCallback, useContext, useEffect, useState} from "react";
import {HttpStatus, useWait} from "../../utils/wait_fn";
import {TransactionModal} from "./transaction_modal";
import {ModalContext} from "../../provider/model_provider";


/**
 *  发送交易过渡 模态框
 * @param confirmationFn
 * @param onDismiss
 * @constructor
 */
export const SendModal: React.FC<{ confirmationFn: Function, onDismiss?: Function }> = ({
                                                                                            confirmationFn,
                                                                                            onDismiss
                                                                                        }) => {

    const {request, status, data} = useWait(confirmationFn);


    useEffect(() => {
        request('');
    }, [])

    return (
        <TransactionModal startDate={new Date().valueOf()} onDismiss={onDismiss} txHash={data} status={status}/>
    );
}


/**
 * 使用 SendModal 带参数时使用
 * @param confirmationFn
 */
export const useSendModal = (confirmationFn: Function) => {
    const {onPresent} = useContext(ModalContext);
    const [status, setStatus] = useState<HttpStatus>(HttpStatus.start);
    const [data, setData] = useState<any>();



    const onSend = useCallback(async (res) => {
        let start = new Date().valueOf()
        try {
            setStatus(HttpStatus.wait);
            let data = await confirmationFn(res);
            setData(data);
            setStatus(HttpStatus.success);
            onPresent(<TransactionModal startDate={start} txHash={data.transactionHash} status={HttpStatus.success}/>);
        } catch (e) {
            setData({err: e});
            setStatus(HttpStatus.failure);
            onPresent(<TransactionModal startDate={start} txHash={{err: e}} status={HttpStatus.failure}/>);
        }
    }, [])

    return {onSend, status, data};
}



