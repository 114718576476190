import React, {useContext, useMemo} from "react";
import styled from "styled-components";
import ArrowBottom from "../../../assets/images/arrow_bottom.png"
import {ModalContext} from "../../../provider/model_provider";
import {ListTokenModel} from "../model/select_model";
import {MoralisData} from "../../../model/moralis_data";
import * as R from "ramda";
import {useStore} from "effector-react";
import {$getMoralisFileData} from "../../inventory/store";
import {useCreaterDataPool} from "../../../provider/creater_lp_provider";
import {PoolType} from "../../collection/pool/nft_pool_item";

export const SelectInput: React.FC = () => {
    const data = useStore($getMoralisFileData)
    const {onPresent} = useContext(ModalContext);
    const {moralisData, poolType} = useCreaterDataPool();
    const [moralis, setMoralis] = moralisData;

    const group: any[] = useMemo(() => {
        if (!data || !data.result) {
            return []
        }
        return R.compose(
            R.map(R.head),
            R.values,
            R.groupBy((student: MoralisData) => {
                return student.token_address;
            }),
            R.path(['result'])
        )(data);
    }, [data])

    return (
        <SelectStyle onClick={() => {
            onPresent(<ListTokenModel poolType={poolType[0]} setMoralis={setMoralis} nfts={group}/>)
        }}>
            {moralis?.name || 'select NFT'}
            <img src={ArrowBottom} alt=""/>
        </SelectStyle>
    )
}

const SelectStyle = styled.div`

  background: #2C2F36;
  border-radius: 100px;
  align-items: center;
  padding: 10px 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.05em;
  margin-left: 20px;
  color: #fff;
  display: flex;
  cursor: pointer;

  img {
    display: block;
    width: 15px;
    margin-left: 10px;
  }

`
